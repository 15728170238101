// src/components/PendingEnrollmentsList/PendingEnrollmentsList.tsx
import React, { useState } from "react";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { FlatList, View } from "react-native";
import { ListItem } from "react-native-elements";
import { Enrollment } from "../../types";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { useTheme } from "../../theme/ThemeContext";
import EmptyState from "../EmptyState/EmptyState";
import { calculateAge } from "../../utils";
import { ManageSingleEnrollmentModal } from "../ManageSingleEnrollmentModal/ManageSingleEnrollmentModalWeb";

interface PendingEnrollmentsListProps {
  enrollments: Enrollment[];
  isUpdating: boolean;
  handleUpdateHorse: (enrollmentId: string, horseId: string) => Promise<void>;
  handleUpdateEnrollmentStatus: (
    enrollmentId: string,
    variables: {
      isForfait?: boolean;
      isHorsConcours?: boolean;
      isEliminated?: boolean;
      approved?: boolean;
    }
  ) => Promise<void>;
}

const PendingEnrollmentsList: React.FC<PendingEnrollmentsListProps> = ({
  enrollments,
  handleUpdateEnrollmentStatus,
  handleUpdateHorse,
  isUpdating,
}) => {
  const theme = useTheme();
  const [selectedEnrollment, setSelectedEnrollment] =
    useState<Enrollment | null>(null);
  // const [horsConcours, setHorsConcours] = useState(false);
  const [isEnrollmentModalVisible, setIsEnrollmentModalVisible] =
    useState(false);

  const handleEditEnrollment = (enrollmentId: string) => {
    const selectedEnrollment = enrollments.find(
      (enrollment) => enrollment.id === enrollmentId
    );
    setSelectedEnrollment(selectedEnrollment || null);
    setIsEnrollmentModalVisible(true);
  };
  const handleEnrollmentModalClose = () => {
    setIsEnrollmentModalVisible(false);
  };

  if (isUpdating) {
    return <LoadingIndicator />;
  }

  const renderPendingItem = ({ item }: { item: Enrollment }) => {
    const { entity, phone, email } = item.profile ?? {};
    const { category, fullName, age } = item;
    const {
      name: horseName,
      breed: horseBreed,
      height: horseHeight,
      dateOfBirth: horseDateOfBirth,
    } = item.horse ?? {};

    return (
      <ListItem
        key={item.id}
        bottomDivider
        onPress={() => {
          handleEditEnrollment(item.id);
        }}
      >
        <ListItem.Content>
          {category && (
            <ListItem.Subtitle
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 5,
              }}
            >
              {category}
            </ListItem.Subtitle>
          )}
          <ListItem.Title
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 5,
            }}
          >
            {fullName} {entity && `(${entity.name})`}
          </ListItem.Title>

          <ListItem.Subtitle
            style={{ ...theme.typography.small, color: theme.colors.grey2 }}
          >
            Email: {email}
          </ListItem.Subtitle>
          <ListItem.Subtitle
            style={{ ...theme.typography.small, color: theme.colors.grey2 }}
          >
            Telefone: {phone}
          </ListItem.Subtitle>
          {age ? (
            <ListItem.Subtitle
              style={{ ...theme.typography.small, color: theme.colors.grey2 }}
            >
              Idade: {age} anos
            </ListItem.Subtitle>
          ) : null}
          {item.horse ? (
            <>
              <ListItem.Title style={{ marginVertical: 5 }}>
                {horseName}
              </ListItem.Title>
              <ListItem.Subtitle
                style={{ ...theme.typography.small, color: theme.colors.grey2 }}
              >
                Raça: {horseBreed}
              </ListItem.Subtitle>
              <ListItem.Subtitle
                style={{ ...theme.typography.small, color: theme.colors.grey2 }}
              >
                Idade: {horseDateOfBirth ? calculateAge(horseDateOfBirth) : ""}{" "}
                anos
              </ListItem.Subtitle>
              <ListItem.Subtitle
                style={{ ...theme.typography.small, color: theme.colors.grey2 }}
              >
                Altura: {horseHeight}
              </ListItem.Subtitle>
            </>
          ) : (
            <View style={{ flexDirection: "row", marginVertical: 10 }}>
              <Icon
                name="alert-circle-outline"
                size={20}
                color={theme.colors.accent}
              />
              <ListItem.Subtitle>sem montaria</ListItem.Subtitle>
            </View>
          )}
        </ListItem.Content>
        <Icon name="pencil-circle" size={30} color={theme.colors.beige} />
      </ListItem>
    );
  };

  return (
    <>
      {enrollments.length > 0 ? (
        <FlatList
          data={enrollments}
          renderItem={renderPendingItem}
          keyExtractor={(item) => item?.id}
          extraData={enrollments}
        />
      ) : (
        <EmptyState
          message="Os concorrentes que precisam ser aprovados aparecerão aqui"
          iconName="horse-human"
        />
      )}
      {selectedEnrollment && (
        <ManageSingleEnrollmentModal
          enrollment={selectedEnrollment}
          isVisible={isEnrollmentModalVisible}
          onClose={handleEnrollmentModalClose}
          handleUpdateEnrollmentStatus={handleUpdateEnrollmentStatus}
          handleUpdateHorse={handleUpdateHorse}
          isUpdating={isUpdating}
        />
      )}
    </>
  );
};

export default PendingEnrollmentsList;
