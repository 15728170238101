import React, { useState, useEffect } from "react";
import { View, Text, Image } from "react-native";
import { Button, Overlay, Input } from "react-native-elements";
import { useTheme } from "../../theme/ThemeContext";
import { imageSources } from "../../assets/images/imageSources";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";

export const WebLoginModal = ({
  isLoginVisible,
  toggleLogin,
}: {
  isLoginVisible: boolean;
  toggleLogin: () => void;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { login, isAuthenticated } = useAuth();
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();

  useEffect(() => {
    return setLoading(false);
  }, []);

  const onSuccess = () => {
    showMessage("Login feito com sucesso! Redirecionando...", "success");
    setTimeout(() => {
      navigate("/dashboard");
    }, 1000);
  };

  useEffect(() => {
    if (isAuthenticated) {
      onSuccess();
    }
  }, [isAuthenticated]);

  const onError = (err: unknown) => {
    showMessage(`Erro: ${err}`, "error");
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await login(email, password, onSuccess, onError);
    } catch (err) {
      showMessage(`Erro ao logar usuário. Tente novamente`, "error");
      setLoading(false);
    }
  };

  return (
    <Overlay
      isVisible={isLoginVisible}
      onBackdropPress={toggleLogin}
      overlayStyle={{ padding: 15, minWidth: "50%", maxWidth: "90%" }}
    >
      <View
        style={{
          alignItems: "flex-start",
          flexDirection: "row",
        }}
      >
        <Icon
          name="close-circle-outline"
          onPress={toggleLogin}
          size={30}
          color={theme.colors.secondary}
        />
      </View>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 15,
        }}
      >
        <Image
          source={imageSources.logoVertical01}
          style={{ width: 146, height: 120 }}
        />
      </View>
      <View>
        <Input
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
          keyboardType="email-address"
          autoCapitalize="none"
        />
        <Input
          placeholder="Password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
          autoCapitalize="none"
        />
        <View style={{ paddingHorizontal: 10, paddingVertical: 20 }}>
          <Button
            title={"Login"}
            onPress={handleSubmit}
            buttonStyle={{
              backgroundColor: theme.colors.secondary,
            }}
            titleStyle={{ color: theme.colors.white }}
            loading={loading}
          />
        </View>
        <Text
          style={{
            ...theme.typography.small,
            color: theme.colors.secondary,
            textAlign: "center",
            marginBottom: 10,
          }}
        >
          Ou entre em contato conosco! striboss.app@gmail.com
        </Text>
      </View>
    </Overlay>
  );
};
