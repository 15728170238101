import React from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";

import { Enrollment } from "../../types";
import { useTheme } from "../../theme/ThemeContext";

export const EnrollmentCard: React.FC<{
  enrollment: Enrollment;
  index: number;
  onPress: () => void;
  isModTimeTrial?: boolean;
}> = ({ enrollment, index, onPress, isModTimeTrial }) => {
  const theme = useTheme();
  const {
    isForfait,
    isEliminated,
    showjumpingScores,
    fullName,
    entryOrder,
    isHorsConcours,
    horse,
    profile,
    category,
  } = enrollment;

  const { firstName, lastName } = profile ?? {};
  const { name: horseName } = horse ?? {};
  const { approximation, totalFaults, time } = showjumpingScores ?? {};
  const isAndroid = Platform.OS === "android";

  const renderTextColumn = (
    label: string,
    value: number | string | undefined
  ) => (
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View style={{ padding: 5 }}>
        <Text style={{ ...theme.typography.h2 }}>{value ?? "--"}</Text>
      </View>
      <Text style={[theme.typography.small, { flexShrink: 1 }]}>{label}</Text>
    </View>
  );

  const renderLeftNumber = () => {
    if (!showjumpingScores) {
      return (
        <View style={{ marginLeft: 10, marginRight: 15, alignItems: "center" }}>
          <Text
            style={[
              {
                fontSize: 24,
                fontWeight: "700",
                color: theme.colors.secondary,
              },
            ]}
          >
            {entryOrder}
          </Text>
        </View>
      );
    } else {
      return (
        <View style={{ marginLeft: 10, marginRight: 15, alignItems: "center" }}>
          <Text
            style={[
              {
                fontSize: 24,
                fontWeight: "700",
                color: theme.colors.secondary,
              },
            ]}
          >
            {index + 1}
          </Text>
        </View>
      );
    }
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 8,
          backgroundColor: theme.colors.white,
          shadowColor: "#000",
          shadowOffset: { width: 2, height: 2 },
          shadowOpacity: 0.05,
          shadowRadius: 3,
          margin: 4,
          borderRadius: 5,
        }}
        key={enrollment.id}
      >
        {!isEliminated && !isForfait && !isHorsConcours ? (
          renderLeftNumber()
        ) : (
          <>
            {isForfait || isEliminated ? (
              <View style={{ marginLeft: 10, marginRight: 15 }}>
                <Icon
                  name="emoticon-cry-outline"
                  size={20}
                  color={theme.colors.error}
                />
              </View>
            ) : (
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "700",
                  color: theme.colors.secondary,
                }}
              >
                {`${isHorsConcours ? "HC " : null}${approximation ?? ""}`}
              </Text>
            )}
          </>
        )}

        <View style={{ flex: 1, flexShrink: 1 }}>
          <View>
            <Text
              style={[
                { ...theme.typography.body, marginBottom: 5 },
                isEliminated || isForfait
                  ? {
                      color: theme.colors.error,
                      textDecorationLine: "line-through",
                    }
                  : null,
              ]}
            >
              {fullName ?? `${firstName} ${lastName}`}{" "}
              {category ? `(${category})` : ""}
            </Text>
          </View>
          <View>
            <Text
              style={[
                {
                  ...theme.typography.body,
                  marginBottom: 5,
                  color: theme.colors.grey2,
                },
                isEliminated || isForfait
                  ? {
                      color: theme.colors.error,
                      textDecorationLine: "line-through",
                    }
                  : null,
              ]}
            >
              {horseName}
            </Text>
          </View>
        </View>

        {showjumpingScores ? null : (
          <Icon name="play-circle" size={30} color={theme.colors.accent} />
        )}

        {!showjumpingScores || isEliminated || isForfait ? (
          <View />
        ) : (
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {approximation && isModTimeTrial ? (
              <View style={{ marginRight: 5 }}>
                <Text
                  style={[
                    {
                      fontSize: 20,
                      fontWeight: "700",
                      color: theme.colors.grey2,
                    },
                  ]}
                >
                  {renderTextColumn("Aprox.", approximation)}
                </Text>
              </View>
            ) : null}
            {time ? (
              <View style={{ marginRight: 5 }}>
                <Text
                  style={[
                    {
                      fontSize: 20,
                      fontWeight: "700",
                      color: theme.colors.grey2,
                    },
                  ]}
                >
                  {renderTextColumn("Tempo.", time)}
                </Text>
              </View>
            ) : null}
            {totalFaults ? (
              <Text
                style={[
                  {
                    fontSize: 20,
                    fontWeight: "700",
                    color: theme.colors.grey2,
                  },
                ]}
              >
                {renderTextColumn("Faltas.", totalFaults)}
              </Text>
            ) : null}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};
