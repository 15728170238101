import { createContext, useContext, useState, useCallback } from "react";

type EnrollmentsContextValue = {
  enrollments: any[]; // Replace any with the appropriate type
  setEnrollments: React.Dispatch<React.SetStateAction<any[]>>;
};

const EnrollmentsContext = createContext<EnrollmentsContextValue | undefined>(
  undefined
);

export const useEnrollmentsContext = () => {
  const context = useContext(EnrollmentsContext);
  if (!context) {
    throw new Error(
      "useEnrollmentsContext must be used within a EnrollmentsProvider"
    );
  }
  return context;
};

type EnrollmentsProviderProps = {
  children: React.ReactNode;
};

export const EnrollmentsProvider: React.FC<EnrollmentsProviderProps> = ({
  children,
}) => {
  const [enrollments, setEnrollments] = useState<any[]>([]);

  return (
    <EnrollmentsContext.Provider value={{ enrollments, setEnrollments }}>
      {children}
    </EnrollmentsContext.Provider>
  );
};
