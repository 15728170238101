import { createContext, useContext } from "react";

interface SnackbarContextValue {
  showMessage: (message: string, type?: "success" | "error") => void;
}

const SnackbarContext = createContext<SnackbarContextValue>({
  showMessage: () => {},
});

export const useSnackbar = () => useContext(SnackbarContext);

export default SnackbarContext;
