import React from "react";
import { CorcelIcon } from "./CorcelIcon";

interface Props {
  color?: string;
  size?: number;
}

export const FacebookIcon: React.FC<Props> = ({ color = "#000", size }) => {
  const myPaths = [
    {
      d: "M27.3333 14C27.3333 6.64001 21.36 0.666672 14 0.666672C6.64 0.666672 0.666668 6.64001 0.666668 14C0.666668 20.4533 5.25333 25.8267 11.3333 27.0667V18H8.66667V14H11.3333V10.6667C11.3333 8.09334 13.4267 6.00001 16 6.00001H19.3333V10H16.6667C15.9333 10 15.3333 10.6 15.3333 11.3333V14H19.3333V18H15.3333V27.2667C22.0667 26.6 27.3333 20.92 27.3333 14Z",
      color: color,
    },
  ];

  return <CorcelIcon paths={myPaths} size={size} viewBox="0 0 28 28" />;
};
