// src/context/UserProfileContext.tsx

import { createContext, useContext } from "react";
import { User } from "../types";

export type UserProfileContextType = {
  userProfile: User | null;
  setUserProfile: (userProfile: any) => void;
};

export const UserProfileContext = createContext<UserProfileContextType>({
  userProfile: null,
  setUserProfile: () => {},
});

export const useUserProfileContext = () => useContext(UserProfileContext);
