import React, { useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  Image,
} from "react-native";
import { ListItem, Text, Avatar, Button } from "react-native-elements";
import { imageSources } from "../../assets/images/imageSources";
import { Event, Modality } from "../../types";
import { useParams, useNavigate } from "react-router-dom";
import useOrganizerEvents from "../../hooks/useOrganizerEvents";
import useUserProfile from "../../hooks/useUserProfile";
import { useTheme } from "../../theme/ThemeContext";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ModalityManagementScoringScreen from "../ModalityManagementScreen/ModalityManagementScoringScreen";

import { WebOrganizerNav } from "../../components/WebOrganizerNav/WebOrganizerNav";

const EventList = ({
  event,
  setSelectedModality,
  selectedModality,
}: {
  event: Event;
  setSelectedModality: (mod: Modality) => void;
  selectedModality: Modality | null;
}) => {
  const theme = useTheme();
  const { title, date, modalities } = event;

  return (
    <>
      {modalities
        ? modalities.map((modality: any, index: number) => (
            <ListItem
              key={modality.id}
              onPress={() => {
                setSelectedModality(modality);
              }}
              containerStyle={{
                borderRadius: 5,
                marginBottom: 4,
                backgroundColor:
                  modality.id === selectedModality?.id
                    ? theme.colors.background
                    : theme.colors.white,
              }}
            >
              {/* <Icon name="horseshoe" size={25} color={theme.colors.secondary} /> */}
              <ListItem.Content>
                <ListItem.Title
                  style={[theme.typography.body, { fontWeight: "600" }]}
                >
                  {modality.name} ({modality.enrollmentCount})
                </ListItem.Title>
              </ListItem.Content>
              <ListItem.Chevron color={theme.colors.secondary} size={25} />
            </ListItem>
          ))
        : null}
    </>
  );
};

const EventScoringScreenWeb: React.FC = () => {
  //   const generatedStyles = styles();
  const theme = useTheme();
  const [selectedModality, setSelectedModality] = useState<Modality | null>(
    null
  );
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { userProfile } = useUserProfile();
  const [event, setEvent] = useState<Event>();

  const { acronym } = userProfile || {};
  const initials = `${acronym ?? ""}`;
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);
  const { organizerEvents, loading, error } = useOrganizerEvents();
  const { width } = Dimensions.get("window");
  const isMobile = width <= 768;
  const { eventId } = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(`/evento/${eventId}`);
  };

  const filterEventsById = (id: string): Event[] => {
    return organizerEvents?.filter((event: Event) => event.id === id) || [];
  };

  useEffect(() => {
    if (eventId && organizerEvents) {
      setEvent(filterEventsById(eventId)[0]);
    }
  }, [organizerEvents]);

  useEffect(() => {
    if (organizerEvents && event) {
      const updatedEvent = organizerEvents.find((e) => e.id === event.id);
      if (updatedEvent) {
        const updatedModality = updatedEvent.modalities.find(
          (m) => m.id === selectedModality?.id
        );
        if (updatedModality) {
          setSelectedModality(updatedModality);
        }
      }
    }
  }, [organizerEvents]);

  if (isMobile) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          padding: 30,
        }}
      >
        <Image
          source={imageSources.logoVertical01}
          style={{ width: 146, height: 120 }}
        />
        <Text
          style={[
            theme.typography.h2,
            {
              textAlign: "center",
              marginTop: 20,
              color: theme.colors.secondary,
            },
          ]}
        >
          Baixe o nosso app pra usar as funcionalidades completas do Striboss no
          celular
        </Text>
      </View>
    );
  }

  return (
    <>
      <WebOrganizerNav />
      <View
        style={{
          backgroundColor: theme.colors.white,
          flexDirection: "row",
          flex: 1,
          height: "100%",
          paddingLeft: 15,
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "space-between",
            minWidth: 225,
            height: "100%",
            paddingRight: 15,
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginVertical: 20,
                borderColor: "#ccc",
                borderWidth: 1,
                borderRadius: 10,
                paddingHorizontal: 10,
                paddingVertical: 15,
              }}
            >
              <Icon
                name="arrow-left-circle"
                size={25}
                color={theme.colors.tertiary}
                style={{ marginRight: 15 }}
                onPress={goBack}
              />
              <Text
                style={{
                  ...theme.typography.h3,
                  color: theme.colors.secondary,
                }}
              >
                {event?.title}
              </Text>
            </View>
            <View
              style={{
                height: "75vh",
              }}
            >
              <ScrollView contentContainerStyle={{ borderRadius: 10 }}>
                <View
                  style={{
                    backgroundColor: theme.colors.background,
                    paddingHorizontal: 10,
                    paddingVertical: 15,
                    borderRadius: 5,
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <Icon
                    name="horseshoe"
                    size={20}
                    color={theme.colors.secondary}
                    style={{ marginRight: 10 }}
                  />
                  <Text style={theme.typography.body}>Séries</Text>
                </View>
                {event ? (
                  <EventList
                    key={eventId}
                    event={event}
                    selectedModality={selectedModality}
                    setSelectedModality={setSelectedModality}
                  />
                ) : null}
              </ScrollView>
            </View>
          </View>
        </View>
        <View style={{ flex: 4, backgroundColor: theme.colors.white }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginVertical: 20,
              borderColor: "#ccc",
              borderWidth: 1,
              borderRadius: 10,
              paddingHorizontal: 10,
              paddingVertical: 15,
            }}
          >
            <Text
              style={{
                ...theme.typography.h3,
                color: theme.colors.accent,
              }}
            >
              Área exclusiva do júri
            </Text>
          </View>
          {loading && (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <LoadingIndicator />
            </View>
          )}
          {error && (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                padding: 30,
              }}
            >
              <Text>Erro: {error.message}</Text>
            </View>
          )}
          {organizerEvents ? (
            <View
              style={{
                flexDirection: "row",
                backgroundColor: theme.colors.white,
                borderRadius: 10,
                flex: 1,
              }}
            >
              <View style={{ flex: 4 }}>
                {selectedModality && event ? (
                  <ModalityManagementScoringScreen
                    modality={selectedModality}
                    currentEvent={event}
                  />
                ) : (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text>
                      Selecione uma categoria para ver os concorrentes
                    </Text>
                  </View>
                )}
              </View>
            </View>
          ) : null}
        </View>
      </View>
    </>
  );
};

export default EventScoringScreenWeb;
