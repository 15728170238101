// types.ts

export interface Enrollment {
  modality?: Modality;
  modalityId: string;
  fullName: string | null;
  category: string | null;
  age: number | null;
  rank: number | null;
  score: number | null;
  time: number | null;
  totalFaultPoints: number | null;
  timeFaultPoints: number | null;
  approximation: number | null;
  faults: number | null;
  id: string;
  profile?: User;
  profileId: string;
  event?: Event;
  eventId: string;
  horse?: Horse;
  horseId: string | null;
  approved?: boolean;
  entryOrder: string | null;
  entity: User | null;
  isHorsConcours: boolean | null;
  isForfait: boolean | null;
  isEliminated: boolean | null;
  dressageScores?: FinalDressageScores | null;
  showjumpingScores?: FinalShowjumpingScores | null;
}

export interface PublicEnrollment {
  fullName?: string | null;
  showjumpingScores?: FinalShowjumpingScores | null;
  dressageScores?: FinalDressageScores | null;
  entryOrder?: string | null;
  approved?: boolean;
  isHorsConcours?: boolean | null;
  isForfait?: boolean | null;
  isEliminated?: boolean | null;
  id: string;
  horseName: string | null;
  horseId: string | null;
}

export interface Athlete {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: DateType;
  phone: string;
  isStudent: boolean;
}

export interface EventType {
  id: string;
  name: string;
}

export interface Entity {
  id: string;
  name: string;
  acronym?: string;
  email?: string;
  phone?: string;
  whatsapp?: string;
  address?: string;
  facebookLink?: string;
  instagramLink?: string;
  state: string;
}

export interface Event {
  id: string;
  title: string;
  description?: string;
  date?: DateType;
  endDate?: DateType;
  enrollmentDateLimit?: DateType;
  location?: string;
  eventType?: EventType;
  eventTypeId: string;
  modalities: Modality[];
  enrollments?: Enrollment[];
  organizerId?: string;
  organizerName?: string;
  organizer?: User;
  price?: string;
  totalEntries?: number;
}

export enum ModalityKeys {
  TIME_TRIAL = "timeTrial",
  RACE = "race",
  DERBY = "tour",
  POWER = "power",
  REPRISE = "reprise",
}

export enum EventTypeIds {
  DRESSAGE_EVENT_TYPE_ID = "3f18501c-8bf5-42e2-9ecf-3cbdc84c3ca2",
  SHOWJUMPING_EVENT_TYPE_ID = "18431fa6-1f00-4195-81ed-b14c4167f307",
}

export type ModalityType = "timeTrial" | "race" | "tour" | "power" | "reprise";

export interface Modality {
  id: string;
  name?: string;
  type?: ModalityType;
  rankingBy?: "series" | "category";
  velocity?: number | null;
  distance?: number | null;
  manualAllottedTime?: number | null;
  penaltyPointsPerSecond?: number | null;
  numCompetitions?: number;
  numJumps?: number | null;
  jumpsArray?: string[];
  trackType?: string;
  description?: string;
  date?: DateType;
  time?: DateType;
  event?: Event;
  eventId: string;
  enrollments?: Enrollment[];
  enrollmentCount?: number;
  categories?: string[];
  dressageRepriseId?: string;
}

export interface Horse {
  id: string;
  name: string;
  breed: string;
  age: number;
  owner: User;
  ownerId: string;
  Participant: Enrollment[];
  dateOfBirth: DateType;
  height: string;
  sex: string;
}

export interface User {
  id: string;
  email: string;
  password: string;
  role: string;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: DateType;
  phone: string | null;
  events: Event[];
  horses: Horse[];
  acronym: string | null;
  eventTypePrefs: UserEventTypePref[];
  eventTypePref: EventType;
  Participant: Enrollment[];
  companyName: string | null;
  companyLogo: string | null;
  website: string | null;
  description: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  whatsapp: string | null;
  isStudent: boolean;
  entityId: string | null;
  entity: Entity;
  pix: string;
}
export interface UserType {
  id: string;
  email: string;
  password: string;
  role: string;
  firstName: string;
  lastName: string;
  dateOfBirth: DateType;
  phone: string;
  acronym: string;
  companyName: string;
  companyLogo: string;
  website: string;
  description: string;
  contactEmail: string;
  contactPhone: string;
  eventTypePrefId: string;
  entityId?: string;
  isStudent?: boolean;
  expoPushToken?: string;
}

export interface UserEventTypePref {
  id: string;
  user: User;
  userId: string;
  eventType: EventType;
  eventTypeId: string;
}

export interface Entity {
  id: string;
  name: string;
}

export interface Dependent {
  id: string;
  fullName: string;
  dateOfBirth: DateType;
  parentId: string;
}

export interface FirestoreTimestamp {
  seconds: number;
  nanoseconds: number;
}

export type DateType = Date | FirestoreTimestamp | undefined | string;

export type Reprise = {
  id: string;
  name: string;
  technicalMarks: Marks[];
  artisticMarks: Marks[];
  objective?: string;
  introduction?: string;
  conditions?: string;
  averageTime?: string;
  maxScore?: string;
};

export type Marks = {
  description: string;
  directives: string;
  coefficient: number;
};

export type MovementScore = {
  description: string;
  score: number;
  remarks?: string;
  coefficient: number;
};

export type FaultsArray = {
  [key: string]: number;
};

export type FinalDressageScores = {
  totalScore: number;
  technicalScores: MovementScore[];
  artisticScores: MovementScore[];
  deductions?: number;
  repriseId: string;
  repriseName: string;
};

export type FinalShowjumpingScores = {
  time: number | null;
  faults: number | null;
  penalties: number | null;
  approximation: number | null;
  faultsArray: FaultsArray | null;
  totalFaults: number | null;
};
