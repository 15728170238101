//src/context/AuthContext.ts
import React, { createContext, useContext } from "react";
import { useAuth } from "../hooks/useAuth";
import { UserType } from "../types";

type AuthContextProps = {
  isAuthenticated: boolean;
  emailVerified: boolean;
  initializing: boolean;
  userRole: string | null;
  userId?: string | null;
  userEmail?: string | null;
  userData?: UserType | null;
};

const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  emailVerified: false,
  initializing: false,
  userRole: null,
  userEmail: null,
  userData: null,
  userId: null,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, userRole, user, initializing, userData } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userRole,
        userData,
        initializing,
        emailVerified: user?.emailVerified ?? false,
        userEmail: user?.email,
        userId: user?.uid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
