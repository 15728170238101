// src/navigation/PrivateWrapper.tsx
import React, { ReactNode } from "react";
import { View } from "react-native";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";

interface PrivateWrapperProps {
  children: ReactNode;
}

const PrivateWrapper: React.FC<PrivateWrapperProps> = ({ children }) => {
  const { isAuthenticated, userRole, initializing } = useAuthContext();

  if (initializing) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <LoadingIndicator />
      </View>
    ); // Show a loading indicator while waiting for the auth state
  }

  return isAuthenticated &&
    (userRole === "ORGANIZER" || userRole === "ADMIN") ? (
    <>{children}</>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateWrapper;
