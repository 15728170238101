import React, { useState, useEffect } from "react";
import { View, ScrollView, TouchableOpacity } from "react-native";
import { Text, Button, Input, Divider, TextArea } from "native-base";
import { useTheme } from "../../theme/ThemeContext";
import {
  Enrollment,
  FinalDressageScores,
  MovementScore,
  Reprise,
} from "../../types";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { Overlay } from "react-native-elements";
import { formatDecimalInput } from "../../utils";

interface DressageScoringModalProps {
  onClose: () => void;
  isVisible: boolean;
  isLoading: boolean;
  reprise: Reprise;
  onSave: (scores: FinalDressageScores) => void;
  openEditEnrollment: () => void;
  enrollment: Enrollment | null;
}

export const DressageScoringModal: React.FC<DressageScoringModalProps> = ({
  isVisible,
  onClose,
  onSave,
  reprise,
  isLoading,
  openEditEnrollment,
  enrollment,
}) => {
  const [technicalScores, setTechnicalScores] = useState<MovementScore[]>([]);
  const [artisticScores, setArtisticScores] = useState<MovementScore[]>([]);
  const [deductions, setDeductions] = useState<number>(0);
  const theme = useTheme();

  useEffect(() => {
    if (enrollment && enrollment.dressageScores) {
      setTechnicalScores(enrollment.dressageScores.technicalScores);
      setArtisticScores(enrollment.dressageScores.artisticScores);
      setDeductions(enrollment.dressageScores.deductions ?? 0);
    }
  }, [enrollment]);

  const updateTechnicalScore = (
    index: number,
    description: string,
    coefficient: number,
    score: string,
    remarks: string
  ) => {
    let newTechnicalScores = [...technicalScores];
    const formattedScore = parseFloat(
      formatDecimalInput(score.replace(/[^0-9.,]/g, ""))
    );
    newTechnicalScores[index] = {
      ...newTechnicalScores[index],
      description,
      coefficient,
      score: formattedScore,
      remarks,
    };
    setTechnicalScores(newTechnicalScores);
  };

  const updateArtisticScore = (
    index: number,
    description: string,
    coefficient: number,
    score: string,
    remarks: string
  ) => {
    let newArtisticScores = [...artisticScores];

    const formattedScore = parseFloat(
      formatDecimalInput(score.replace(/[^0-9.,]/g, ""))
    );
    newArtisticScores[index] = {
      ...newArtisticScores[index],
      description,
      coefficient,
      score: formattedScore,
      remarks,
    };
    setArtisticScores(newArtisticScores);
  };

  const totalScore =
    technicalScores.reduce(
      (total, { score, coefficient }) =>
        total + (score ? score * (coefficient ? coefficient : 1) : 0),
      0
    ) +
    artisticScores.reduce(
      (total, { score, coefficient }) =>
        total + (score ? score * (coefficient ? coefficient : 1) : 0),
      0
    ) -
    deductions;

  const handleSave = () => {
    const finalDressageScores: FinalDressageScores = {
      totalScore,
      technicalScores,
      artisticScores,
      deductions,
      repriseId: reprise.id,
      repriseName: reprise.name,
    };
    onSave(finalDressageScores);
  };

  return (
    <Overlay
      isVisible={isVisible}
      onBackdropPress={onClose}
      overlayStyle={{ maxHeight: "90%", width: "95%", padding: 0 }}
    >
      <View
        style={{
          padding: 15,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text style={[theme.typography.h3, { fontWeight: "600" }]}>
          Movimentos
        </Text>
        <Icon
          name="close"
          size={25}
          color={theme.colors.secondary}
          onPress={onClose}
        />
      </View>
      <Divider />
      <ScrollView style={{ paddingHorizontal: 10 }}>
        <TouchableOpacity
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: 15,
            backgroundColor: theme.colors.background,
          }}
          onPress={openEditEnrollment}
        >
          <Text
            style={{
              ...theme.typography.h3,
              marginRight: 20,
              fontWeight: "600",
              color: theme.colors.tertiary,
            }}
          >
            Editar Inscrição
          </Text>
          <Icon name="pencil-circle" size={35} color={theme.colors.tertiary} />
        </TouchableOpacity>
        {reprise.technicalMarks.map((mark, index) => (
          <View key={index}>
            <View style={{ flexDirection: "row", marginVertical: 10 }}>
              <View
                style={{ flex: 1, padding: 5, justifyContent: "space-between" }}
              >
                <Text style={[theme.typography.small, { fontWeight: "600" }]}>
                  {index + 1} - {mark.description}
                </Text>
                {mark.coefficient === 2 ? (
                  <View
                    style={{
                      alignItems: "center",
                      paddingVertical: 4,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: theme.colors.background,
                        borderRadius: 15,
                        paddingHorizontal: 8,
                        paddingVertical: 5,
                      }}
                    >
                      <Text>x2</Text>
                    </View>
                  </View>
                ) : null}

                <Input
                  placeholder="grau"
                  value={technicalScores[index]?.score.toString()}
                  keyboardType="decimal-pad"
                  inputMode="decimal"
                  size="md"
                  onChangeText={(value) =>
                    updateTechnicalScore(
                      index,
                      mark.description,
                      mark.coefficient,
                      value,
                      technicalScores[index]?.remarks || ""
                    )
                  }
                />
              </View>

              <View
                style={{ flex: 2, padding: 5, justifyContent: "space-between" }}
              >
                <Text style={[theme.typography.small, { fontWeight: "400" }]}>
                  {mark.directives}
                </Text>
                <TextArea
                  variant="outline"
                  value={technicalScores[index]?.remarks}
                  aria-label="t1"
                  placeholder="observ"
                  onChangeText={(value) =>
                    updateTechnicalScore(
                      index,
                      mark.description,
                      mark.coefficient,
                      technicalScores[index]?.score.toString() || "0",
                      value
                    )
                  }
                  mt={2}
                  size="md"
                  type="text"
                  autoCompleteType=""
                />
              </View>
            </View>

            <Divider />
          </View>
        ))}
        <View style={{ marginVertical: 10 }}>
          <Text>Notas de conjunto</Text>
        </View>
        <Divider />
        {reprise.artisticMarks.map((mark, index) => (
          <View key={index}>
            <View style={{ flexDirection: "row", marginVertical: 10 }}>
              <View
                style={{ flex: 1, padding: 5, justifyContent: "space-between" }}
              >
                <Text style={[theme.typography.small, { fontWeight: "600" }]}>
                  {mark.description}
                </Text>
                <Input
                  placeholder="grau"
                  value={artisticScores[index]?.score.toString()}
                  keyboardType="decimal-pad"
                  inputMode="decimal"
                  size="md"
                  onChangeText={(value) =>
                    updateArtisticScore(
                      index,
                      mark.description,
                      mark.coefficient,
                      value,
                      artisticScores[index]?.remarks || ""
                    )
                  }
                />
              </View>
              <View
                style={{ flex: 2, padding: 5, justifyContent: "space-between" }}
              >
                <Text style={[theme.typography.small, { fontWeight: "400" }]}>
                  {mark.directives}
                </Text>
                <TextArea
                  variant="outline"
                  aria-label="t1"
                  placeholder="observ"
                  value={artisticScores[index]?.remarks}
                  onChangeText={(value) =>
                    updateArtisticScore(
                      index,
                      mark.description,
                      mark.coefficient,
                      artisticScores[index]?.score.toString() || "0",
                      value
                    )
                  }
                  mt={2}
                  size="md"
                  type="text"
                  autoCompleteType=""
                />
              </View>
            </View>

            <Divider />
          </View>
        ))}
        <View style={{ marginVertical: 20 }}>
          <Input
            placeholder="Deduções"
            keyboardType="decimal-pad"
            inputMode="decimal"
            onChangeText={(value) => setDeductions(Number(value))}
          />
        </View>
      </ScrollView>
      <Divider />
      <View style={{ padding: 10 }}>
        <Button
          onPress={handleSave}
          _text={{ fontWeight: "semibold" }}
          style={{ backgroundColor: theme.colors.secondary }}
          isLoading={isLoading}
        >
          Salvar
        </Button>
      </View>
    </Overlay>
  );
};
