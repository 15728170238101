// EventCreationScreen/styles.ts
import { StyleSheet } from "react-native";
import { useTheme } from "../../theme/ThemeContext";

export default function styles() {
  const theme = useTheme();
  return StyleSheet.create({
    container: {
      backgroundColor: theme.colors.white,
    },
    buttonContainer: {
      flexDirection: "row",
    },
    title: {
      padding: 15,
    },
  });
}
