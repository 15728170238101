import React from "react";
import Svg, { Path } from "react-native-svg";

interface PathProps {
  d: string;
  color?: string;
}

interface Props {
  paths: PathProps[];
  size?: number;
  width?: number;
  height?: number;
  viewBox?: string;
}

export const CorcelIcon: React.FC<Props> = ({
  paths,
  size = 50,
  width,
  height,
  viewBox = "0 0 50 50",
}) => {
  return (
    <Svg width={width ?? size} height={height ?? size} viewBox={viewBox}>
      {paths.map((pathProps, index) => (
        <Path key={index} {...pathProps} fill={pathProps.color} />
      ))}
    </Svg>
  );
};
