// src/screens/PrivacyPolicyScreen/PrivacyPolicyScreen.tsx
import React from "react";
import { ScrollView, Text, View, StyleSheet } from "react-native";
import { useTheme } from "../../theme/ThemeContext";

const PrivacyPolicyScreen: React.FC = () => {
  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.title}>
        POLÍTICA DE PRIVACIDADE DO APLICATIVO STRIBOSS
      </Text>
      <Text style={styles.date}>Última atualização: 31/05/2023</Text>
      <Text style={styles.content}>
        Bem-vindo ao aplicativo Striboss! Sua privacidade é de extrema
        importância para nós. Esta Política de Privacidade descreve como nós,
        Striboss, coletamos, usamos, processamos e compartilhamos as informações
        que coletamos através de nosso aplicativo. Ao usar nosso aplicativo,
        você concorda com a coleta e uso de suas informações de acordo com esta
        política. Caso não concorde com esta política, não utilize este
        aplicativo. {"\n\n"}
        1. INFORMAÇÕES COLETADAS {"\n\n"}
        1.1. Informações de Identificação: Ao se registrar em nosso aplicativo,
        solicitamos que você nos forneça informações pessoais que podem ser
        usadas para entrar em contato com você ou identificá-lo. Essas
        informações podem incluir, mas não se limitam a: seu nome; endereço de
        e-mail; número de telefone; data de nascimento; gênero; foto de perfil e
        localização geográfica. Reconhecemos a importância da privacidade dessas
        informações e garantimos que serão tratadas de acordo com as disposições
        desta Política de Privacidade.
        {"\n\n"}
        1.2. Informações de Uso: Também podemos coletar informações técnicas
        sobre como nosso aplicativo é acessado e utilizado. Esses Dados de Uso
        podem incluir informações como: informações do dispositivo, como modelo,
        sistema operacional e identificador único, endereço IP; informações de
        localização e dados sobre a forma como você utiliza o aplicativo, como
        as páginas visitadas, horário e dados de acesso, tempo gasto nessas
        páginas e outras informações diagnósticas relevantes. A coleta desses
        dados tem como objetivo aprimorar a qualidade e a experiência de uso do
        aplicativo, bem como possibilitar análises estatísticas para a melhoria
        contínua dos nossos serviços. Todas as informações coletadas são
        tratadas de forma confidencial e utilizadas de acordo com nossa Política
        de Privacidade. {"\n\n"}
        2. USO DAS INFORMAÇÕES {"\n\n"}
        2.1. Como Usamos suas Informações: Utilizamos as informações com as
        finalidades de: fornecer, manter e melhorar o aplicativo e seus
        recursos; prestar suporte ao cliente; permitir que você participe de
        recursos interativos do nosso aplicativo, quando você optar por fazê-lo;
        comunicar-se com você sobre atualizações, promoções ou eventos;
        detectar, prevenir e lidar com atividades fraudulentas, ilegais ou
        violar nossos termos de serviço; realizar análises e pesquisas internas
        para melhorar nossos serviços.
        {"\n\n"}
        3. COMPARTILHAMENTO DE INFORMAÇÕES {"\n\n"}
        3.1. Com quem Compartilhamos suas Informações: Na Striboss, valorizamos
        sua privacidade e não vendemos, trocamos ou alugamos suas informações
        pessoais com terceiros. No entanto, existem algumas circunstâncias em
        que podemos compartilhar suas informações. Isso inclui o
        compartilhamento com fornecedores que nos auxiliam na operação e
        melhoria do aplicativo, com parceiros de negócios para fins de pesquisa
        e desenvolvimento, bem como com os organizadores de campeonatos
        esportivos nos quais você se inscreveu por meio do nosso aplicativo.
        Ademais, podemos compartilhar suas informações quando exigidas por lei
        ou regulamentação, ou quando houver necessidade de proteger a segurança,
        propriedade ou direitos da Striboss, de nossos usuários ou de outras
        pessoas. Por fim, podemos compartilhar suas informações no caso de uma
        fusão, aquisição ou venda de ativos, em que as informações do usuário
        são consideradas um ativo transferível. Ressaltamos que qualquer
        compartilhamento de informações será realizado de acordo com as leis de
        proteção de dados e em conformidade com nossa Política de Privacidade.
        {"\n\n"}
        4. RETENÇÃO DE DADOS {"\n\n"}
        4.1. Retenção de Dados Pessoais: As informações pessoais fornecidas por
        você ao se cadastrar em nosso aplicativo serão retidas enquanto sua
        conta estiver ativa ou conforme necessário para o fornecimento de
        serviços. Entendemos a importância de manter seus dados atualizados e
        seguros durante o período de uso do nosso aplicativo. Caso você decida
        encerrar sua conta, faremos todos os esforços para excluir suas
        informações de forma segura e dentro de um prazo razoável, a menos que
        sejamos obrigados por lei a mantê-las por um período mais longo.
        {"\n\n"}
        4.2. Retenção de Dados por Motivos Legais e Comerciais: Podemos também
        reter informações pessoais, mesmo após o encerramento da sua conta,
        quando necessário para cumprir obrigações legais, resolver disputas,
        aplicar nossos termos de uso ou para fins comerciais legítimos. Esses
        fins comerciais podem incluir medidas de prevenção de fraudes e
        aprimoramento da segurança.
        {"\n\n"}
        5. MEDIDAS DE SEGURANÇA {"\n\n"}
        Na Striboss, priorizamos a segurança das informações pessoais dos nossos
        usuários. Implementamos medidas de segurança para proteger seus dados
        contra acesso não autorizado, alterações, divulgação ou destruição.
        Nossas medidas de segurança incluem: {"\n\n"}
        5.1. Criptografia de dados: Utilizamos protocolos de criptografia para
        proteger a transmissão e o armazenamento dos dados pessoais dos
        usuários. {"\n\n"}
        5.2. Controles de acesso: Apenas funcionários autorizados têm acesso às
        informações pessoais dos usuários, e isso é limitado ao necessário para
        a prestação dos serviços solicitados. {"\n\n"}
        5.3. Monitoramento e auditoria: Realizamos monitoramento contínuo e
        auditoria dos nossos sistemas para identificar e responder a possíveis
        ameaças ou proteção de segurança. {"\n\n"}
        6. LIMITAÇÕES DE SEGURANÇA: {"\n\n"}
        Apesar dos nossos esforços contínuos para garantir a segurança das
        informações pessoais dos nossos usuários, é importante destacar que
        nenhum método de transmissão pela internet ou processamento eletrônico é
        totalmente seguro. Compreendemos as preocupações relacionadas à
        segurança dos dados e reconhecemos que existem riscos inerentes. Alguns
        desses desafios podem incluir: {"\n\n"}
        6.1. Riscos da internet: A transmissão de dados pela internet pode estar
        sujeita a riscos, como intercepção, acesso não autorizado ou alteração
        por terceiros malintencionados. {"\n\n"}
        6.2. Vulnerabilidades do sistema: Mesmo com medidas de segurança
        robustas, sistemas de computador e servidores podem estar sujeitos a
        vulnerabilidades que podem ser exploradas por indivíduos
        mal-intencionados.{"\n\n"}
        6.3. Uso de dispositivos pessoais: O uso de dispositivos pessoais para
        acessar e transmitir informações também apresenta riscos adicionais,
        como perda ou roubo desses dispositivos.{"\n\n"}
        Embora não possamos garantir a segurança absoluta das informações,
        estamos comprometidos em revisar e aprimorar constantemente nossos
        sistemas e práticas de segurança para proteger os dados dos nossos
        usuários da melhor forma possível.{"\n\n"}
        7. SEUS DIREITOS
        {"\n\n"}
        7.1. Acesso e Retificação: Você tem o direito de acessar, corrigir,
        atualizar, excluir e solicitar uma cópia das informações pessoais que
        temos sobre você. Caso encontre imprecisões ou incompletude em seus
        dados, você pode solicitar uma retificação ou atualização das
        informações entrando em contato conosco pelo email
        striboss.app@gmail.com. {"\n\n"}
        7.2. Exclusão de Dados: Você tem o direito de solicitar a exclusão de
        suas informações pessoais armazenadas em nosso aplicativo. Para isso,
        pedimos para que entre em contato conosco através dos canais fornecidos
        nesta Política de Privacidade. Nos empenharemos em atender integralmente
        sua solicitação de exclusão, salvo quando houver obrigações legais que
        nos exijam reter determinadas informações. Recomendamos que você avalie
        cuidadosamente os possíveis efeitos antes de solicitar a exclusão de
        seus dados, pois a exclusão de suas informações pessoais pode afetar a
        funcionalidade e a personalização do aplicativo. {"\n\n"}
        8. REQUISITO DE IDADE {"\n\n"}
        8.1. Requisito de Idade Mínima: Para usar o aplicativo, você deve ter
        pelo menos 18 anos ou a idade legal aplicável em seu país de residência.
        Ao usar o aplicativo, você declara e garante que atende a esse requisito
        de idade mínima. {"\n\n"}
        9. ALTERAÇÕES NESTA POLÍTICA {"\n\n"}A Striboss reserva-se o direito de
        revisar e atualizar esta Política de Privacidade a qualquer momento e
        por qualquer motivo. Quando fizermos alterações nestas políticas,
        atualizaremos a data de "última atualização" no topo deste documento.{" "}
        {"\n\n"}
        9.1. Notificação de Alterações: Se houver alterações nesta Política de
        Privacidade, nos esforçaremos para fornecer um aviso mais destacado, que
        pode incluir, por exemplo, um aviso por e-mail ou no aplicativo. É
        recomendável que você revise periodicamente estes Termos de Uso para se
        manter atualizado sobre nossas práticas. {"\n\n"}
        9.2. Aceitação das Alterações: O uso contínuo do aplicativo Striboss
        após a publicação das alterações a estas políticas constituirá a sua
        aceitação dessas alterações. Se você não concordar com as alterações,
        você deve parar de usar o aplicativo. {"\n\n"}
        10. CONTATO
        {"\n\n"} Caso tenha alguma dúvida, comentário ou preocupação sobre a
        nossa Política de Privacidade, entre em contato conosco: {"\n\n"}
        Striboss.app{"\n\n"}
        striboss.app@gmail.com{"\n\n"}
        (61) 9 8109-0640
      </Text>
    </ScrollView>
  );
};

const createStyles = (theme: any) => {
  return StyleSheet.create({
    container: {
      flexGrow: 1,
      padding: 15,
      backgroundColor: theme.colors.background,
    },
    title: {
      fontSize: 24,
      fontWeight: "bold",
      marginBottom: 15,
      color: theme.colors.text,
    },
    date: {
      fontSize: 14,
      fontStyle: "italic",
      marginBottom: 15,
      color: theme.colors.text,
    },
    content: {
      fontSize: 16,
      color: theme.colors.text,
      lineHeight: 22,
    },
  });
};

export default PrivacyPolicyScreen;
