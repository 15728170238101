// src/screens/TermsAndConditionsScreen/TermsAndConditionsScreen.tsx
import React from "react";
import { ScrollView, Text, View, StyleSheet } from "react-native";
import { useTheme } from "../../theme/ThemeContext";

const TermsAndConditionsScreen: React.FC = () => {
  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.title}>TERMOS DE USO DO APLICATIVO STRIBOSS</Text>
      <Text style={styles.date}>Última atualização: 31/05/2023</Text>
      <Text style={styles.content}>
        Bem-vindo ao aplicativo Striboss!
        {"\n\n"}
        Antes de começar a utilizar nossa plataforma, é importante que você leia
        atentamente e concorde com os termos e condições a seguir. Ao utilizar o
        nosso aplicativo, você estará concordando com todas as disposições
        descritas neste termo. Se você não concordar com alguns destes Termos,
        não use este aplicativo.
        {"\n\n"}
        1. ACESSO E USO DO APLICATIVO: {"\n\n"}
        1.1. Licença: A Striboss concede a você uma licença revogável, não
        exclusiva e não transferível para acessar e usar o aplicativo de acordo
        com estes Termos de Uso. {"\n\n"}
        1.2. Requisito de Idade: Para usar o aplicativo, você deve ter pelo
        menos 18 anos ou a idade legal aplicável em seu país de residência. Ao
        usar o aplicativo, você declara e garante que atende a esse requisito de
        idade mínima {"\n\n"}
        1.3. Restrições de Uso: O aplicativo Striboss é desenvolvido para
        auxiliar na organização e divulgação de eventos equestres. Ao utilizar o
        aplicativo, você concorda em não modificar, distribuir, sublicenciar ou
        vender qualquer parte do nosso aplicativo. Além disso, você concorda em
        não o utilizar para fins ilegais ou proibidos por estes Termos de Uso,
        ou de qualquer maneira que possa prejudicar o funcionamento normal do
        aplicativo. {"\n\n"}
        1.4. Contas de Usuário: Para acessar determinados recursos do
        aplicativo, é necessário criar uma conta de usuário. É sua
        responsabilidade manter a confidencialidade das informações de sua
        conta, incluindo a senha, e por todas as atividades realizadas por meio
        dela. A Striboss não será responsável por qualquer perda ou dano
        decorrente do descumprimento desta obrigação. Também é de sua
        responsabilidade garantir que todas as informações fornecidas sejam
        verdadeiras e precisas. Qualquer informação falsa, enganosa ou
        incompleta pode resultar na suspensão ou cancelamento de sua conta. Por
        isso, forneça apenas informações corretas e atualizadas ao usar o
        aplicativo. Reservamo-nos o direito exclusivo de encerrar contas,
        removedor ou editar conteúdo, ou cancelar pedidos conforme julgarmos
        necessário. {"\n\n"}
        1.5. Proteção de Dados: A sua privacidade é importante para nós! Todas
        as informações pessoais que coletamos ao criar uma conta ou utilizar o
        nosso aplicativo são tratadas de acordo com a nossa Política de
        Privacidade. {"\n\n"}
        1.6. Disponibilidade do Serviço: A Striboss se compromete a
        disponibilizar o aplicativo de forma contínua e ininterrupta, utilizando
        de todos os esforços para garantir a disponibilidade do serviço 24 horas
        por dia, sete dias por semana. No entanto, haverá ocasiões em que o
        aplicativo precisará ser interrompido para manutenção, atualizações e
        reparos, ou devido à falha de equipamentos de telecomunicações ou outros
        motivos além do nosso controle. A Striboss não será responsável por
        quaisquer interrupções, atrasos ou erros causados no aplicativo.{" "}
        {"\n\n"}
        2. PRIVACIDADE E PROTEÇÃO DE DADOS: {"\n\n"}
        2.1. Compromisso com a Privacidade: Na Striboss valorizamos sua
        privacidade e estamos empenhados em proteger suas informações pessoais.
        Coletamos, armazenamos e utilizamos seus dados em conformidade com as
        leis de proteção de dados e nossa Política de Privacidade. {"\n\n"}
        2.2. Confidencialidade e não Divulgação de Informações Pessoais: Nós não
        divulgamos ou compartilhamos suas informações pessoais com terceiros,
        exceto quando necessário para garantir a prestação de serviço
        solicitado, ou quando exigido por lei. Destacamos ainda que, ao se
        inscrever em campeonatos esportivos por meio do nosso aplicativo,
        compartilharemos suas informações com os organizadores desses eventos,
        com o objetivo de viabilizar e assegurar sua participação. {"\n\n"}
        2.3. Medidas de Segurança e Limitações: A segurança das suas informações
        pessoais é importante para nós. Implementamos medidas de segurança para
        protege-las contra acesso não autorizado, alterações, divulgação ou
        exclusão. É fundamental salientar que nenhum método de transmissão pela
        internet ou armazenamento eletrônico é totalmente seguro e que apesar
        dos nossos esforços contínuos, não garantimos a segurança absoluta das
        informações transmitidas ou armazenadas em nosso aplicativo. {"\n\n"}
        2.4. Revisão da Política de Privacidade: Recomendamos que você revise
        nossa Política de Privacidade para obter informações discriminadas sobre
        como coletamos, usamos e protegemos seus dados pessoais. A Política de
        Privacidade aborda em profundidade os procedimentos e práticas
        relacionadas à privacidade e proteção de dados. {"\n\n"}
        3. PROPRIEDADE INTELECTUAL: {"\n\n"}
        Ao utilizar o aplicativo Striboss, você reconhece e concorda
        integralmente que todos direitos de propriedade intelectual relacionados
        ao aplicativo são de propriedade exclusiva da empresa ou de seus
        licenciados. Isso inclui seu design, logotipos, recursos,
        funcionalidade, conteúdo, direitos autorais e outros direitos
        relacionados ao nosso aplicativo. {"\n\n"}
        3.1. Uso do Conteúdo: Nenhuma parte do aplicativo e nenhum conteúdo
        podem ser copiados, reproduzidos, agregados, republicados, carregados,
        postados, exibidos publicamente, codificados, traduzidos, transmitidos,
        distribuídos, vendidos, licenciados ou explorados para qualquer
        finalidade comercial, sem o consentimento prévio por escrito da
        Striboss. {"\n\n"}
        3.2. Violação de Direitos Autorais: Respeite e proteja esses direitos
        para garantir o desenvolvimento contínuo do aplicativo Striboss. O uso
        não autorizado pode resultar em medidas legais em conformidade com lei
        aplicável. Em caso de violação, entre em contato conosco imediatamente.{" "}
        {"\n\n"}
        3.2. Marcas Registradas: A marca e o logotipo da Striboss são
        propriedade exclusiva da empresa e estão devidamente registrados. Não é
        permitido o uso dessas marcas sem a autorização prévia e expressa da
        Striboss. Reservamo-nos o direito de tomar medidas legais adequadas para
        proteger nossa marca registrada contra qualquer uso não autorizado.
        {"\n\n"}
        4. RESPONSABILIDADE E LIMITAÇÃO: {"\n\n"}
        4.1. Responsabilidades do Usuário: Ao utilizar o aplicativo Striboss,
        você concorda em utilizar o serviço de maneira lícita, respeitando todas
        as leis aplicáveis e os termos deste acordo.
        {"\n\n"}
        4.2. Isenção de Responsabilidade: Em nenhuma circunstância a Striboss
        será responsável por quaisquer danos, sejam diretos, indiretos,
        incidentais ou consequenciais, que possam surgir em decorrência do uso
        ou incapacidade de usar o aplicativo, incluindo, mas não se limitando a,
        atrasos ou falhas na transmissão de dados; danos causados por vírus,
        malware ou qualquer outra forma de conteúdo malicioso. {"\n\n"}
        4.3. Informações Fornecidas: A Striboss não se responsabiliza por
        qualquer informação inserida pelo usuário no aplicativo. O usuário
        garante a veracidade, precisão e autenticidade de todas as informações
        fornecidas, reservando-se o direito da Striboss de verificar tais
        informações a qualquer momento. {"\n\n"}
        4.4. Eventos e Organizadores: O aplicativo Striboss é uma plataforma que
        facilita a organização e divulgação de campeonatos equestres. No
        entanto, não nos responsabilizamos pelo sucesso dos eventos, pelo
        cumprimento das leis e regulamentos específicos ou por quaisquer outras
        questões fora do âmbito de gerenciamento do aplicativo. Vale ressaltar
        que a Striboss não é um organizador ou proprietário dos eventos listados
        no aplicativo. Não somos responsáveis por examinar ou garantir a
        qualidade, segurança ou legalidade dos eventos, nem a veracidade ou
        precisão das imagens dos mesmos. A capacidade dos organizadores de
        realizar os eventos e a capacidade dos participantes de participar são
        de responsabilidade exclusiva de cada parte envolvida. {"\n\n"}
        4.5. Participação por Conta e Risco: Ao participar dos eventos listados
        no aplicativo, você assume total responsabilidade por suas ações e
        decisões. É altamente recomendável que você realize uma pesquisa
        completa e cuidadosa sobre qualquer evento antes de confirmar sua
        participação, garantindo assim sua própria segurança e satisfação.{" "}
        {"\n\n"}
        4.6. Desacordo entre Usuários e Organizadores: A Striboss não assume
        responsabilidade pela resolução de quaisquer disputas entre os usuários
        e os organizadores de eventos listados no aplicativo. Caso surja algum
        desacordo ou disputa com um organizador de evento, recomendamos que você
        busque resolver a questão diretamente com ele. A Striboss não se envolve
        nas relações ou participa de disputas entre as partes envolvidas,
        ficando isenta de qualquer responsabilidade nesse sentido.
        {"\n\n"}
        4.7. Não há Garantias: A Striboss não garante a satisfação com qualquer
        evento listado no aplicativo. Em nenhuma circunstância a Striboss será
        responsável por qualquer dano ou perda causada ou supostamente causada
        por seu uso ou confiança em qualquer conteúdo, bens ou serviços
        disponíveis em ou através de qualquer evento listado no aplicativo.
        {"\n\n"}
        4.8. Ações ou Omissões dos Organizadores e Participantes: Você concorda
        em isentar e não responsabilizar a Striboss e seus funcionários,
        diretores, afiliados e agentes de quaisquer reivindicações,
        responsabilidades, danos, perdas ou despesas, incluindo honorários
        advocatícios, decorrentes de:
        {"\n\n"}
        (I) Sua participação em eventos listados no aplicativo Striboss,
        incluindo qualquer perda ou dano que você possa sofrer como resultado
        dessa participação.
        {"\n\n"}
        (II) Quaisquer ações ou omissões dos organizadores de eventos ou outros
        concorrentes, incluindo, mas não se limitando a, eventos cancelados,
        perigosos ou ilegais, ou conduta imprópria por parte dos organizadores.
        {"\n\n"}
        5. RECISÃO DE SERVIÇO: {"\n\n"}
        5.1. Direitos da Striboss: A Striboss reserva-se o direito, a seu
        exclusivo critério, de fazer modificações, suspender ou descontinuar o
        aplicativo, ou qualquer serviço, conteúdo, recurso ou produto oferecido
        através do aplicativo, a qualquer momento, sem aviso prévio e sem
        responsabilidade. Essas alterações podem ocorrer por razões técnicas ou
        de segurança. Faremos o possível para informar aos usuários sobre
        quaisquer alterações feitas por meio de comunicados no próprio
        aplicativo ou por outros meios adequados, porém não garantimos a
        notificação prévia em todos os casos. {"\n\n"}
        5.2. Direitos do Usuário: Você tem o direito de encerrar o uso do
        aplicativo a qualquer momento. Você pode descontinuar o uso do
        aplicativo ou, se preferir, pode nos solicitar a exclusão da sua conta.
        Salientamos que se você solicitar a exclusão da sua conta, pode haver um
        atraso enquanto processamos o pedido e removemos suas informações.{" "}
        {"\n\n"}
        5.3. Consequências da Rescisão: Após a rescisão da sua conta, você
        perderá o acesso a todos os serviços, informações e conteúdos associados
        à sua conta. Você concorda que a Striboss não será responsável por
        qualquer perda de conteúdo e recursos resultantes da rescisão da sua
        conta.
        {"\n\n"}
        5.4. Sobrevivência: As disposições destes Termos de Uso que, por sua
        natureza, são destinadas a permanecer em vigor mesmo após a rescisão,
        incluindo, mas não se limitando a, disposições sobre propriedade
        intelectual, isenção de garantia, indenização e limitação de
        responsabilidade, continuarão em pleno vigor e efeito após a rescisão do
        seu uso do aplicativo Striboss.
        {"\n\n"}
        6. INDENIZAÇÃO {"\n\n"}
        Você concorda em defender, indenizar e isentar a Striboss, suas
        afiliadas, diretores, funcionários e agentes de qualquer reivindicação,
        responsabilidades, danos, julgamentos, perdas, custos, despesas ou
        honorários (incluindo honorários advocatícios) resultantes de ou de
        alguma forma relacionados a: {"\n\n"}
        6.1. Uso indevido do aplicativo por parte do usuário; {"\n\n"}
        6.2. Violação dos presentes Termos de Uso; {"\n\n"}
        6.3. Envio, postagem ou transmissão de qualquer informação (incluindo
        informações do usuário ou qualquer outro conteúdo) no aplicativo;{" "}
        {"\n\n"}
        6.4. Violação de quaisquer direitos de terceiros, incluindo direitos de
        privacidade ou propriedade intelectual; {"\n\n"}
        6.5. Violação de qualquer lei, regra ou regulamento aplicável; {"\n\n"}
        6.6. Reivindicações ou danos resultantes das informações ou conteúdos
        fornecidos pelo usuário à Striboss. {"\n\n"}
        7. LINKS PARA OUTROS SITES {"\n\n"} O aplicativo pode conter links para
        sites de terceiros que não são de propriedade ou controlados pela
        Striboss. {"\n\n"}
        7.1. Sem Endosso: A Striboss não endossa, não tem controle e não assume
        responsabilidade pelo conteúdo, políticas de privacidade, ou práticas de
        quaisquer sites ou serviços de terceiros. Além disso, a Striboss não
        pode censurar ou editar o conteúdo de qualquer site de terceiros.{" "}
        {"\n\n"}
        7.2. Acessando Links: Ao usar o aplicativo Striboss e optar por acessar
        links para sites de terceiros, você o faz por sua própria conta e risco.
        Sugerimos que você leia os termos e condições e políticas de privacidade
        de qualquer site de terceiros que você visitar. {"\n\n"}
        7.3. Desvinculação: Se você encontrar um link no aplicativo Striboss que
        você considera inadequado ou ofensivo, entre em contato conosco e
        faremos o nosso melhor para avaliar a situação e, se necessário,
        remove-lo. {"\n\n"}
        8. ALTERAÇÕES NOS TERMOS E CONDIÇÕES DE USO {"\n\n"}A Striboss
        reserva-se o direito de revisar e atualizar estes Termos de Uso a
        qualquer momento e por qualquer motivo. Quando fizermos alterações
        nestes termos, atualizaremos a data de "última atualização" no topo
        deste documento. {"\n\n"}
        8.1. Notificação de Alterações: Se houver alterações nestes Termos de
        Uso, nos esforçaremos para fornecer um aviso mais destacado, que pode
        incluir, por exemplo, um aviso por e-mail ou no aplicativo. É
        recomendável que você revise periodicamente estes Termos de Uso para se
        manter atualizado sobre nossas práticas. {"\n\n"}
        8.2. Aceitação das Alterações: O uso contínuo do aplicativo Striboss
        após a publicação das alterações a estes termos constituirá a sua
        aceitação dessas alterações. Se você não concordar com as alterações,
        você deve parar de usar o aplicativo.
        {"\n\n"}
        9. LEGISLAÇÃO APLICÁVEL E JURISDIÇÃO {"\n\n"}
        Estes Termos de Uso e qualquer disputa ou reivindicação decorrente de ou
        em conexão com eles serão regidos e interpretados de acordo com as leis
        da República Federativa do Brasil, sem consideração aos princípios de
        conflito de leis. {"\n\n"}
        Você concorda que os tribunais localizados dentro da República
        Federativa do Brasil terão jurisdição exclusiva para resolver qualquer
        disputa ou reivindicação que surja de ou em conexão com estes Termos de
        Uso ou seu uso do aplicativo Striboss, independentemente de onde você
        reside ou de onde você baixou ou acessou o aplicativo. {"\n\n"}
        Ao usar o aplicativo Striboss, você concorda em submeter-se à jurisdição
        desses tribunais e concorda que não irá contestar a jurisdição ou o
        local desses tribunais. {"\n\n"}
        10. CONTATO {"\n"}
        Caso tenha alguma dúvida sobre estes Termos de Uso ou sobre o uso do
        aplicativo Striboss, entre em contato conosco:
        {"\n\n"}
        Striboss.app
        {"\n\n"}
        striboss.app@gmail.com
        {"\n\n"}
        (61) 9 8109-0640
        {"\n\n"}
        Agradecemos por escolher o Striboss. Esperamos que você aproveite o uso
        do nosso aplicativo e desejamos muito sucesso em seus eventos equestres!
      </Text>
    </ScrollView>
  );
};

const createStyles = (theme: any) => {
  return StyleSheet.create({
    container: {
      flexGrow: 1,
      padding: 15,
      backgroundColor: theme.colors.background,
    },
    title: {
      fontSize: 24,
      fontWeight: "bold",
      marginBottom: 15,
      color: theme.colors.text,
    },
    date: {
      fontSize: 14,
      fontStyle: "italic",
      marginBottom: 15,
      color: theme.colors.text,
    },
    content: {
      fontSize: 16,
      color: theme.colors.text,
      lineHeight: 22,
      paddingVertical: 20,
    },
  });
};

export default TermsAndConditionsScreen;
