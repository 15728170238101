import React, { useState, useRef } from "react";

import {
  StyleSheet,
  Image,
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  Animated,
  ScrollView,
  Dimensions,
  Linking,
  FlatList,
} from "react-native";
import { Button, Overlay } from "react-native-elements";
import { useTheme } from "../../theme/ThemeContext";
import { imageSources } from "../../assets/images/imageSources";
import { useNavigate } from "react-router-dom";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { WebLoginModal } from "../../components/WebLoginModal/WebLoginModal";

type FeatureType = {
  num: string;
  title: string;
  description: string;
};

type AtheleteFeatureType = {
  num: string;
  description: string;
};

const athleteFeatures = [
  {
    num: "01",
    description: "Facilite seu processo de inscrição e participe rapidamente!",
  },
  {
    num: "02",
    description: "Consulte provas, horários e inscrições a qualquer momento.",
  },
  {
    num: "03",
    description:
      "Mantenha-se sempre informado e atualizado sobre suas competições.",
  },
  {
    num: "04",
    description: "Aproveite a praticidade no momento do pagamento.",
  },
  {
    num: "05",
    description: "Fique sabendo sua ordem de entrada com antecedência.",
  },
  {
    num: "06",
    description:
      "Tenha acesso imediato aos resultados e classificações das provas.",
  },
  {
    num: "07",
    description: "Acompanhe facilmente sua progressão e desempenho.",
  },
];

const features = [
  {
    num: "01",
    title: "eficiência e conveniência",
    description:
      "Nossa plataforma oferece uma experiência de inscrição online rápida e intuitiva, eliminando a necessidade de papelada e acelerando processos burocráticos essenciais. Tudo é realizado de forma prática, poupando tempo e esforço tanto para competidores quanto para organizadores de eventos e escolas de equitação.",
  },
  {
    num: "02",
    title: "gestão inteligente de provas",
    description:
      "Tenha o controle sobre cada aspecto do seu evento, desde o planejamento até o dia da competição. Gerencie de forma precisa as inscrições, horário, resultados e muito mais, tudo em tempo real, proporcionando uma visão completa e atualizada.",
  },
  {
    num: "03",
    title: "comunicação centralizada",
    description:
      "Mantenha todos os envolvidos informados e atualizados com facilidade usando o Striboss. Conecte organizadores, competidores e escolas de hipismo em um ambiente único. Com recursos de comunicação simples e ágeis, você pode enviar notificações importantes e compartilhar informações, potencializando assim o sucesso dos seus eventos equestres.",
  },
  {
    num: "04",
    title: "pagamento seguro",
    description:
      "Otimize o processo financeiro! A Striboss oferece um sistema de pagamento online seguro para inscrições e taxas relacionadas aos eventos. Desfrute de uma experiência tranquila e tenha total controle sobre os aspectos financeiros de seus campeonatos.",
  },
  {
    num: "05",
    title: "ordem de entrada simplificada",
    description:
      "Simplifique a ordem de entrada das provas com o Striboss agilizando o processo. Nossa plataforma gera automaticamente a sequência de entrada, com a flexibilidade de realizar edições personalizadas quando necessário. ",
  },
  {
    num: "06",
    title: "monitoramento em tempo real",
    description:
      "Mantenha-se atualizado sobre as últimas pontuações e resultados da prova por meio do nosso sistema de monitoramento em tempo real. Acompanhe de forma rápida e precisa o progresso das competições, obtendo informações atualizadas sobre as pontuações, classificações e desempenho dos competidores. ",
  },
  {
    num: "07",
    title: "histórico e resultados",
    description:
      "Proporcionamos aos organizadores de competições hípicas a funcionalidade de disponibilizar os resultados de forma organizada. Mantemos um registro completo das competições, permitindo que atletas e organizadores acessem os resultados anteriores e acompanhem o progresso ao longo do tempo. O Striboss oferece uma solução completa para o gerenciamento de resultados, garantindo transparência, praticidade e satisfação de todos os envolvidos.",
  },
];

const WebLandingPage = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [imageVisible, setImageVisible] = useState(false);
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const navigate = useNavigate();
  const theme = useTheme();
  const toggleLogin = () => setIsLoginVisible(!isLoginVisible);
  const homeRef = useRef(null);
  const organizerRef = useRef(null);
  const aboutUsRef = useRef(null);
  const downloadRef = useRef(null);
  const { width } = Dimensions.get("window");
  const isMobile = width <= 768;
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleTerms = () => {
    navigate("/terms");
  };

  const handlePrivacy = () => {
    navigate("/privacy");
  };

  const handleDirectory = () => {
    navigate("/diretorio");
  };

  const handleClickOrScroll = () => {
    if (imageVisible) {
      // This check ensures the animation only runs once
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: true,
      }).start(() => {
        setImageVisible(false);
      });
    }
  };

  const navigationLinks = (
    <View
      style={{
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-around",
        alignItems: "center",
        width: isMobile ? "100%" : "auto",
      }}
    >
      <TouchableOpacity
        onPress={() => scrollToRef(organizerRef)}
        style={{ marginVertical: isMobile ? 20 : 0 }}
      >
        <Text
          style={{ color: theme.colors.white, fontSize: 16, fontWeight: "700" }}
        >
          Soluções
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => scrollToRef(aboutUsRef)}
        style={{ marginVertical: isMobile ? 20 : 0 }}
      >
        <Text
          style={{ color: theme.colors.white, fontSize: 16, fontWeight: "700" }}
        >
          Quem somos
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={handleDirectory}
        style={{ marginVertical: isMobile ? 20 : 0 }}
      >
        <Text
          style={{ color: theme.colors.white, fontSize: 16, fontWeight: "700" }}
        >
          Hípicas
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setIsMenuVisible(false);
          toggleLogin();
        }}
        style={{ marginVertical: isMobile ? 20 : 0 }}
      >
        <Text
          style={{ color: theme.colors.white, fontSize: 16, fontWeight: "700" }}
        >
          Login organizador
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderItem = ({ item }: { item: FeatureType }) => {
    const { num, title, description } = item;

    return (
      <View
        style={{
          backgroundColor: theme.colors.secondary,
          padding: 25,
          borderRadius: 10,
          marginBottom: 20,
        }}
      >
        <Text
          style={{
            fontSize: 90,
            fontWeight: "600",

            color: theme.colors.secondary,
            textShadowColor: theme.colors.white,
            textShadowOffset: { width: 0, height: 0 },
            textShadowRadius: 3,
          }}
        >
          {num}
        </Text>
        <View style={{ marginBottom: 20 }}>
          <Text
            style={[
              theme.typography.h2,
              { color: theme.colors.white, fontWeight: "300" },
            ]}
          >
            {title}
          </Text>
        </View>
        <Text
          style={[
            theme.typography.small,
            { color: theme.colors.white, fontWeight: "300" },
          ]}
        >
          {description}
        </Text>
      </View>
    );
  };

  const renderAthleteListItem = (feat: AtheleteFeatureType) => {
    const { num, description } = feat;
    return (
      <View
        key={num}
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          alignItems: "center",
        }}
      >
        <View style={{ marginRight: 20 }}>
          <Text
            style={{
              fontSize: 48,
              fontWeight: "600",
              marginVertical: isMobile ? 20 : 5,
              color: theme.colors.secondary,
              textShadowColor: theme.colors.white,
              textShadowOffset: { width: 0, height: 0 },
              textShadowRadius: 3,
            }}
          >
            {num}
          </Text>
        </View>
        <Text
          style={{
            fontSize: 18,
            fontWeight: "200",
            marginVertical: 20,
            color: theme.colors.white,
          }}
        >
          {description}
        </Text>
      </View>
    );
  };

  const NavigationBar = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingVertical: 20,
          paddingHorizontal: isMobile ? 10 : 0,
        }}
      >
        <View style={!isMobile && { flex: 2, paddingLeft: 20 }}>
          <TouchableOpacity onPress={() => scrollToRef(homeRef)}>
            <Image
              source={imageSources.logoHorizontal}
              style={{ width: 196, height: 35 }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
        {isMobile ? (
          <TouchableOpacity onPress={() => setIsMenuVisible(!isMenuVisible)}>
            <Icon name="menu" size={30} color={theme.colors.white} />
          </TouchableOpacity>
        ) : (
          <View style={{ flex: 3 }}>{navigationLinks}</View>
        )}
      </View>
    );
  };

  const MobileNavigationMenu = () => {
    return (
      <Overlay
        isVisible={isMenuVisible}
        onBackdropPress={() => setIsMenuVisible(false)}
        overlayStyle={{
          padding: 15,
          minWidth: "50%",
          backgroundColor: theme.colors.secondary,
        }}
      >
        {navigationLinks}
      </Overlay>
    );
  };

  const returnDownloadLinks = (size: number, textColor: string) => (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-around",
        paddingRight: 20,
        alignItems: "center",
      }}
    >
      <TouchableOpacity
        onPress={() =>
          Linking.openURL(
            "https://play.google.com/store/apps/details?id=com.linny.santoro.Striboss"
          )
        }
        style={{
          margin: 10,
          marginTop: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={imageSources.googlePlayQrCode}
          style={{ width: size, height: size }}
          resizeMode="contain"
        />
        <View style={{ marginTop: 10 }}>
          <Text style={{ color: textColor }}>google play store</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() =>
          Linking.openURL("https://apps.apple.com/br/app/striboss/id6448731064")
        }
        style={{
          margin: 10,
          marginTop: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={imageSources.appleQrCode}
          style={{ width: size, height: size }}
          resizeMode="contain"
        />
        <View style={{ marginTop: 10 }}>
          <Text style={{ color: textColor }}>apple store</Text>
        </View>
      </TouchableOpacity>
    </View>
  );

  const Footer = () => {
    return (
      <ImageBackground
        source={imageSources.office_02}
        resizeMode="cover"
        style={[
          {
            flex: 1,
            justifyContent: "center",
            backgroundColor: theme.colors.grey2,
            paddingBottom: 20,
            marginTop: 20,
          },
          isMobile
            ? {
                flexDirection: "column",
                padding: 20,
              }
            : {
                flexDirection: "row",
                paddingHorizontal: 0.05 * windowWidth,
                alignItems: "center",
              },
        ]}
      >
        <View
          style={{
            backgroundColor: "rgba(27, 68, 134, 0.7)",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
        {isMobile ? (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ flex: 1 }}>
              <Image
                source={imageSources.logoHorizontalWhiteYellow}
                style={{ width: windowWidth / 3, height: 50 }}
                resizeMode="contain"
              />
            </View>
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                onPress={handleTerms}
                style={{ marginVertical: 8 }}
              >
                <Text
                  style={[
                    theme.typography.small,
                    {
                      color: theme.colors.white,
                      textDecorationLine: "underline",
                    },
                  ]}
                >
                  Termos e condições
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={handlePrivacy}
                style={{ marginVertical: 8 }}
              >
                <Text
                  style={[
                    theme.typography.small,
                    {
                      color: theme.colors.white,
                      textDecorationLine: "underline",
                    },
                  ]}
                >
                  Política de privacidade
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : null}
        <View
          style={[
            {
              marginTop: 20,
              flexDirection: "row",
              justifyContent: "space-between",
            },
            isMobile
              ? { justifyContent: "space-around" }
              : {
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                },
          ]}
        >
          {!isMobile ? (
            <>
              <View style={{ flex: 1 }}>
                <Image
                  source={imageSources.logoHorizontalWhiteYellow}
                  style={{ width: windowWidth / 5, height: 50 }}
                  resizeMode="contain"
                />
              </View>
              <View style={{ flex: 1 }}>
                <TouchableOpacity
                  onPress={handleTerms}
                  style={{ marginVertical: 8 }}
                >
                  <Text
                    style={[
                      theme.typography.small,
                      {
                        color: theme.colors.white,
                        textDecorationLine: "underline",
                      },
                    ]}
                  >
                    Termos e condições
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={handlePrivacy}
                  style={{ marginVertical: 8 }}
                >
                  <Text
                    style={[
                      theme.typography.small,
                      {
                        color: theme.colors.white,
                        textDecorationLine: "underline",
                      },
                    ]}
                  >
                    Política de privacidade
                  </Text>
                </TouchableOpacity>
              </View>
            </>
          ) : null}
          <View style={{ flex: 1, flexShrink: 1, paddingRight: 10 }}>
            <View style={{ marginVertical: 8 }}>
              <Text
                style={{
                  color: theme.colors.white,
                  fontWeight: "700",
                  fontSize: 14,
                }}
              >
                Onde estamos
              </Text>
            </View>
            <View style={{ marginVertical: 4, paddingRight: 5 }}>
              <Text
                style={[theme.typography.small, { color: theme.colors.white }]}
              >
                Edifício Ion Sala 55 SS1 - Parte 97
              </Text>
            </View>
            <View style={{ marginVertical: 4 }}>
              <Text
                style={[theme.typography.small, { color: theme.colors.white }]}
              >
                Asa Norte, Brasília, DF
              </Text>
            </View>
            <View style={{ marginVertical: 4 }}>
              <Text
                style={[theme.typography.small, { color: theme.colors.white }]}
              >
                70.830-018
              </Text>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View style={{ marginVertical: 8 }}>
              <Text
                style={{
                  color: theme.colors.white,
                  fontWeight: "700",
                  fontSize: 14,
                }}
              >
                Contato
              </Text>
            </View>
            <View style={{ marginVertical: 4 }}>
              <Text
                style={[theme.typography.small, { color: theme.colors.white }]}
              >
                61 9 8109.0640
              </Text>
            </View>
            <View style={{ marginVertical: 4 }}>
              <Text
                style={[theme.typography.small, { color: theme.colors.white }]}
              >
                striboss.app@gmail.com
              </Text>
            </View>
            <View style={{ marginVertical: 4 }}>
              <Text
                style={[theme.typography.small, { color: theme.colors.white }]}
              >
                @striboss.app
              </Text>
            </View>
          </View>
        </View>
      </ImageBackground>
    );
  };

  const renderGreeting = () => (
    <Animated.View style={{ opacity: fadeAnim, flex: 1 }}>
      <TouchableOpacity style={{ flex: 1 }} onPress={handleClickOrScroll}>
        <ImageBackground
          source={imageSources.horseVideo01}
          resizeMode="cover"
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: windowHeight + 1,
            backgroundColor: theme.colors.grey2,
          }}
        >
          <View
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          />
          <Image
            source={imageSources.logoHorizontalWhiteYellow}
            style={{ width: 285, height: 77 }}
            resizeMode="contain"
          />
          <View
            style={{
              maxWidth: 500,
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Text
              style={[
                theme.typography.title,
                { color: theme.colors.white, textAlign: "center" },
              ]}
            >
              O futuro do hipismo começa aqui
            </Text>
          </View>
          <Button
            title="baixe o app"
            type="clear"
            onPress={() => {
              scrollToRef(downloadRef);
              handleClickOrScroll();
            }}
            titleStyle={{ color: theme.colors.accent, fontWeight: "600" }}
            buttonStyle={{
              backgroundColor: theme.colors.background,
              borderRadius: 15,
              paddingHorizontal: 15,
              paddingVertical: 4,
              marginTop: 15,
            }}
          />
        </ImageBackground>
      </TouchableOpacity>
    </Animated.View>
  );

  const renderHero = () => (
    <ImageBackground
      source={imageSources.horseVideo01}
      resizeMode="cover"
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: 700,
      }}
    >
      <NavigationBar />
      {isMobile && <MobileNavigationMenu />}
      <View
        style={{
          height: 500,
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={homeRef}
      >
        <View
          style={{
            maxWidth: 500,
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Text
            style={[
              theme.typography.title,
              { color: theme.colors.white, textAlign: "center" },
            ]}
          >
            O futuro do hipismo começa aqui
          </Text>
        </View>
        <Button
          title="baixe o app"
          type="clear"
          onPress={() => {
            scrollToRef(downloadRef);
            handleClickOrScroll();
          }}
          titleStyle={{ color: theme.colors.accent, fontWeight: "600" }}
          buttonStyle={{
            backgroundColor: theme.colors.secondary,
            borderRadius: 15,
            paddingHorizontal: 15,
            paddingVertical: 4,
            marginVertical: 15,
          }}
        />
        {/* <TouchableOpacity
          onPress={() =>
            Linking.openURL("https://www.instagram.com/striboss.app/?hl=en")
          }
        >
          <Icon name="instagram" size={30} color={theme.colors.primary} />
        </TouchableOpacity> */}
      </View>
    </ImageBackground>
  );

  const renderIntro = () => (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        minHeight: !isMobile ? 600 : undefined,
        flexDirection: isMobile ? "column" : "row",
        padding: !isMobile ? 0.05 * windowWidth : undefined,
      }}
    >
      <View style={{ flex: 1 }}>
        <Image
          source={imageSources.iphone}
          style={
            isMobile
              ? { width: 300, height: 500 }
              : { width: windowWidth / 2 - 50, height: 700 }
          }
          resizeMode="contain"
        />
      </View>
      <View style={{ flex: 1 }}>
        <View
          style={
            isMobile
              ? { marginTop: 50, marginHorizontal: 20 }
              : { maxWidth: 200, marginBottom: 20 }
          }
        >
          <Text
            style={{
              fontSize: 40,
              fontWeight: "300",
              marginTop: 20,
              color: theme.colors.accent,
            }}
          >
            A striboss
          </Text>
        </View>
        <View style={isMobile ? { marginHorizontal: 20 } : { maxWidth: 500 }}>
          <Text
            style={{
              fontSize: 14,
              color: theme.colors.primary,
            }}
          >
            Somos uma plataforma SaaS (Software as a Service) desenvolvida
            exclusivamente para organizadores de competições hípicas. Com nossa
            solução, disponibilizamos recursos-chave que agilizam e aprimoram a
            organização do seu campeonato.
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: theme.colors.primary,
              marginTop: 20,
            }}
          >
            Melhore a experiência do participante e otimize o processo de
            organização, mantendo o controle total sobre o seu evento com o
            Striboss.
          </Text>
        </View>
      </View>
    </View>
  );

  const renderOrganizerSolutions = () => (
    <View ref={organizerRef}>
      {isMobile ? (
        <View
          style={[
            isMobile ? { marginTop: 50, marginHorizontal: 20 } : null,
            { alignItems: "center" },
          ]}
        >
          <Text
            style={{
              fontSize: 40,
              fontWeight: "300",
              marginTop: 20,
              color: theme.colors.secondary,
            }}
          >
            soluções para
          </Text>
          <Text
            style={{
              fontSize: 40,
              fontWeight: "300",

              color: theme.colors.accent,
            }}
          >
            hípicas
          </Text>
          <Button
            title="baixe o app"
            type="clear"
            onPress={() => {
              scrollToRef(downloadRef);
              handleClickOrScroll();
            }}
            titleStyle={{
              color: theme.colors.accent,
              fontWeight: "600",
              fontSize: 12,
            }}
            buttonStyle={{
              backgroundColor: theme.colors.secondary,
              borderRadius: 15,
              paddingHorizontal: 15,
              paddingVertical: 4,
              marginTop: 15,
              marginBottom: 20,
            }}
          />
        </View>
      ) : null}
      <ImageBackground
        source={imageSources.horsePic}
        resizeMode="cover"
        style={{
          flex: 1,
          backgroundColor: theme.colors.grey2,
          padding: isMobile ? 20 : 0,
          marginTop: !isMobile ? 100 : 0,
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(232, 161, 35, 0.8)",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {isMobile ? (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View>
                <Icon
                  name="dots-vertical"
                  size={70}
                  color={theme.colors.tertiary}
                />
              </View>

              <View
                style={{
                  marginBottom: 75,
                  maxHeight: windowHeight - 150,
                  maxWidth: windowWidth - 120,
                }}
              >
                <FlatList
                  data={features}
                  keyExtractor={(item) => item?.num}
                  renderItem={renderItem}
                />
              </View>
            </View>
          ) : (
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                <View
                  style={[
                    {
                      backgroundColor: theme.colors.white,
                      paddingVertical: 100,
                      padding: 0.05 * windowWidth,
                    },
                  ]}
                >
                  <View style={{ alignItems: "flex-start" }}>
                    <Text
                      style={{
                        fontSize: 40,
                        fontWeight: "300",
                        marginTop: 20,
                        color: theme.colors.secondary,
                      }}
                    >
                      soluções para
                    </Text>

                    <Text
                      style={{
                        fontSize: 40,
                        fontWeight: "300",
                        color: theme.colors.accent,
                      }}
                    >
                      hípicas
                    </Text>
                    <Button
                      title="baixe o app"
                      type="clear"
                      onPress={() => {
                        scrollToRef(downloadRef);
                        handleClickOrScroll();
                      }}
                      titleStyle={{
                        color: theme.colors.accent,
                        fontWeight: "600",
                        fontSize: 12,
                      }}
                      buttonStyle={{
                        backgroundColor: theme.colors.secondary,
                        borderRadius: 15,
                        paddingHorizontal: 15,
                        paddingVertical: 4,
                        marginTop: 15,
                        marginBottom: 20,
                      }}
                    />
                  </View>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    marginBottom: 75,
                    maxHeight: windowHeight - 250,
                    maxWidth: windowWidth - windowWidth / 3,
                    flex: 6,
                    top: -80,
                    left: -80,
                  }}
                >
                  <FlatList
                    data={features}
                    keyExtractor={(item) => item?.num}
                    renderItem={renderItem}
                  />
                </View>
                <View style={{ flex: 1, left: -80 }}>
                  <Icon
                    name="dots-vertical"
                    size={70}
                    color={theme.colors.tertiary}
                  />
                </View>
              </View>
            </View>
          )}
        </View>
      </ImageBackground>
    </View>
  );

  const renderAthleteSolutions = () => (
    <ImageBackground
      source={imageSources.horseVideo02}
      resizeMode="cover"
      style={[
        {
          justifyContent: "center",
          alignItems: "center",
        },
        isMobile
          ? {
              flexDirection: "column",
              paddingVertical: 0.05 * windowWidth,
            }
          : { flex: 1, flexDirection: "row", padding: 0.05 * windowWidth },
      ]}
    >
      <View
        style={{
          backgroundColor: "rgba(27, 68, 134, 0.7)",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />
      {isMobile ? (
        <View
          style={[
            {
              alignItems: "center",
              flex: 2,
            },
          ]}
        >
          <Image
            source={imageSources.twoIphones}
            style={[{ width: 320, height: 380, top: -80 }]}
            resizeMode="contain"
          />
        </View>
      ) : null}
      <View
        style={[
          { alignItems: "center", justifyContent: "center" },
          isMobile
            ? { marginHorizontal: 20 }
            : { maxWidth: 200, alignItems: "flex-start", flex: 1 },
        ]}
      >
        <Text
          style={{
            fontSize: 40,
            fontWeight: "300",
            color: theme.colors.white,
          }}
        >
          soluções para
        </Text>
        <Text
          style={{
            fontSize: 40,
            fontWeight: "300",
            color: theme.colors.accent,
          }}
        >
          atletas
        </Text>
      </View>
      {!isMobile ? (
        <View
          style={{
            backgroundColor: theme.colors.accent,
            width: 3,
            height: "100%",
          }}
        />
      ) : null}
      <View style={[isMobile ? null : { flex: 1, paddingLeft: 20 }]}>
        {athleteFeatures.map((feat) => renderAthleteListItem(feat))}
      </View>
      {!isMobile ? (
        <View
          style={[
            {
              alignItems: "flex-end",
              flex: 1,
            },
          ]}
        >
          <Image
            source={imageSources.twoIphones}
            style={[
              isMobile
                ? { width: 320, height: 380, top: -80 }
                : { width: windowWidth / 3, height: 500 },
            ]}
            resizeMode="contain"
          />
        </View>
      ) : null}
    </ImageBackground>
  );

  const renderDownloadSection = () => (
    <View
      style={{
        backgroundColor: theme.colors.accent,
        padding: 0.05 * windowWidth,
        flexDirection: isMobile ? "column" : "row",
      }}
      ref={downloadRef}
    >
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-end",
          paddingTop: 20,
        }}
      >
        <Image
          source={imageSources.handHoldingSmartphone}
          style={{ width: windowWidth / 2, height: isMobile ? 320 : 400 }}
          resizeMode="contain"
        />
      </View>
      <View
        style={{
          flex: 1,
          // alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontSize: 32,
            color: theme.colors.secondary,
            fontWeight: "300",
            textAlign: "center",
          }}
        >
          baixe o app!
        </Text>
        {returnDownloadLinks(150, theme.colors.secondary)}
      </View>
    </View>
  );

  const renderAboutUsSection = () => (
    <View ref={aboutUsRef}>
      <View
        style={[
          isMobile
            ? { flexDirection: "column", marginVertical: 30 }
            : {
                justifyContent: "center",
                alignItems: "flex-end",
                flexDirection: "row",
                marginVertical: 30,
                padding: 0.05 * windowWidth,
              },
        ]}
      >
        <View
          style={[
            isMobile
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  top: -60,
                }
              : { flex: 1 },
          ]}
        >
          <Image
            source={imageSources.horseLove}
            style={[
              isMobile
                ? { width: windowWidth - 60, height: 250 }
                : { width: windowWidth / 2 - 100, height: 350 },
              { borderRadius: 30 },
            ]}
          />
        </View>
        <View
          style={[
            isMobile
              ? {
                  paddingLeft: 50,
                  alignItems: "flex-end",
                  paddingRight: 20,
                }
              : {
                  flex: 1,
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  paddingLeft: 20,
                  maxWidth: windowWidth / 3,
                },
          ]}
        >
          <Text
            style={{
              fontSize: 40,
              fontWeight: "300",
              color: theme.colors.secondary,
            }}
          >
            prazer, somos a
          </Text>
          <Text
            style={{
              fontSize: 40,
              fontWeight: "300",
              color: theme.colors.accent,
            }}
          >
            striboss
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: theme.colors.primary,
              textAlign: "right",
              marginTop: 10,
            }}
          >
            Somos uma equipe unida pela paixão pelos animais, pela emoção das
            competições e pela busca constante por inovação e tecnologia. Temos
            o objetivo de promover e fortalecer o esporte da equitação,
            tornando-o mais reconhecido para que mais pessoas possam
            experimentar a beleza e a intensidade dessa prática.
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: theme.colors.primary,
              textAlign: "right",
              marginTop: 10,
            }}
          >
            Nosso aplicativo é um reflexo dessa dedicação. Desenvolvido
            exclusivamente para competições hípicas, nasceu do desejo de
            simplificar e aprimorar a organização desses eventos. Com ele,
            oferecemos soluções tecnológicas e serviços de gestão para o setor
            equestre, abrangendo desde a organização de competições até o
            registro de resultados. Tudo é pensado para facilitar cada etapa do
            processo, permitindo que organizadores e participantes possam focar
            no que realmente importa: a paixão pelos cavalos e pelo esporte.
          </Text>
        </View>
      </View>
      <View
        style={
          isMobile
            ? { flexDirection: "column" }
            : {
                justifyContent: "center",
                alignItems: "flex-start",
                marginVertical: 30,
                paddingHorizontal: 0.05 * windowWidth,
                flexDirection: "row",
              }
        }
      >
        <View
          style={[
            isMobile
              ? {
                  paddingLeft: 50,
                  alignItems: "flex-end",
                  paddingRight: 20,
                }
              : {
                  flex: 1,
                  alignItems: "flex-start",
                  paddingRight: 20,
                  maxWidth: windowWidth / 3,
                },
          ]}
        >
          <Text
            style={{
              fontSize: 14,
              color: theme.colors.primary,
              textAlign: isMobile ? "right" : "left",
              marginTop: 10,
            }}
          >
            Acreditamos que o hipismo é mais do que uma atividade física, é uma
            forma de conexão com a natureza, com os animais e com nós mesmos. É
            um universo de superação, aprendizado e respeito. É um espaço onde
            se constroem laços e se inspiram emoções.
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: theme.colors.primary,
              textAlign: isMobile ? "right" : "left",
              marginTop: 10,
            }}
          >
            Estamos comprometidos em construir uma comunidade equestre forte e
            unida. Seja parte dessa revolução.
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: theme.colors.primary,
              textAlign: isMobile ? "right" : "left",
              marginTop: 10,
            }}
          >
            O futuro do hipismo, com a tecnologia Striboss
          </Text>
        </View>
        {isMobile ? null : (
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <Image
              source={imageSources.office_01}
              style={{
                width: windowWidth / 2 - 100,
                height: 330,
                borderRadius: 30,
              }}
              // resizeMode="contain"
            />
          </View>
        )}
      </View>
    </View>
  );

  return (
    <ScrollView
      onScroll={handleClickOrScroll}
      onScrollBeginDrag={handleClickOrScroll}
      scrollEventThrottle={16}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      {imageVisible ? (
        renderGreeting()
      ) : (
        <View>
          <View>
            {renderHero()}
            {renderIntro()}
            {renderOrganizerSolutions()}
            {renderAthleteSolutions()}
            {renderDownloadSection()}
            {renderAboutUsSection()}
          </View>
          <Footer />
        </View>
      )}

      <WebLoginModal
        isLoginVisible={isLoginVisible}
        toggleLogin={toggleLogin}
      />
    </ScrollView>
  );
};

export default WebLandingPage;
