// src/hooks/useDressage.ts
import { useEffect, useState } from "react";
import { useDressageReprisesContext } from "../context/DressageReprisesContext";
import { Reprise } from "../types";
import { getDocs, collection } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

export const useDressage = () => {
  const { reprises, setReprises } = useDressageReprisesContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (reprises === null || reprises.length === 0) {
      const fetchReprises = async () => {
        try {
          const db = getFirestore();
          const entitiesSnapshot = await getDocs(
            collection(db, "dressageReprises")
          );
          const fetchedReprises = entitiesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Reprise[];

          setReprises(fetchedReprises);
        } catch (error) {
          console.error("Nenhuma reprise encontrada", error);
        }
      };
      setIsLoading(true);
      setError(null);

      try {
        fetchReprises();
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Ocorreu um erro desconhecido");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [reprises, setReprises]);

  return { reprises, setReprises, isLoading, error };
};
