import React, { createContext, useContext } from "react";
import { colors, typography, buttonStyles, cardStyles } from "./index";
import { Theme } from "./index";

export type ThemeContextType = Theme;

export const ThemeContext = createContext<ThemeContextType>({
  colors,
  typography,
  buttonStyles,
  cardStyles,
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ThemeContext.Provider
      value={{ colors, typography, buttonStyles, cardStyles }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  return useContext(ThemeContext);
};
