import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Alert,
  ActivityIndicator,
} from "react-native";
import { useTheme } from "../../theme/ThemeContext";
import {
  FinalShowjumpingScores,
  Enrollment,
  ModalityType,
  ModalityKeys,
  Horse,
  FaultsArray,
} from "../../types";
import { Modal, Select } from "../../CorcelDesign";
import { FormControl, Input, WarningOutlineIcon } from "native-base";
import useUserHorses from "../../hooks/useUserHorses";
import EmptyState from "../EmptyState/EmptyState";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { customSort } from "../../utils";

interface ShowjumpingScoringViewWebProps {
  onClose: () => void;
  isVisible: boolean;
  onSave: ({
    finalShowjumpingScores,
    isEliminated,
    isForfait,
  }: {
    finalShowjumpingScores: FinalShowjumpingScores;
    isEliminated: boolean;
    isForfait: boolean;
  }) => void;
  handleUpdateHorse: (enrollmentId: string, horseId: string) => Promise<void>;
  enrollment: Enrollment | null;
  allottedTime?: number;
  idealTime?: number;
  numJumps?: number;
  jumpsArray?: string[];
  penaltyPointsPerSecond?: number | null;
  isUpdating: boolean;
  modalityType: ModalityType;
}

export const ShowjumpingScoringViewWeb: React.FC<
  ShowjumpingScoringViewWebProps
> = ({
  isVisible,
  onClose,
  enrollment,
  numJumps,
  allottedTime,
  idealTime,
  onSave,
  isUpdating,
  modalityType,
  handleUpdateHorse,
  penaltyPointsPerSecond,
  jumpsArray,
}) => {
  const theme = useTheme();
  const [time, setTime] = useState<number | null | string>();
  const [faults, setFaults] = useState<number | null | string>();
  const [approximation, setApproximation] = useState<number | null>();
  const [penalties, setPenalties] = useState<number | null>();
  const [total, setTotal] = useState<number | null>();
  const [isEliminated, setIsEliminated] = useState(false);
  const [isForfait, setIsForfait] = useState(false);
  const [isHorsConcours, setIsHorsConcours] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [selectedHorse, setSelectedHorse] = useState<string | undefined>();

  if (!numJumps || !allottedTime) {
    return (
      <View>
        <EmptyState
          message="Primeiro calcule o tempo e adicione a quantidade de obstáculos na tela anterior"
          iconName="alert-circle"
        />
      </View>
    );
  }

  const isModTimeTrial = modalityType === ModalityKeys.TIME_TRIAL;

  const {
    fullName,
    entity,
    horse,
    horseId,
    id: enrollmentId,
  } = enrollment ?? {};

  const { acronym } = entity ?? {};
  const { name: horseName } = horse ?? {};

  const { userHorses, loading, error } = useUserHorses();
  const horses = userHorses || [];

  const enrollmentIsMissingHorse = Boolean(!horseId);

  const isSchoolHorse = (horseId: string) => {
    return userHorses?.some((userHorse: Horse) => userHorse.id === horseId);
  };

  const handleSpecialCaseChange = (key: "isEliminated" | "isForfait") => {
    if (key === "isEliminated") {
      setIsEliminated(!isEliminated);
    } else if (key === "isForfait") {
      setIsForfait(!isForfait);
    }
  };

  const [faultsArray, setFaultsArray] = useState<FaultsArray>(
    Object.fromEntries([["Erro", 0]])
  );

  useEffect(() => {
    const jumps = jumpsArray
      ? Array.from(jumpsArray, (x) => [x, 0])
      : Array.from({ length: numJumps ?? 0 }, (_, i) => [`${i + 1}`, 0]);
    if (jumpsArray) {
      setFaultsArray(Object.fromEntries([...jumps, ["Erro", 0]]));
    }
  }, [jumpsArray]);

  const handleCheckChange = (key: string) => {
    setFaultsArray((prevState) => {
      const currentValue = prevState[key];
      const newValue = (currentValue + 1) % 3; // cycles through 0, 1, 2
      return {
        ...prevState,
        [key]: newValue,
      };
    });
  };
  useEffect(() => {
    const {
      isEliminated: initIsEliminated,
      isForfait: initIsForfait,
      isHorsConcours: initIsHorsConcours,
      showjumpingScores,
    } = enrollment ?? {};
    const {
      totalFaults: initTotalFaults,
      time: initTime,
      penalties: initPenalties,
      faults: initFaults,
      approximation: initApproximation,
      faultsArray: initFaultsArray,
    } = showjumpingScores ?? {};
    setIsEliminated(initIsEliminated ?? false);
    setIsForfait(initIsForfait ?? false);
    setIsHorsConcours(initIsHorsConcours ?? false);
    if (initTime) {
      setTime(initTime);
    }
    if (initFaults) {
      setFaults(initFaults);
    }
    if (initPenalties) {
      setPenalties(initPenalties);
    }
    if (initApproximation) {
      setApproximation(initApproximation);
    }
    if (initTotalFaults) {
      setTotal(initTotalFaults);
    }
    if (initFaultsArray) {
      setFaultsArray(initFaultsArray);
    }
    if (horseId && isSchoolHorse(horseId)) {
      setSelectedHorse(horseId);
    }
  }, [enrollment]);

  useEffect(() => {
    const calculatedFaults = Object.values(faultsArray).reduce(
      (acc, faultType) => {
        if (faultType === 1 || faultType === 2) return acc + 4;
        return acc;
      },
      0
    );
    setFaults(calculatedFaults);

    // Additional type checks before calculation

    const numericTime =
      typeof time === "number" ? time : Number(time?.replace(",", "."));

    const numericFaults = typeof faults === "number" ? faults : Number(faults);

    if (isModTimeTrial && !isNaN(numericTime) && idealTime !== undefined) {
      const calculatedApproximation = Math.abs(numericTime - idealTime);
      setApproximation(Number(calculatedApproximation.toFixed(2)));
    }
    let calculatedPenalties = 0;
    if (
      isModTimeTrial &&
      !isNaN(numericTime) &&
      allottedTime !== undefined &&
      idealTime !== undefined
    ) {
      const lowerBound = idealTime - Math.abs(allottedTime - idealTime);
      const upperBound = allottedTime;

      if (numericTime < lowerBound) {
        calculatedPenalties =
          Math.ceil(Math.abs(numericTime - lowerBound)) *
          (penaltyPointsPerSecond ?? 1);
      }

      if (numericTime > upperBound) {
        calculatedPenalties =
          Math.ceil(Math.abs(numericTime - upperBound)) *
          (penaltyPointsPerSecond ?? 1);
      }
    } else if (!isNaN(numericTime) && allottedTime !== undefined) {
      if (numericTime > allottedTime) {
        calculatedPenalties =
          Math.ceil(Math.abs(numericTime - allottedTime)) *
          (penaltyPointsPerSecond ?? 1);
      }
    }

    setPenalties(calculatedPenalties);

    if (!isNaN(numericFaults)) {
      setTotal(numericFaults + calculatedPenalties);
    }
  }, [faultsArray, time, allottedTime, idealTime, faults, penalties]);

  const saveHorseSelection = async (newHorseId: string) => {
    try {
      if (newHorseId && enrollmentId) {
        await handleUpdateHorse(enrollmentId, newHorseId);
        setSelectedHorse(newHorseId);
      }
    } catch (error) {
      console.error("Error updating enrollment horse:", error);
    }
  };

  const handleSave = () => {
    if (!time && !isEliminated && !isForfait) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }

    const numericTime =
      typeof time === "number" ? time : Number(time?.replace(",", "."));
    const numericFaults = typeof faults === "number" ? faults : Number(faults);
    const finalShowjumpingScores: FinalShowjumpingScores = {
      approximation: isModTimeTrial
        ? approximation ?? null
        : numericTime ?? null,
      faults: !isNaN(numericFaults) ? numericFaults : null,
      faultsArray,
      penalties: penalties ?? null,
      time: !isNaN(numericTime) ? numericTime : null,
      totalFaults: total ?? null,
    };
    onSave({ finalShowjumpingScores, isEliminated, isForfait });
  };

  const onCloseWithWarning = () => {
    Alert.alert(
      "Salvar resultado?",
      "",
      [
        { text: "Salvar", onPress: handleSave, style: "cancel" },
        { text: "Voltar sem salvar", onPress: onClose },
      ],
      { cancelable: false }
    );
  };

  const getColorForFault = (faultType: number) => {
    switch (faultType) {
      case 0:
        return "#ccc";
      case 1:
        return theme.colors.accent;
      case 2:
        return theme.colors.error;
      default:
        return "#ccc";
    }
  };

  const getBackgroundColorForFault = (faultType: number) => {
    switch (faultType) {
      case 0:
        return "#fff";
      case 1:
        return theme.colors.accent;
      case 2:
        return theme.colors.error;
      default:
        return "#fff";
    }
  };
  const getTextForFault = (faultType: number, key: string) => {
    if (!/^\d/.test(key)) {
      return key;
    }
    switch (faultType) {
      case 0:
        return key;
      case 1:
        return "X";
      case 2:
        return "D";
      default:
        return key;
    }
  };

  const getTextColorForFault = (faultType: number) => {
    return faultType > 0 ? theme.colors.white : theme.colors.grey2;
  };

  const renderSelectButton = (key: string) => (
    <TouchableOpacity
      key={key}
      style={{
        borderWidth: 1,
        borderColor: getColorForFault(faultsArray[key]),
        backgroundColor: getBackgroundColorForFault(faultsArray[key]),
        borderRadius: 10,
        paddingHorizontal: 12,
        paddingVertical: 8,
        margin: 4,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.05,
        shadowRadius: 3,
      }}
      onPress={() => handleCheckChange(key)}
    >
      <Text
        style={{
          color: getTextColorForFault(faultsArray[key]),
        }}
      >
        {getTextForFault(faultsArray[key], key)}
      </Text>
    </TouchableOpacity>
  );

  const renderSpecialButton = (
    key: "isEliminated" | "isForfait",
    title: string
  ) => {
    const isSelected = key === "isEliminated" ? isEliminated : isForfait;

    return (
      <View style={{ flex: 1 }}>
        <TouchableOpacity
          key={key}
          style={{
            borderWidth: 1,
            borderColor: isSelected ? theme.colors.error : "#ccc",
            backgroundColor: isSelected ? theme.colors.error : "#fff",
            borderRadius: 10,
            paddingHorizontal: 12,
            paddingVertical: 8,
            margin: 4,
            shadowColor: "#000",
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.05,
            shadowRadius: 3,
          }}
          onPress={() => handleSpecialCaseChange(key)}
        >
          <Text
            style={{
              textAlign: "center",
              color: isSelected ? theme.colors.white : theme.colors.grey2,
            }}
          >
            {title}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const renderTextRow = (label: string, value?: number | string | null) => (
    <View
      style={{
        flexDirection: "column",
        borderRadius: 10,
        backgroundColor: theme.colors.background,
        padding: 15,
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: 5,
      }}
    >
      <Text style={{ ...theme.typography.h2 }}>{value ?? "--"}</Text>
      <Text style={[theme.typography.small, { flexShrink: 1 }]}>{label}</Text>
    </View>
  );

  const modalTitle = `${acronym ? acronym + " | " : ""}${fullName || ""}`;

  return (
    <View>
      <View style={{ marginBottom: 20 }}>
        <Text style={[theme.typography.h2, { textAlign: "center" }]}>
          {modalTitle}
        </Text>
      </View>
      {enrollmentIsMissingHorse ? (
        <>
          <Text
            style={{
              color: theme.colors.grey2,
              fontWeight: "600",
              marginBottom: 5,
            }}
          >
            Montaria não definida
          </Text>
          <Select
            options={
              userHorses
                ? userHorses.map((horse: Horse, i: number) => ({
                    label: horse.name,
                    value: horse.id,
                  }))
                : []
            }
            placeholder="Cavalo"
            value={selectedHorse}
            onValueChange={saveHorseSelection}
          />
        </>
      ) : null}

      {horseId && isSchoolHorse(horseId) ? (
        <>
          <Text
            style={{
              color: theme.colors.grey2,
              fontWeight: "600",
              marginBottom: 5,
            }}
          >
            Montaria
          </Text>
          <Select
            options={horses.map((horse: Horse, i: number) => ({
              label: horse.name,
              value: horse.id,
            }))}
            placeholder="Cavalo"
            value={selectedHorse}
            onValueChange={saveHorseSelection}
          />
        </>
      ) : null}
      {horseName && horseId && !isSchoolHorse(horseId) ? (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Icon name="horse" size={25} color={theme.colors.tertiary} />
          <Text style={[{ marginLeft: 10 }, theme.typography.h3]}>
            {horseName}
          </Text>
        </View>
      ) : null}
      <FormControl isRequired mb={4} isInvalid={timeError}>
        <FormControl.Label>Tempo</FormControl.Label>
        <Input
          variant="outline"
          size="md"
          placeholder="Tempo"
          value={time?.toString() ?? undefined}
          onChangeText={(value) => {
            const cleanValue = value.replace(/[^0-9.,]/g, "");
            if (
              !isNaN(Number(value.replace(",", "."))) ||
              value === "." ||
              value === "," ||
              value === ""
            ) {
              setTime(cleanValue);
            }
          }}
          keyboardType="number-pad"
          inputMode="decimal"
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          Adicione o tempo
        </FormControl.ErrorMessage>
      </FormControl>

      <Text>Faltas na pista: </Text>
      <View
        style={{
          paddingVertical: 5,
          flexDirection: "row",
          flexShrink: 1,
          flexWrap: "wrap",
        }}
      >
        {Object.keys(faultsArray)
          .sort(customSort)
          .map((key) => renderSelectButton(key))}
      </View>
      <View style={{ flexDirection: "row" }}>
        {renderSpecialButton("isEliminated", "Eliminado")}
        {renderSpecialButton("isForfait", "Forfeit")}
      </View>

      <View
        style={{
          paddingVertical: 5,
          flexDirection: "row",
          flexShrink: 1,
          flexWrap: "wrap",
          marginTop: 20,
          justifyContent: "space-around",
        }}
      >
        {isModTimeTrial ? renderTextRow("Aprox.", approximation) : null}
        {renderTextRow("Penal.", penalties)}
        {renderTextRow("Total", total)}
      </View>

      <View style={{ marginTop: 20 }}>
        <TouchableOpacity
          onPress={handleSave}
          style={[
            {
              backgroundColor: theme.colors.secondary,
              paddingVertical: 12,
              paddingHorizontal: 20,
              borderRadius: 4,
            },
          ]}
        >
          {isUpdating ? (
            <ActivityIndicator color="white" />
          ) : (
            <Text style={{ color: "white", fontSize: 16, textAlign: "center" }}>
              Salvar
            </Text>
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};
