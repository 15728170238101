import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, FlatList } from "react-native";
import { Modal } from "../../CorcelDesign";
import { Enrollment, Entity } from "../../types";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { useTheme } from "../../theme/ThemeContext";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";

interface DraggableEntryOrderModalProps {
  isVisible: boolean;
  onClose: () => void;
  updateEntryOrder: (
    enrollmentId: string,
    newPosition: number,
    newData: Enrollment[]
  ) => Promise<void>;
  enrollments: Enrollment[];
  loading: boolean;
}

export const DraggableEntryOrderModal: React.FC<
  DraggableEntryOrderModalProps
> = ({ isVisible, onClose, updateEntryOrder, enrollments, loading }) => {
  const [data, setData] = useState(enrollments);
  const theme = useTheme();

  useEffect(() => {
    setData(enrollments);
  }, [enrollments]);

  const moveItem = (fromIndex: number, toIndex: number) => {
    const newData = [...data];
    const [movedItem] = newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, movedItem);
    setData(newData);
    updateEntryOrder(movedItem.id, toIndex, newData);
  };

  const itemCard = (
    entryOrder: string | null,
    fullName: string | null,
    horseName: string,
    entity?: Entity
  ) => (
    <View
      style={{
        justifyContent: "space-between",
        padding: 15,
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <View style={{ paddingRight: 10 }}>
        <Text
          style={{
            fontSize: 20,
            fontWeight: "600",
            color: theme.colors.primary,
          }}
        >
          {entryOrder}
        </Text>
      </View>
      <View style={{ flex: 1, alignItems: "flex-start" }}>
        <Text>
          {fullName} {entity && `(${entity?.acronym})`}
        </Text>
        <Text style={{ fontWeight: "500" }}>{horseName}</Text>
      </View>
    </View>
  );

  const renderItem = ({ item, index }: { item: Enrollment; index: number }) => {
    const { entity } = item.profile ?? {};
    const { name: horseName } = item.horse ?? {};
    const { entryOrder, fullName } = item ?? {};

    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
        }}
      >
        <View>
          <TouchableOpacity
            onPress={() => moveItem(index, index - 1)}
            disabled={index === 0}
          >
            <Icon name="menu-up-outline" size={30} color={theme.colors.grey3} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => moveItem(index, index + 1)}
            disabled={index === data.length - 1}
          >
            <Icon
              name="menu-down-outline"
              size={30}
              color={theme.colors.grey3}
            />
          </TouchableOpacity>
        </View>
        {itemCard(entryOrder, fullName, horseName ?? "", entity)}
      </View>
    );
  };

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      title="Editar ordem de entrada"
    >
      <View>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={(item, index) => `draggable-item-${index}`}
          />
        )}
      </View>
    </Modal>
  );
};
