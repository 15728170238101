// theme/index.ts

export const colors = {
  primary: "#1C2A59",
  secondary: "#1C4486",
  tertiary: "#5B86C3",
  background: "#EEEEEE",
  text: "#333333",
  white: "#FFFFFF",
  black: "#000000",
  accent: "#E8A123",
  accentBorder: "#EDD2A7",
  beige: "#CB862A",
  error: "#D7263D",
  grey2: "#999999",
  grey3: "#CCCCCC",
};

export type FontWeight =
  | "normal"
  | "bold"
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900";

export const typography = {
  title: {
    fontSize: 48,
    fontWeight: "700" as FontWeight,
  },
  h1: {
    fontSize: 32,
    fontWeight: "700" as FontWeight,
    color: colors.text,
  },
  h2: {
    fontSize: 24,
    fontWeight: "600" as FontWeight,
    color: colors.text,
  },
  h3: {
    fontSize: 18,
    fontWeight: "500" as FontWeight,
    color: colors.text,
  },
  body: {
    fontSize: 16,
    fontWeight: "400" as FontWeight,
    color: colors.text,
  },
  small: {
    fontSize: 12,
    fontWeight: "400" as FontWeight,
    color: colors.text,
  },
};

export const buttonStyles = {
  primary: {
    backgroundColor: colors.primary,
  },
  secondary: {
    backgroundColor: colors.secondary,
  },
  tertiary: {
    backgroundColor: colors.tertiary,
  },
  beige: {
    backgroundColor: colors.beige,
  },
  accent: {
    backgroundColor: colors.accent,
  },
  disabled: {
    backgroundColor: colors.background,
  },
};

export const cardStyles = {
  default: {
    backgroundColor: colors.white,
    borderColor: colors.primary,
  },
  error: {
    backgroundColor: colors.error,
    borderColor: colors.error,
  },
};

export interface Theme {
  colors: typeof colors;
  typography: typeof typography;
  buttonStyles: typeof buttonStyles;
  cardStyles: typeof cardStyles;
}
