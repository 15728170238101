import React, { useEffect, useState } from "react";
import { ListItem } from "react-native-elements";
import { View, Text, ScrollView } from "react-native";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { Enrollment, Reprise, FinalDressageScores } from "../../types";
import type { UpdatedEnrollmentType } from "../../hooks/useEnrollments";
import { useTheme } from "../../theme/ThemeContext";
import { useDressage } from "../../hooks/useDressage";
import { useSnackbar } from "../../context/SnackbarContext";
import { stringEntryOrderComparator } from "../../utils";
import { ManageSingleEnrollmentModal } from "../../components/ManageSingleEnrollmentModal/ManageSingleEnrollmentModalWeb";
import { DressageScoringModal } from "../../components/DressageScoringModal/DressageScoringModal";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import type { UpdateEnrollmentArgs } from "../../hooks/useEnrollments";

type Props = {
  enrollments: Enrollment[];
  modality: any; // Replace with the actual type
  handleUpdateEnrollmentStatus: (
    enrollmentId: string,
    variables: any
  ) => Promise<void>;
  handleUpdateHorse: (enrollmentId: string, horseId: string) => Promise<void>;
  isUpdating: boolean;
  updateEnrollment: (
    args: UpdateEnrollmentArgs
  ) => Promise<UpdatedEnrollmentType>;
};

export const DressageScoringScreen: React.FC<Props> = ({
  enrollments,
  modality,
  handleUpdateEnrollmentStatus,
  handleUpdateHorse,
  isUpdating,
  updateEnrollment,
}) => {
  const [showDressageScoreModal, setShowDressageScoreModal] = useState(false);

  const [selectedEnrollment, setSelectedEnrollment] =
    useState<Enrollment | null>(null);
  const [isEnrollmentModalVisible, setIsEnrollmentModalVisible] =
    useState(false);
  const [reprise, setReprise] = useState<Reprise>();

  const {
    reprises,
    isLoading: repriseLoading,
    error: repriseError,
  } = useDressage();
  const theme = useTheme();
  const { showMessage } = useSnackbar();

  useEffect(() => {
    if (modality && reprises) {
      const filteredReprises = reprises.filter(
        (reprise) => reprise.id === modality.dressageRepriseId
      );
      setReprise(filteredReprises[0]);
    }
  }, [modality, reprises]);

  const [approvedEnrollments, setApprovedEnrollments] =
    useState<Enrollment[]>(enrollments);
  const [finishedEnrollments, setFinishedEnrollments] =
    useState<Enrollment[]>(enrollments);

  useEffect(() => {
    setApprovedEnrollments(
      enrollments
        .filter((enrollment: Enrollment) => enrollment.approved)
        .filter((enrollment: Enrollment) => !enrollment.dressageScores)
        .sort((a, b) => {
          const entryOrderA = a.entryOrder?.toString() ?? "0";
          const entryOrderB = b.entryOrder?.toString() ?? "0";
          return stringEntryOrderComparator(entryOrderA, entryOrderB);
        })
    );
    setFinishedEnrollments(
      enrollments
        .filter((enrollment: Enrollment) => enrollment.approved)
        .filter((enrollment: Enrollment) => enrollment.dressageScores)
        .sort((a, b) => {
          if (b.dressageScores?.totalScore && a.dressageScores?.totalScore) {
            return b.dressageScores.totalScore - a.dressageScores.totalScore;
          }
          return 0;
        })
    );
  }, [enrollments]);

  const { name, type } = modality ?? {};

  let modType;

  if (type === "reprise") {
    modType = "Reprise";
  }

  const openEditScoreModal = (enrollment: Enrollment) => {
    setSelectedEnrollment(enrollment);
    setShowDressageScoreModal(true);
  };

  const closeEditScoreModal = () => {
    setShowDressageScoreModal(false);
    setSelectedEnrollment(null);
  };

  const handleEditEnrollment = () => {
    setShowDressageScoreModal(false);
    setIsEnrollmentModalVisible(true);
  };
  const handleEnrollmentModalClose = () => {
    setIsEnrollmentModalVisible(false);
    setSelectedEnrollment(null);
  };

  const handleSaveDressageScore = async (scores: FinalDressageScores) => {
    if (selectedEnrollment) {
      try {
        await updateEnrollment({
          enrollmentId: selectedEnrollment.id,
          dressageScores: scores,
        });
        showMessage("Scores updated successfully", "success");
      } catch (error) {
        showMessage("Error updating scores", "error");
      } finally {
        setShowDressageScoreModal(false);
      }
    }
  };

  if (repriseLoading) {
    return <LoadingIndicator />;
  }

  const dressageIntro = () => {
    if (reprise) {
      return (
        <>
          {reprise?.objective ? (
            <View style={{ padding: 10 }}>
              <Text style={[theme.typography.small]}>
                Objetivo: {reprise.objective}
              </Text>
            </View>
          ) : null}
          {reprise?.introduction ? (
            <View style={{ padding: 10 }}>
              <Text style={[theme.typography.small]}>
                Introdução: {reprise.introduction}
              </Text>
            </View>
          ) : null}
          {reprise?.conditions ? (
            <View style={{ padding: 10 }}>
              <Text style={[theme.typography.small]}>
                Condições: {reprise.conditions}
              </Text>
            </View>
          ) : null}
          {reprise?.averageTime ? (
            <View style={{ padding: 10 }}>
              <Text style={[theme.typography.small]}>
                Tempo médio da reprise: {reprise.averageTime}
              </Text>
            </View>
          ) : null}
          {reprise?.maxScore ? (
            <View style={{ padding: 10 }}>
              <Text style={[theme.typography.small]}>
                Máximo de pontos: {reprise.maxScore}
              </Text>
            </View>
          ) : null}
        </>
      );
    }
  };

  const renderEnrollmentListItem = (enrollment: Enrollment) => {
    const { horse, score, entryOrder, fullName, profile, id } = enrollment;

    const { firstName, lastName } = profile ?? {};

    return (
      <ListItem bottomDivider key={id}>
        <View style={{ margin: 15, alignItems: "center" }}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "700",
              color: theme.colors.secondary,
            }}
          >
            {entryOrder}
          </Text>
        </View>
        <ListItem.Content>
          <View>
            <ListItem.Subtitle
              style={{ ...theme.typography.body, marginBottom: 5 }}
            >
              {fullName ?? `${firstName} ${lastName}`}
            </ListItem.Subtitle>
            <ListItem.Subtitle
              style={{ ...theme.typography.body, marginBottom: 5 }}
            >
              {horse?.name}
            </ListItem.Subtitle>
          </View>
        </ListItem.Content>
        <Icon
          name="play-circle"
          size={30}
          color={theme.colors.accent}
          onPress={() => openEditScoreModal(enrollment)}
        />
      </ListItem>
    );
  };

  const renderResultsListItem = (enrollment: Enrollment) => {
    const {
      horse,
      score,
      entryOrder,
      isHorsConcours,
      isForfait,
      isEliminated,
      dressageScores,
      fullName,
      profile,
      id,
    } = enrollment;

    const { totalScore } = dressageScores ?? {};
    const { firstName, lastName } = profile ?? {};

    return (
      <ListItem bottomDivider key={id}>
        <View style={{ margin: 15, alignItems: "center" }}>
          {isForfait || isEliminated ? (
            <Icon
              name="emoticon-cry-outline"
              size={20}
              color={theme.colors.error}
            />
          ) : (
            <Text
              style={{
                fontSize: 20,
                fontWeight: "700",
                color: theme.colors.secondary,
              }}
            >
              {totalScore}
            </Text>
          )}
        </View>
        <ListItem.Content>
          <View>
            <ListItem.Subtitle
              style={{ ...theme.typography.body, marginBottom: 5 }}
            >
              {fullName ?? `${firstName} ${lastName}`}
            </ListItem.Subtitle>
            <ListItem.Subtitle
              style={{ ...theme.typography.body, marginBottom: 5 }}
            >
              {horse?.name}
            </ListItem.Subtitle>
          </View>
        </ListItem.Content>
        {/* Additional controls can be added here */}
      </ListItem>
    );
  };

  return (
    <View>
      <View style={{ margin: 15 }}>
        <Text
          style={{
            ...theme.typography.h3,
            color: theme.colors.secondary,
            textAlign: "center",
          }}
        >
          {name} - {modType}
        </Text>
      </View>
      <ScrollView>
        {dressageIntro()}

        {approvedEnrollments.map(renderEnrollmentListItem)}
        <View style={{ margin: 15 }}>
          <Text
            style={{
              ...theme.typography.h3,
              color: theme.colors.secondary,
              textAlign: "center",
            }}
          >
            Resultados
          </Text>
        </View>
        {finishedEnrollments.map(renderResultsListItem)}
      </ScrollView>

      {showDressageScoreModal && reprise ? (
        <DressageScoringModal
          isVisible={showDressageScoreModal}
          onClose={closeEditScoreModal}
          reprise={reprise}
          isLoading={isUpdating}
          onSave={handleSaveDressageScore}
          openEditEnrollment={handleEditEnrollment}
          enrollment={selectedEnrollment}
        />
      ) : null}

      {selectedEnrollment && isEnrollmentModalVisible && (
        <ManageSingleEnrollmentModal
          enrollment={selectedEnrollment}
          isVisible={isEnrollmentModalVisible}
          onClose={handleEnrollmentModalClose}
          handleUpdateEnrollmentStatus={handleUpdateEnrollmentStatus}
          handleUpdateHorse={handleUpdateHorse}
          isUpdating={isUpdating}
        />
      )}
    </View>
  );
};
