import React, { useState, useEffect } from "react";
import { View, ScrollView, TouchableOpacity } from "react-native";
import _ from "lodash";
import { Text, ListItem } from "react-native-elements";
import { Enrollment, Event } from "../../types";
import { useTheme } from "../../theme/ThemeContext";
import { stringEntryOrderComparator } from "../../utils";
import { useSnackbar } from "../../context/SnackbarContext";
import EmptyState from "../EmptyState/EmptyState";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
// import { EntryOrderModal } from "../EntryOrderModal/EntryOrderModal";
import { ManageSingleEnrollmentModal } from "../ManageSingleEnrollmentModal/ManageSingleEnrollmentModalWeb";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { DraggableEntryOrderModal } from "../DraggableEntryOrderModal/DraggableEntryOrderModal";

interface FilteredResults {
  rank?: number;
  score?: number;
  time?: number;
  approximation?: number;
  faults?: number;
}

interface ApprovedEnrollmentsListWebProps {
  enrollments: Enrollment[];
  modalityId?: string;
  isUpdating: boolean;
  isGeneratingEntryOrder: boolean;
  isUpdatingEntryOrder: boolean;
  handleGenerateEntryOrder: (modalityId: string) => Promise<void>;
  handleUpdateHorse: (enrollmentId: string, horseId: string) => Promise<void>;
  handleUpdateEnrollmentStatus: (
    enrollmentId: string,
    variables: {
      isForfait?: boolean;
      isHorsConcours?: boolean;
      isEliminated?: boolean;
      approved?: boolean;
    }
  ) => Promise<void>;
  // handleCreateOrEditEntryOrder: (
  //   enrollmentId: string,
  //   position: number
  // ) => Promise<void>;

  currentEvent?: Event | null;
  handleUpdateEntryOrder: (
    enrollmentId: string,
    newPosition: number,
    newData: Enrollment[]
  ) => Promise<void>;
}

const ApprovedEnrollmentsListWeb: React.FC<ApprovedEnrollmentsListWebProps> = ({
  enrollments,
  modalityId,
  isUpdating,
  handleGenerateEntryOrder,
  handleUpdateEnrollmentStatus,
  handleUpdateHorse,
  isGeneratingEntryOrder,
  handleUpdateEntryOrder,
  isUpdatingEntryOrder,
}) => {
  const theme = useTheme();
  const { showMessage } = useSnackbar();
  const [updated, setUpdated] = useState(false);
  const [viewPrint, setViewPrint] = useState(false);
  const [isDragEntryOrderModalVisible, setIsDragEntryOrderModalVisible] =
    useState(false);
  const [isEntryOrderModalVisible, setIsEntryOrderModalVisible] =
    useState(false);
  const [isEnrollmentModalVisible, setIsEnrollmentModalVisible] =
    useState(false);
  const [selectedEnrollment, setSelectedEnrollment] =
    useState<Enrollment | null>(null);

  const handleEditEnrollment = (enrollmentId: string) => {
    const selectedEnrollment = enrollments.find(
      (enrollment) => enrollment.id === enrollmentId
    );
    setSelectedEnrollment(selectedEnrollment || null);
    setIsEnrollmentModalVisible(true);
  };

  const hasEntryOrder = enrollments.some(
    (enrollment) =>
      enrollment.entryOrder !== undefined && enrollment.entryOrder !== null
  );

  useEffect(() => {
    if (hasEntryOrder) {
      setUpdated(!updated);
    }
  }, [hasEntryOrder]);

  const enrollmentsWithEntryOrder = enrollments.filter((enrollment) =>
    Boolean(enrollment.entryOrder)
  );

  const sortedEnrollments =
    enrollmentsWithEntryOrder.length > 1
      ? enrollmentsWithEntryOrder?.sort((a, b) => {
          const entryOrderA = a.entryOrder?.toString() ?? "0";
          const entryOrderB = b.entryOrder?.toString() ?? "0";
          return stringEntryOrderComparator(entryOrderA, entryOrderB);
        })
      : enrollmentsWithEntryOrder;

  const allEntryOrders = sortedEnrollments
    .map((enrollment) => enrollment.entryOrder)
    .filter((order) => order !== null) as string[];

  const enrollmentsWithoutOrder = enrollments.filter((enrollment) =>
    Boolean(!enrollment.entryOrder)
  );

  const generateEntryOrder = () => {
    if (modalityId) {
      handleGenerateEntryOrder(modalityId);
      showMessage("Ordem de entrada atualizada com sucesso", "success");
    }
  };

  const handleEnrollmentModalClose = () => {
    setIsEnrollmentModalVisible(false);
  };

  const handleEditEntryOrder = (enrollmentId: string) => {
    const selectedEnrollment = enrollments.find(
      (enrollment) => enrollment.id === enrollmentId
    );
    setSelectedEnrollment(selectedEnrollment || null);
    setIsEntryOrderModalVisible(true);
  };

  const renderItemWithoutEntryOrder = ({ item }: { item: Enrollment }) => {
    const { entity } = item.profile ?? {};
    const { name: horseName } = item.horse ?? {};
    const { entryOrder, category, fullName } = item ?? {};
    return (
      <ListItem
        key={item.id}
        bottomDivider
        onPress={() => {
          handleEditEnrollment(item.id);
        }}
      >
        <Text
          style={{
            fontSize: 20,
            fontWeight: "600",
            color: theme.colors.primary,
          }}
        >
          {entryOrder}
        </Text>
        <ListItem.Content style={{ flexShrink: 1 }}>
          {category && (
            <ListItem.Subtitle
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 5,
              }}
            >
              {category}
            </ListItem.Subtitle>
          )}
          <ListItem.Title>
            {fullName} {entity && `(${entity.acronym})`}
          </ListItem.Title>
          {horseName ? (
            <ListItem.Subtitle>{horseName}</ListItem.Subtitle>
          ) : (
            <ListItem.Subtitle style={{ color: theme.colors.error }}>
              sem montaria
            </ListItem.Subtitle>
          )}
        </ListItem.Content>
        <Icon name="alert-outline" size={30} color={theme.colors.error} />
      </ListItem>
    );
  };

  const itemWithoutEntryOrderGroup = () => (
    <View style={{ marginVertical: 30 }}>
      <Text
        style={{
          ...theme.typography.body,
          marginVertical: 10,
          marginLeft: 10,
          fontWeight: "700",
          color: theme.colors.error,
        }}
      >
        Inscrições sem ordem de entrada
      </Text>
      {enrollmentsWithoutOrder.map((enrollment: Enrollment) =>
        renderItemWithoutEntryOrder({ item: enrollment })
      )}
    </View>
  );

  return (
    <View style={{ flex: 1, justifyContent: "space-between" }}>
      <ScrollView>
        <TouchableOpacity
          onPress={() => {
            if (enrollmentsWithoutOrder.length > 0) {
              generateEntryOrder();
            } else {
              setIsDragEntryOrderModalVisible(true);
            }
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              padding: 15,
              backgroundColor: theme.colors.grey3,
            }}
          >
            <Text
              style={{
                ...theme.typography.h3,
                color: theme.colors.tertiary,
                fontWeight: "700",
              }}
            >
              {enrollmentsWithoutOrder.length > 0
                ? "Gerar ordem de entrada "
                : "Editar ordem de entrada "}
            </Text>
            <Icon
              name="format-list-numbered"
              size={30}
              color={theme.colors.tertiary}
            />
          </View>
        </TouchableOpacity>
        {isGeneratingEntryOrder ? (
          <LoadingIndicator />
        ) : (
          <View style={{ flexDirection: "column" }}>
            {enrollmentsWithoutOrder.length > 0 && itemWithoutEntryOrderGroup()}
            {sortedEnrollments.length > 0 && (
              <View>
                <Text
                  style={{
                    ...theme.typography.body,
                    marginVertical: 15,
                    fontWeight: "700",
                    marginLeft: 10,
                  }}
                >
                  Inscrições com ordem de entrada
                </Text>

                {sortedEnrollments.map((enrollment) => {
                  const { entity } = enrollment.profile ?? {};
                  const { name: horseName } = enrollment.horse ?? {};
                  const {
                    entryOrder,
                    fullName,
                    isForfait,
                    isHorsConcours,
                    isEliminated,
                  } = enrollment ?? {};
                  return (
                    <ListItem key={enrollment.id} bottomDivider>
                      <Text
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                          color: theme.colors.primary,
                        }}
                      >
                        {entryOrder}
                      </Text>
                      <ListItem.Content style={{ flexShrink: 1 }}>
                        <ListItem.Title
                          style={[
                            isEliminated || isForfait
                              ? {
                                  color: theme.colors.error,
                                  textDecorationLine: "line-through",
                                }
                              : null,
                          ]}
                        >
                          {isHorsConcours ? "HC - " : null} {fullName}{" "}
                          {entity ? `(${entity.name})` : null}
                        </ListItem.Title>
                        <ListItem.Subtitle>
                          {horseName ? horseName : "sem montaria"}
                        </ListItem.Subtitle>
                      </ListItem.Content>
                      <Icon
                        name="pencil-circle"
                        size={30}
                        color={theme.colors.grey2}
                        onPress={() => {
                          handleEditEnrollment(enrollment.id);
                        }}
                      />
                    </ListItem>
                  );
                })}
              </View>
            )}
            {enrollmentsWithoutOrder.length === 0 &&
              sortedEnrollments.length === 0 && (
                <EmptyState
                  message="Os concorrentes aprovados aparecerão aqui"
                  iconName="horse-human"
                />
              )}
          </View>
        )}
      </ScrollView>
      {isDragEntryOrderModalVisible && (
        <DraggableEntryOrderModal
          isVisible={isDragEntryOrderModalVisible}
          enrollments={sortedEnrollments}
          onClose={() => setIsDragEntryOrderModalVisible(false)}
          updateEntryOrder={handleUpdateEntryOrder}
          loading={isUpdatingEntryOrder}
        />
      )}

      {selectedEnrollment && (
        <ManageSingleEnrollmentModal
          enrollment={selectedEnrollment}
          isVisible={isEnrollmentModalVisible}
          onClose={handleEnrollmentModalClose}
          handleUpdateEnrollmentStatus={handleUpdateEnrollmentStatus}
          handleUpdateHorse={handleUpdateHorse}
          isUpdating={isUpdating}
        />
      )}
    </View>
  );
};

export default ApprovedEnrollmentsListWeb;
