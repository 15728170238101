// src/assets/images/imageSources.ts

import showjumpingImg01 from "./showjumping_01.png";
import horseLove from "./horse_love.jpeg";
import dressageImg01 from "./dressage_01.png";
import hunterTrailsImg01 from "./hunter-trails_01.png";
import poloImg01 from "./polo_01.png";
import roapingImg01 from "./roaping_01.png";
import barrelImg01 from "./barrel_01.png";
import error_01 from "./error_01.png";
import message_01 from "./message_01.png";
import logoVertical01 from "./logo_vertical_01.png";
import logoBlueYellow from "./logo_blue_yellow.png";
import logoHorizontal from "./logo_horizontal.png";
import logoWhite from "./logo_white.png";
import logoHorizontalWhiteYellow from "./logo_horizontal_white_yellow.png";
import entryOrder from "./entry-order.png";
import googlePlayQrCode from "./qr-code-play.png";
import appleQrCode from "./qr-code-apple.png";
import horseVideo01 from "./horse_video_01.gif";
import horseVideo02 from "./horse_video_02.gif";
import iphone from "./iphone.png";
import twoIphones from "./two_iphones.png";
import handHoldingSmartphone from "./hand_holding_smartphone.png";
import office_01 from "./office_01.jpeg";
import office_02 from "./office_02.jpeg";
import horsePic from "./horse.jpeg";

const imageSources = {
  showjumping: [showjumpingImg01],
  dressage: [dressageImg01],
  hunterTrails: [hunterTrailsImg01],
  polo: [poloImg01],
  barrel: [barrelImg01],
  roaping: [roapingImg01],
  error: [error_01],
  message: [message_01],
  logo: [logoVertical01, logoBlueYellow, logoWhite],
  logoWhite,
  logoVertical01,
  logoBlueYellow,
  logoHorizontal,
  logoHorizontalWhiteYellow,
  googlePlayQrCode,
  appleQrCode,
  entryOrder,
  horseVideo01,
  iphone,
  handHoldingSmartphone,
  twoIphones,
  horseVideo02,
  office_01,
  office_02,
  horseLove,
  horsePic,
};

const imageMap = new Map();
imageMap.set("Salto", imageSources.showjumping);
imageMap.set("Adestramento", imageSources.dressage);
imageMap.set("Enduro", imageSources.hunterTrails);
imageMap.set("Polo", imageSources.polo);
imageMap.set("Laço", imageSources.roaping);
imageMap.set("Tambor", imageSources.barrel);
imageMap.set("All", [
  ...imageSources.roaping,
  ...imageSources.polo,
  ...imageSources.hunterTrails,
  ...imageSources.dressage,
  ...imageSources.showjumping,
  ...imageSources.barrel,
]);

export { imageMap, imageSources };
