import React, { useEffect, useState } from "react";
import { Button } from "react-native-elements";
import pdfMake from "pdfmake/build/pdfmake";
import { View } from "react-native";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { PageOrientation } from "pdfmake/interfaces";
import htmlToPdfmake from "html-to-pdfmake";
import { Event, Modality, Enrollment, ModalityKeys } from "../../types"; // Import your types here
import { formatDateToDisplay, stringEntryOrderComparator } from "../../utils"; // Import your utility functions here
import { useTheme } from "../../theme/ThemeContext";
import useOrganizerEvents from "../../hooks/useOrganizerEvents";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const EventPdf = ({
  currentEvent,
  modalityId,
}: {
  currentEvent: Event | null;
  modalityId?: string;
}) => {
  const { fetchEventEnrollments, enrollmentsLoading } = useOrganizerEvents();
  const [enrollments, setEnrollments] = useState<Enrollment[]>([]);

  useEffect(() => {
    if (currentEvent?.id) {
      fetchEventEnrollments(currentEvent.id).then((fetchedEnrollments) => {
        if (fetchedEnrollments) {
          setEnrollments(fetchedEnrollments);
        }
      });
    }
  }, [currentEvent]);

  const eventTitle = currentEvent?.title ?? "";
  const eventDate = currentEvent?.date
    ? formatDateToDisplay(currentEvent.date)
    : "";
  const modalities = currentEvent?.modalities ?? [];

  const generatePDF = (modalitiesHtml: any[]) => {
    const documentDefinition = {
      content: [
        {
          text: eventTitle,
          style: "header",
        },
        ,
        ...modalitiesHtml,
      ],
      pageOrientation: "landscape" as PageOrientation,
      styles: {
        header: {
          fontSize: 24,
          bold: true,
        },
      },
    };
    pdfMake.createPdf(documentDefinition).download();
  };

  const sortEnrollments = (
    modalityEnrollments: Enrollment[],
    isModTimeTrial: boolean
  ) => {
    return modalityEnrollments.sort((a, b) => {
      if (
        a.showjumpingScores?.totalFaults !== b.showjumpingScores?.totalFaults
      ) {
        return (
          (a.showjumpingScores?.totalFaults ?? 0) -
          (b.showjumpingScores?.totalFaults ?? 0)
        );
      }
      return (
        (isModTimeTrial
          ? a.showjumpingScores?.approximation ?? 0
          : a.showjumpingScores?.time ?? 0) -
        (isModTimeTrial
          ? b.showjumpingScores?.approximation ?? 0
          : b.showjumpingScores?.time ?? 0)
      );
    });
  };

  const createPdf = (
    onlyApproved: boolean = false,
    showScores: boolean = false
  ) => {
    const sortedEnrollments = enrollments
      .filter((e) => (onlyApproved ? e.approved : true))
      .sort((a, b) => {
        // First, sort by approved status if onlyApproved is false
        if (!onlyApproved) {
          if (a.approved && !b.approved) return 1;
          if (!a.approved && b.approved) return -1;
        }

        // Then, sort by modalityId
        return a.modalityId.localeCompare(b.modalityId);
      });

    // Create a mapping of modalityId to enrollments
    const enrollmentsByModality: Record<string, Enrollment[]> = {};
    sortedEnrollments.forEach((enrollment) => {
      if (!enrollmentsByModality[enrollment.modalityId]) {
        enrollmentsByModality[enrollment.modalityId] = [];
      }
      enrollmentsByModality[enrollment.modalityId].push(enrollment);
    });

    let filteredMod;

    if (modalityId) {
      filteredMod = modalities.filter((modality) => modality.id === modalityId);
    } else {
      filteredMod = modalities;
    }

    const modalitiesHtml = filteredMod
      .map((modality: Modality) => {
        // Fetch enrollments for the current modality
        let modalityEnrollments = enrollmentsByModality[modality.id] || [];
        const { name: modName, type: modType } = modality ?? {};

        const isModTimeTrial = modType === ModalityKeys.TIME_TRIAL;

        if (onlyApproved && !showScores) {
          // Sort by 'entryOrder' if it exists and we're only showing approved entries
          modalityEnrollments = modalityEnrollments
            .filter((e) => e.approved)
            .sort((a, b) => {
              const entryOrderA = String(a.entryOrder ?? "0");
              const entryOrderB = String(b.entryOrder ?? "0");
              return stringEntryOrderComparator(entryOrderA, entryOrderB);
            });
        }

        if (showScores) {
          modalityEnrollments = modalityEnrollments
            .filter((e) => e.approved)
            .sort((a, b) => {
              const rankA = String(a.rank ?? "0");
              const rankB = String(b.rank ?? "0");
              return stringEntryOrderComparator(rankA, rankB);
            });
        }

        const entryOrderColumn = onlyApproved
          ? '<th style="border: 2px solid #757575; padding: 8px;">Nr</th>'
          : "";
        const scoringColumns = onlyApproved
          ? `
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;"> </th>
            <th style="border: 2px solid #757575; padding: 8px;">Faltas</th>
            <th style="border: 2px solid #757575; padding: 8px;">Tempo</th>
            <th style="border: 2px solid #757575; padding: 8px;">Approx.</th>
            <th style="border: 2px solid #757575; padding: 8px;">Penal.</th>
            <th style="border: 2px solid #757575; padding: 8px;">Total</th>
            <th style="border: 2px solid #757575; padding: 8px;">Class.</th>`
          : "";

        const enrollmentsHtml =
          modalityEnrollments
            .map(
              (enrollment: Enrollment) => `
          <tr>
          ${
            onlyApproved && enrollment.approved
              ? `<td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
                  enrollment.entryOrder ?? ""
                }</td>`
              : ""
          }
            <td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
              enrollment.fullName ??
              `${enrollment.profile?.firstName} ${
                enrollment.profile?.lastName
              }${enrollment.category ? ` | ${enrollment.category}` : ""}`
            }</td>
            <td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
              enrollment.horse?.name ?? ""
            }</td>
            <td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
              enrollment.entity?.acronym ?? ""
            }</td>
            ${
              !onlyApproved
                ? `<td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
                    enrollment.approved ? "Aprovado" : "Pendente"
                  }</td>`
                : ""
            }
            ${
              onlyApproved && enrollment.approved
                ? `
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px"></td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
                  showScores
                    ? enrollment.showjumpingScores?.faults ?? "  "
                    : "   "
                }</td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
                  showScores
                    ? enrollment.showjumpingScores?.time ?? "  "
                    : "   "
                }</td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
                  showScores
                    ? enrollment.showjumpingScores?.approximation ?? "  "
                    : "   "
                }</td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
                  showScores
                    ? enrollment.showjumpingScores?.penalties ?? "  "
                    : "   "
                }</td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
                  showScores
                    ? enrollment.showjumpingScores?.totalFaults ?? "  "
                    : "   "
                }</td>
                <td style="border: 2px solid #757575; margin: 2px; font-size: 12px">${
                  showScores ? enrollment.rank ?? "  " : "   "
                }</td>
                `
                : ""
            }
          </tr>
        `
            )
            .join("") ?? "";

        const modalityContent = htmlToPdfmake(`
        <h4>${modality.name}</h4>
        <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr style="background-color: #f5f5f5;">
              ${entryOrderColumn}
              <th style="border: 2px solid #757575; padding: 8px;">Nome</th>
              <th style="border: 2px solid #757575; padding: 8px;">Monta</th>
              <th style="border: 2px solid #757575; padding: 8px;">Entidade</th>
              ${
                !onlyApproved
                  ? '<th style="border: 2px solid #757575; padding: 8px;">Status</th>'
                  : ""
              }
             
              ${scoringColumns}
            </tr>
          </thead>
          <tbody>
            ${enrollmentsHtml}
          </tbody>
        </table>
      `);
        return [modalityContent, { text: "", pageBreak: "after" }];
      })
      .flat();

    generatePDF(modalitiesHtml);
  };

  const theme = useTheme();

  if (enrollmentsLoading) {
    return <LoadingIndicator />;
  }

  return (
    <View>
      <Button
        title="Imprimir todos os inscritos"
        onPress={() => createPdf(false, false)}
        buttonStyle={[theme.buttonStyles.tertiary, { marginBottom: 10 }]}
      />
      <Button
        title="Imprimir ata"
        onPress={() => createPdf(true, false)}
        buttonStyle={[theme.buttonStyles.secondary, { marginBottom: 10 }]}
      />
      <Button
        title="Imprimir resultados"
        onPress={() => createPdf(true, true)}
        buttonStyle={theme.buttonStyles.primary}
      />
    </View>
  );
};
