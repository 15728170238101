import React from "react";
import { CorcelIcon } from "./CorcelIcon";

interface Props {
  color?: string;
  size?: number;
}

export const InstagramIcon: React.FC<Props> = ({ color = "#000", size }) => {
  const myPaths = [
    {
      d: "M8.4 0.666672H19.6C23.8667 0.666672 27.3333 4.13334 27.3333 8.40001V19.6C27.3333 21.651 26.5186 23.618 25.0683 25.0683C23.618 26.5186 21.651 27.3333 19.6 27.3333H8.4C4.13333 27.3333 0.666664 23.8667 0.666664 19.6V8.40001C0.666664 6.349 1.48142 4.38199 2.93171 2.93171C4.38199 1.48143 6.34899 0.666672 8.4 0.666672ZM8.13333 3.33334C6.86029 3.33334 5.63939 3.83905 4.73922 4.73923C3.83904 5.6394 3.33333 6.8603 3.33333 8.13334V19.8667C3.33333 22.52 5.48 24.6667 8.13333 24.6667H19.8667C21.1397 24.6667 22.3606 24.161 23.2608 23.2608C24.161 22.3606 24.6667 21.1397 24.6667 19.8667V8.13334C24.6667 5.48001 22.52 3.33334 19.8667 3.33334H8.13333ZM21 5.33334C21.442 5.33334 21.8659 5.50893 22.1785 5.82149C22.4911 6.13405 22.6667 6.55798 22.6667 7.00001C22.6667 7.44203 22.4911 7.86596 22.1785 8.17852C21.8659 8.49108 21.442 8.66667 21 8.66667C20.558 8.66667 20.134 8.49108 19.8215 8.17852C19.5089 7.86596 19.3333 7.44203 19.3333 7.00001C19.3333 6.55798 19.5089 6.13405 19.8215 5.82149C20.134 5.50893 20.558 5.33334 21 5.33334ZM14 7.33334C15.7681 7.33334 17.4638 8.03572 18.714 9.28596C19.9643 10.5362 20.6667 12.2319 20.6667 14C20.6667 15.7681 19.9643 17.4638 18.714 18.7141C17.4638 19.9643 15.7681 20.6667 14 20.6667C12.2319 20.6667 10.5362 19.9643 9.28595 18.7141C8.03571 17.4638 7.33333 15.7681 7.33333 14C7.33333 12.2319 8.03571 10.5362 9.28595 9.28596C10.5362 8.03572 12.2319 7.33334 14 7.33334ZM14 10C12.9391 10 11.9217 10.4214 11.1716 11.1716C10.4214 11.9217 10 12.9391 10 14C10 15.0609 10.4214 16.0783 11.1716 16.8284C11.9217 17.5786 12.9391 18 14 18C15.0609 18 16.0783 17.5786 16.8284 16.8284C17.5786 16.0783 18 15.0609 18 14C18 12.9391 17.5786 11.9217 16.8284 11.1716C16.0783 10.4214 15.0609 10 14 10Z",
      color: color,
    },
  ];

  return <CorcelIcon paths={myPaths} size={size} viewBox="0 0 28 28" />;
};
