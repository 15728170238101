import React from "react";
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  StyleSheet,
  Modal as RNModal,
  ActivityIndicator,
} from "react-native";
import { useTheme } from "../../theme/ThemeContext";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  title: string;
  buttonText?: string;
  isLoading?: boolean;
  submitLoading?: boolean;
  children: React.ReactNode;
};

export const Modal: React.FC<ModalProps> = ({
  isVisible,
  onClose,
  onSubmit,
  title,
  buttonText,
  children,
  isLoading,
  submitLoading,
}) => {
  const theme = useTheme();
  return (
    <RNModal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalContainer}>
          <View style={styles.modalHeader}>
            <Text
              style={[
                theme.typography.h3,
                { fontWeight: "600", flexShrink: 1 },
              ]}
            >
              {title}
            </Text>
            <TouchableOpacity onPress={onClose} style={styles.closeButton}>
              <Icon
                name="close"
                size={25}
                color={theme.colors.secondary}
                onPress={onClose}
              />
            </TouchableOpacity>
          </View>

          {isLoading ? (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: 30,
              }}
            >
              <ActivityIndicator color={theme.colors.secondary} size="large" />
            </View>
          ) : (
            <ScrollView style={styles.modalContent}>{children}</ScrollView>
          )}

          {onSubmit && buttonText ? (
            <View style={styles.modalFooter}>
              <TouchableOpacity
                onPress={onSubmit}
                style={[
                  styles.submitButton,
                  { backgroundColor: theme.colors.secondary },
                ]}
              >
                {submitLoading ? (
                  <ActivityIndicator color="white" />
                ) : (
                  <Text style={styles.submitButtonText}>{buttonText}</Text>
                )}
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
      </View>
    </RNModal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContainer: {
    width: "90%", // Set a maximum width
    maxHeight: "80%", // Set a maximum height
    backgroundColor: "white",
    borderRadius: 4,
    alignItems: "center",
  },
  modalHeader: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    marginBottom: 10,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  closeButton: {
    padding: 10,
  },
  closeText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  modalContent: {
    width: "100%",
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  modalFooter: {
    width: "100%",
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  submitButton: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 4,
  },
  submitButtonText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
  },
});
