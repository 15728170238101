import React, { useState } from "react";
import { View, Dimensions } from "react-native";
import { Text } from "react-native-elements";

import { Modality, Event } from "../../types";

import useEnrollments from "../../hooks/useEnrollments";
import useOrganizerEvents from "../../hooks/useOrganizerEvents";
import { useTheme } from "../../theme/ThemeContext";
import { useSnackbar } from "../../context/SnackbarContext";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

import { EventTypeIds } from "../../types";
import { ShowjumpingScoringScreenWeb } from "../ModalityScoringScreen/ShowjumpingScoringScreenWeb";
import { DressageScoringScreen } from "../ModalityScoringScreen/DressageScoringScreen";

type Props = {
  modality: Modality;
  currentEvent: Event;
};
const ModalityManagementScoringScreen: React.FC<Props> = ({
  modality,
  currentEvent,
}) => {
  const theme = useTheme();
  const { showMessage } = useSnackbar();

  const modalityId = modality?.id;
  const eventTypeId = currentEvent?.eventTypeId;
  const { modalityLoading } = useOrganizerEvents();
  const windowHeight = Dimensions.get("window").height;
  const isDressageEvent = eventTypeId === EventTypeIds.DRESSAGE_EVENT_TYPE_ID;
  const isShowjumpingEvent =
    eventTypeId === EventTypeIds.SHOWJUMPING_EVENT_TYPE_ID;

  const {
    enrollments,
    isLoading,
    error,
    updateEnrollment,
    isUpdating,
    generateEntryOrderForModality,
    isGeneratingEntryOrder,
    handleUpdateEntryOrder,
    isUpdatingEntryOrder,
    updateRanks,
    rankIsUpdating,
    createLastMinuteEnrollment,
    isCreating,
  } = useEnrollments({
    modalityId,
  });

  const [approvedCount, setApprovedCount] = useState(0);

  const handleUpdateHorse = async (enrollmentId: string, horseId: string) => {
    try {
      await updateEnrollment({
        enrollmentId,
        horseId,
      });
      showMessage("Cavalo atualizado com sucesso", "success");
    } catch (error) {
      showMessage("Não foi possível atualizar o cavalo", "error");
      console.error("Error updating horse:", error);
    }
  };

  const handleUpdateEnrollmentStatus = async (
    enrollmentId: string,
    variables: {
      isForfait?: boolean;
      isHorsConcours?: boolean;
      isEliminated?: boolean;
      approved?: boolean;
    }
  ) => {
    try {
      await updateEnrollment({
        enrollmentId,
        ...variables,
      });

      setApprovedCount((count) => (variables.approved ? count + 1 : count - 1));

      showMessage("Inscrição atualizada com sucesso", "success");
    } catch (error) {
      showMessage("Não foi possível atualizar inscrição", "error");
      console.error("Error updating enrollment:", error);
    }
  };

  const handleGenerateEntryOrder = async (modalityId: string) => {
    try {
      await generateEntryOrderForModality(modalityId);
      showMessage("Ordem de entrada gerada com sucesso", "success");
    } catch (error) {
      console.error("Error generating entry order:", error);
      showMessage("Não foi possivel gerar ordem de entrada", "error");
    }
  };

  if (isLoading || modalityLoading) {
    return (
      <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
        <LoadingIndicator />
      </View>
    );
  }

  if (error) {
    return (
      <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
        <Text>Não foi possível carregar a lista de inscritos.</Text>
      </View>
    );
  }

  const enrollmentList = () => {
    if (isShowjumpingEvent) {
      return (
        <ShowjumpingScoringScreenWeb
          enrollments={enrollments}
          modality={modality}
          handleUpdateEnrollmentStatus={handleUpdateEnrollmentStatus}
          handleUpdateHorse={handleUpdateHorse}
          isUpdating={isUpdating}
          updateEnrollment={updateEnrollment}
          updateRanks={updateRanks}
          rankIsUpdating={rankIsUpdating}
          createLastMinuteEnrollment={createLastMinuteEnrollment}
          isCreating={isCreating}
          eventId={currentEvent?.id}
          handleGenerateEntryOrder={handleGenerateEntryOrder}
          isGeneratingEntryOrder={isGeneratingEntryOrder}
          handleUpdateEntryOrder={handleUpdateEntryOrder}
          isUpdatingEntryOrder={isUpdatingEntryOrder}
          event={currentEvent}
        />
      );
    }

    if (isDressageEvent) {
      return (
        <DressageScoringScreen
          enrollments={enrollments}
          modality={modality}
          handleUpdateEnrollmentStatus={handleUpdateEnrollmentStatus}
          handleUpdateHorse={handleUpdateHorse}
          isUpdating={isUpdating}
          updateEnrollment={updateEnrollment}
        />
      );
    }
  };

  return (
    <View style={{ height: windowHeight - 1000 }}>{enrollmentList()}</View>
  );
};

export default ModalityManagementScoringScreen;
