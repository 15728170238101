// src/hooks/useEnrollments.ts

import { useEffect, useState, useMemo } from "react";
import { useEnrollmentsContext } from "../context/EnrollmentsContext";
import { useAuth } from "./useAuth";
import {
  getFirestore,
  onSnapshot,
  Query,
  QuerySnapshot,
  FirestoreError,
  DocumentData,
} from "firebase/firestore";
import {
  getDocs,
  where,
  query,
  collection,
  doc,
  getDoc,
  addDoc,
  writeBatch,
  updateDoc,
  runTransaction,
} from "firebase/firestore";
import {
  Horse,
  Enrollment,
  Modality,
  FinalDressageScores,
  FinalShowjumpingScores,
} from "../types";
import { sendPushNotification } from "./useAuth";
import { getEntity } from "./useUserProfile";
// import { updateModalityTime } from "../firebaseStandaloneFunctions/handleModalities";

interface UseEnrollmentsProps {
  eventId?: string;
  modalityId?: string;
  profileId?: string;
}

export interface UpdateEnrollmentArgs {
  enrollmentId: string;
  horseId?: string;
  approved?: boolean;
  rank?: number;
  score?: number;
  time?: number;
  approximation?: number;
  timeFaultPoints?: number;
  totalFaultPoints?: number;
  faults?: number;
  isHorsConcours?: boolean;
  isForfait?: boolean;
  isEliminated?: boolean;
  dressageScores?: FinalDressageScores;
  showjumpingScores?: FinalShowjumpingScores;
}

export interface UpdatedEnrollmentType {
  // Define all the fields that an updated enrollment would have
  id: string;
  horseId?: string;
  approved?: boolean;
  isHorsConcours?: boolean;
  rank?: number;
  score?: number;
  time?: number;
  approximation?: number;
  faults?: number;
  timeFaultPoints?: number;
  totalFaultPoints?: number;
  isForfait?: boolean;
  isEliminated?: boolean;
  dressageScores?: FinalDressageScores;
  horse?: Horse;
}

export interface RankUpdateArgs {
  title: string; // The ID of the section where you want to update the ranks
  data: Array<Enrollment>; // An array containing the id and finalScore of each enrollment in this section
}

const useEnrollments = (props: UseEnrollmentsProps) => {
  const options = useMemo(() => props, [props]);
  const { profileId, modalityId, eventId } = options;
  const { enrollments, setEnrollments } = useEnrollmentsContext();
  const { user, userRole } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isGeneratingEntryOrder, setIsGeneratingEntryOrder] = useState(false);
  const [isUpdatingEntryOrder, setIsUpdatingEntryOrder] = useState(false);
  const [rankIsUpdating, setIsRankUpdating] = useState(false);
  const [modalityUpdating, setModalityUpdating] = useState(false);

  const processEnrollment = async (enrollmentDoc: any) => {
    const db = getFirestore();
    try {
      const enrollmentData = enrollmentDoc.data();

      const modalityDocRef = doc(db, "modalities", enrollmentData.modalityId);
      const modalityDoc = await getDoc(modalityDocRef).catch((error) => {
        console.error(`Erro ao obter documento da categoria`, error);
        return null;
      });

      const horseDocRef = enrollmentData.horseId
        ? doc(db, "horses", enrollmentData.horseId)
        : null;

      const horseDoc = horseDocRef
        ? await getDoc(horseDocRef).catch((error) => {
            console.error(`Erro ao obter documento do cavalo`, error);
            return null;
          })
        : null;

      const profileDocRef = doc(db, "users", enrollmentData.profileId);
      const profileDoc = await getDoc(profileDocRef).catch((error) => {
        console.error(`Erro ao obter documento do perfil`, error);
        return null;
      });

      if (!profileDoc) {
        return null;
      }

      const profileData = profileDoc.data();

      const participantEntity = await getEntity(profileData?.entityId);

      const entityDocRef = profileData?.organizerId
        ? doc(db, "users", profileData?.organizerId)
        : null;

      const entityDoc = entityDocRef
        ? await getDoc(entityDocRef).catch((error) => {
            console.error(`Erro ao obter documento da entidade`, error);
            return null;
          })
        : null;

      return {
        id: enrollmentDoc.id,
        ...enrollmentData,
        modality:
          modalityDoc && modalityDoc.exists()
            ? { ...modalityDoc.data(), id: modalityDoc.id }
            : null,
        horse:
          horseDoc && horseDoc.exists()
            ? { ...horseDoc.data(), id: horseDoc.id }
            : null,
        entity:
          entityDoc && entityDoc.exists()
            ? { ...entityDoc.data(), id: entityDoc.id }
            : null,
        profile: profileDoc.exists()
          ? {
              ...profileData,
              id: profileDoc.id,
              entity: participantEntity,
            }
          : null,
      };
    } catch (error) {
      console.error(`Erro ao processar inscrição:`, error);
      return null;
    }
  };

  const fetchEnrollments = (enrollmentQuery: Query<DocumentData>) => {
    setIsLoading(true);
    setError(null);

    try {
      if (!user) {
        throw new Error("Você deve estar logado para ver seus registros");
      }

      const unsubscribe = onSnapshot(enrollmentQuery, {
        next: async (enrollmentsSnapshot: QuerySnapshot<DocumentData>) => {
          if (enrollmentsSnapshot.empty) {
            setEnrollments([]);
            setIsLoading(false);
            return;
          }

          const enrollmentPromises =
            enrollmentsSnapshot.docs.map(processEnrollment);

          const allEnrollments = (await Promise.all(enrollmentPromises)).filter(
            (enrollment) => enrollment !== null
          );

          setEnrollments(allEnrollments);
          setIsLoading(false);
        },
        error: (error: FirestoreError) => {
          console.error("Erro ao obter inscrições:", error);
          setError("Um erro desconhecido ocorreu");
          setIsLoading(false);
        },
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("Um erro desconhecido ocorreu");
      }
      setIsLoading(false);
    }
  };

  const fetchModalityEnrollments = (id: string): (() => void) | undefined => {
    const db = getFirestore();

    let enrollmentQuery;

    enrollmentQuery = query(
      collection(db, "enrollments"),
      where("modalityId", "==", id)
    );

    return fetchEnrollments(enrollmentQuery);
  };

  const fetchEventEnrollments = (
    id: string,
    userId: string
  ): (() => void) | undefined => {
    const db = getFirestore();

    let enrollmentQuery;

    enrollmentQuery = query(
      collection(db, "enrollments"),
      where("profileId", "==", userId),
      where("eventId", "==", id)
    );

    return fetchEnrollments(enrollmentQuery);
  };

  const fetchProfileEnrollments = (id: string): (() => void) | undefined => {
    const db = getFirestore();

    let enrollmentQuery;

    enrollmentQuery = query(
      collection(db, "enrollments"),
      where("profileId", "==", id)
    );

    return fetchEnrollments(enrollmentQuery);
  };

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (user && modalityId) {
      unsubscribe = fetchModalityEnrollments(modalityId);
    }
    if (user && eventId) {
      const { uid: userId } = user;
      unsubscribe = fetchEventEnrollments(eventId, userId);
    }
    if (user && profileId) {
      unsubscribe = fetchProfileEnrollments(profileId);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user, modalityId, eventId, profileId]);

  const createEnrollment = async ({
    eventId,
    modalityId,
    fullName,
    age,
    horseId,
    category,
  }: {
    eventId: string;
    category: string | null;
    modalityId: string;
    age: number;
    fullName: string;
    horseId?: string | null;
  }) => {
    setIsCreating(true);
    try {
      if (!user || userRole !== "PARTICIPANT") {
        throw new Error("Somente participantes podem se registrar em eventos");
      }

      const db = getFirestore();

      const eventDocRef = doc(db, "events", eventId);
      const eventDoc = await getDoc(eventDocRef);

      if (!eventDoc.exists()) {
        throw new Error("Evento não encontrado");
      }

      const eventData = eventDoc.data();

      const modalityDocRef = doc(db, "modalities", modalityId);
      const modalityDoc = await getDoc(modalityDocRef);

      if (!modalityDoc.exists()) {
        throw new Error("Categoria não encontrada");
      }

      if (horseId) {
        const horseDocRef = doc(db, "horses", horseId);
        const horseDoc = await getDoc(horseDocRef);

        if (!horseDoc.exists()) {
          throw new Error("Cavalo não encontrado");
        }
      }

      const existingEnrollmentQueryWithHorse = query(
        collection(db, "enrollments"),
        where("profileId", "==", user.uid),
        where("modalityId", "==", modalityId),
        where("horseId", "==", horseId),
        where("fullName", "==", fullName)
      );

      const existingEnrollmentQueryWithoutHorse = query(
        collection(db, "enrollments"),
        where("profileId", "==", user.uid),
        where("modalityId", "==", modalityId),
        where("horseId", "==", null),
        where("fullName", "==", fullName)
      );

      const [
        existingEnrollmentWithHorseSnapshot,
        existingEnrollmentWithoutHorseSnapshot,
      ] = await Promise.all([
        getDocs(existingEnrollmentQueryWithHorse),
        getDocs(existingEnrollmentQueryWithoutHorse),
      ]);

      if (
        !existingEnrollmentWithHorseSnapshot.empty ||
        (!horseId && !existingEnrollmentWithoutHorseSnapshot.empty)
      ) {
        throw new Error(
          "Você já está cadastrado nessa categoria com esse cavalo. Escolha outro cavalo."
        );
      }

      const enrollmentRef = await addDoc(collection(db, "enrollments"), {
        profileId: user.uid,
        eventId,
        fullName,
        category,
        age,
        horseId: horseId || null,
        modalityId,
        approved: false,
        rank: null,
        score: null,
        time: null,
        approximation: null,
        faults: null,
      });

      const organizerDoc = await getDoc(
        doc(db, "users", eventData.organizerId)
      );
      const organizerPushToken = organizerDoc.data()?.expoPushToken;

      if (organizerPushToken) {
        // Send a push notification to the organizer
        await sendPushNotification(
          organizerPushToken,
          "Nova inscrição",
          `Há um novo concorrente inscrito no evento '${
            eventData.title
          }', na série '${modalityDoc.data().name}'.`
        );
      }

      return {
        id: enrollmentRef.id,
        profileId: user.uid,
        eventId,
        horseId: horseId || null,
        approved: false,
        fullName,
        category,
        age,
      };
    } finally {
      setIsCreating(false);
    }
  };

  const createLastMinuteEnrollment = async ({
    eventId,
    modalityId,
    fullName,
    category,
  }: {
    eventId?: string;
    category: string | null;
    modalityId: string;
    fullName: string;
  }) => {
    setIsCreating(true);

    try {
      if (!user) {
        throw new Error("Only organizers can create last-minute enrollments");
      }

      const db = getFirestore();

      // Replace these with your generic profileId and horseId
      const genericProfileId = "jBbuuzMzgfYXlyqFbpv6AQ3sR7K2";
      const genericHorseId = "APmCxJQBixhJ1R6S5i5q";
      const adjustedName = fullName !== "" ? fullName : "Pessoa";
      const adjustedCategory = category ?? "Outro";

      if (!eventId) {
        throw new Error("Event not found");
      }

      console.log("here");

      const eventDocRef = doc(db, "events", eventId);
      const eventDoc = await getDoc(eventDocRef);

      if (!eventDoc.exists()) {
        throw new Error("Event not found");
      }

      const modalityDocRef = doc(db, "modalities", modalityId);
      const modalityDoc = await getDoc(modalityDocRef);

      if (!modalityDoc.exists()) {
        throw new Error("Modality not found");
      }

      const enrollmentRef = await addDoc(collection(db, "enrollments"), {
        profileId: genericProfileId,
        eventId,
        fullName: adjustedName,
        category: adjustedCategory,
        horseId: genericHorseId,
        modalityId,
        approved: true,
        rank: null,
        score: null,
        time: null,
        approximation: null,
        faults: null,
      });

      // const organizerDoc = await getDoc(doc(db, "users", user.uid));
      // const organizerPushToken = organizerDoc.data()?.expoPushToken;

      // if (organizerPushToken) {
      //   // Send a push notification to the organizer
      //   await sendPushNotification(
      //     organizerPushToken,
      //     "Inscrição de última hora",
      //     `Uma inscrição de última hora foi adicionada ao evento '${
      //       eventDoc.data().title
      //     }', na série '${modalityDoc.data().name}'.`
      //   );
      // }

      const newEnrollment = {
        id: enrollmentRef.id,
        profileId: genericProfileId,
        eventId,
        horseId: genericHorseId,
        approved: true,
        fullName,
        category,
      };

      // Update the local state
      setEnrollments((prevEnrollments) => [...prevEnrollments, newEnrollment]);

      return newEnrollment;
    } finally {
      setIsCreating(false);
    }
  };

  const updateEnrollment = async (args: UpdateEnrollmentArgs) => {
    setIsUpdating(true);
    try {
      if (!user || userRole === "PARTICIPANT") {
        throw new Error("Apenas organizadores podem atualizar inscrições");
      }

      const db = getFirestore();
      const enrollmentRef = doc(db, "enrollments", args.enrollmentId);
      const enrollmentDoc = await getDoc(enrollmentRef);

      if (!enrollmentDoc.exists) {
        throw new Error("Inscrição não encontrada");
      }

      const enrollmentData = enrollmentDoc.data();
      if (!enrollmentData) {
        throw new Error("Inscrição não encontrada");
      }

      const updateData: Partial<UpdateEnrollmentArgs> = {};

      let updatedHorse: Horse | undefined;

      if (typeof args.horseId !== "undefined") {
        const horseDoc = await getDoc(doc(db, "horses", args.horseId));
        if (!horseDoc.exists) {
          throw new Error("Cavalo não encontrado");
        }
        updateData.horseId = args.horseId;
      }

      if (typeof args.approved !== "undefined") {
        updateData.approved = args.approved;
      }

      if (typeof args.isHorsConcours !== "undefined") {
        updateData.isHorsConcours = args.isHorsConcours;
      }

      if (typeof args.rank !== "undefined") {
        updateData.rank = args.rank;
      }

      if (typeof args.score !== "undefined") {
        updateData.score = args.score;
      }

      if (
        args.showjumpingScores?.time &&
        typeof args.showjumpingScores?.time !== "undefined"
      ) {
        updateData.time = args.showjumpingScores?.time;
      } else if (typeof args.time !== "undefined") {
        updateData.time = args.time;
      }

      if (
        args.showjumpingScores?.approximation &&
        typeof args.showjumpingScores?.approximation !== "undefined"
      ) {
        updateData.approximation = args.showjumpingScores?.approximation;
      } else if (typeof args.approximation !== "undefined") {
        updateData.approximation = args.approximation;
      }

      if (
        args.showjumpingScores?.faults &&
        typeof args.showjumpingScores?.faults !== "undefined"
      ) {
        updateData.faults = args.showjumpingScores?.faults;
      } else if (typeof args.faults !== "undefined") {
        updateData.faults = args.faults;
      }

      if (
        args.showjumpingScores?.penalties &&
        typeof args.showjumpingScores?.penalties !== "undefined"
      ) {
        updateData.timeFaultPoints = args.showjumpingScores?.penalties;
      } else if (typeof args.timeFaultPoints !== "undefined") {
        updateData.timeFaultPoints = args.timeFaultPoints;
      }

      if (
        args.showjumpingScores?.totalFaults &&
        typeof args.showjumpingScores?.totalFaults !== "undefined"
      ) {
        updateData.totalFaultPoints = args.showjumpingScores?.totalFaults;
      } else if (typeof args.totalFaultPoints !== "undefined") {
        updateData.totalFaultPoints = args.totalFaultPoints;
      }

      if (typeof args.isForfait !== "undefined") {
        updateData.isForfait = args.isForfait;
      }

      if (typeof args.isEliminated !== "undefined") {
        updateData.isEliminated = args.isEliminated;
      }

      if (typeof args.dressageScores !== "undefined") {
        updateData.dressageScores = args.dressageScores;
      }

      if (typeof args.showjumpingScores !== "undefined") {
        updateData.showjumpingScores = args.showjumpingScores;
      }

      await updateDoc(enrollmentRef, updateData);

      const existingEnrollment = enrollments.find(
        (enrollment) => enrollment.id === enrollmentDoc.id
      );

      const updatedEnrollment = {
        ...enrollmentData,
        id: enrollmentDoc.id,
        ...updateData,
        horse: updatedHorse || existingEnrollment.horse,
      };

      if (enrollments) {
        const updatedEnrollments = enrollments.map((enrollment) =>
          enrollment.id === updatedEnrollment.id
            ? {
                ...enrollment,
                ...updateData,
                horse: updatedHorse || existingEnrollment.horse,
              }
            : enrollment
        );

        setEnrollments(updatedEnrollments);
      }
      let notificationMessage: string;
      if (args.approved !== undefined) {
        notificationMessage = "Sua inscrição foi aprovada";
      } else if (args.horseId !== undefined) {
        notificationMessage = "Cavalo para a prova atualizado";
      } else if (args.dressageScores !== undefined) {
        notificationMessage =
          "Verifique o resultado da prova de adestramento no app";
      } else if (args.showjumpingScores !== undefined) {
        const { time, totalFaults, approximation } = args.showjumpingScores;
        const tempo = `tempo = ${time}; `;
        const faltas = `faltas = ${totalFaults}`;
        const aprox = `approx = ${approximation}; `;
        notificationMessage = `Resultados da prova atualizados: ${
          time !== undefined ? tempo : ""
        } ${approximation !== undefined ? aprox : ""} ${
          totalFaults !== undefined ? faltas : ""
        }`;
      } else {
        const tempo = `tempo = ${args.time};`;
        const faltas = `faltas = ${args.faults};`;
        const aprox = `approx = ${args.approximation};`;
        const total = `total = ${args.score};`;
        const classif = `classificação = ${args.rank};`;
        notificationMessage = `Resultados da prova atualizados: ${
          args.time !== undefined ? tempo : ""
        } ${args.approximation !== undefined ? aprox : ""} ${
          args.faults !== undefined ? faltas : ""
        } ${args.score !== undefined ? total : ""} ${
          args.rank !== undefined ? classif : ""
        }`;
      }

      try {
        const userProfileDoc = await getDoc(
          doc(db, "users", enrollmentData.profileId)
        );
        const userProfileData = userProfileDoc.data();

        if (userProfileData && userProfileData.expoPushToken) {
          await sendPushNotification(
            userProfileData.expoPushToken,
            "Inscrição atualizada",
            notificationMessage
          );
        }
      } catch (error) {
        console.error("Erro ao enviar notificação:", error);
      }

      return updatedEnrollment;
    } finally {
      setIsUpdating(false);
    }
  };

  const generateEntryOrderForModality = async (modalityId: string) => {
    if (!user) {
      throw new Error(
        "Você deve estar logado para gerar ordem de entrada para a categoria."
      );
    }
    setIsGeneratingEntryOrder(true);
    try {
      const db = getFirestore();
      const enrollmentSnapshot = await getDocs(
        query(
          collection(db, "enrollments"),
          where("modalityId", "==", modalityId),
          where("approved", "==", true)
        )
      );

      if (enrollmentSnapshot.empty) {
        throw new Error(`Nenhuma inscrição encontrada para o essa categoria`);
      }

      // Find the maximum entry order value among existing enrollments
      const maxExistingEntryOrder = enrollmentSnapshot.docs.reduce(
        (max, doc) => Math.max(max, parseInt(doc.data().entryOrder || "0", 10)),
        0
      );

      // Filter out enrollments that already have an entry order
      const enrollmentsWithoutEntryOrder = enrollmentSnapshot.docs.filter(
        (doc) => !doc.data().entryOrder
      );

      // Generate entry orders for the remaining enrollments, starting after the maximum existing entry order
      const entryOrderArray = Array.from(
        { length: enrollmentsWithoutEntryOrder.length },
        (_, i) => (i + maxExistingEntryOrder + 1).toString()
      );
      for (let i = entryOrderArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [entryOrderArray[i], entryOrderArray[j]] = [
          entryOrderArray[j],
          entryOrderArray[i],
        ];
      }

      // Update entry order for enrollments
      const updateBatch = writeBatch(db);
      const enrollmentIdToEntryOrderMap: Record<string, string> = {};
      enrollmentsWithoutEntryOrder.forEach((docSnapshot, index) => {
        const enrollmentId = docSnapshot.id;
        updateBatch.update(docSnapshot.ref, {
          entryOrder: entryOrderArray[index],
        });
        enrollmentIdToEntryOrderMap[enrollmentId] = entryOrderArray[index];
      });
      await updateBatch.commit();

      // Update entry order in the state using existing enrollments
      if (enrollments) {
        const updatedEnrollments = enrollments.map((enrollment) => {
          const newEntryOrder = enrollmentIdToEntryOrderMap[enrollment.id];
          if (newEntryOrder !== undefined) {
            return { ...enrollment, entryOrder: newEntryOrder };
          }
          return enrollment;
        });

        setEnrollments(updatedEnrollments);
        return updatedEnrollments;
      } else {
        throw new Error("Inscrição não encontrada");
      }
    } catch (error) {
      throw new Error("Erro ao gerar ordem de entrada.");
    } finally {
      setIsGeneratingEntryOrder(false);
    }
  };

  const handleUpdateEntryOrder = async (
    enrollmentId: string,
    newPosition: number,
    newData: Enrollment[]
  ) => {
    if (!user) {
      throw new Error(
        "Você deve estar logado para editar ordem de entrada para a categoria."
      );
    }
    setIsUpdatingEntryOrder(true);
    try {
      const db = getFirestore();
      const enrollmentCollection = collection(db, "enrollments");

      await runTransaction(db, async (transaction) => {
        newData.forEach(async (item, index) => {
          const docRef = doc(enrollmentCollection, item.id);
          transaction.update(docRef, { entryOrder: index + 1 });
        });
      });
      setEnrollments(newData);
    } catch (error) {
      console.error("Error updating entry order:", error);
    } finally {
      setIsUpdatingEntryOrder(false);
    }
  };

  const updateRanks = async (
    args: RankUpdateArgs[],
    isModTimeTrial: boolean
  ) => {
    if (!user) {
      throw new Error(
        "Você deve estar logado para editar ordem de entrada para a categoria."
      );
    }
    setIsRankUpdating(true);

    try {
      const db = getFirestore();

      // Create a batch to perform all updates atomically.
      const batch = writeBatch(db);

      const filteredArgs = args.filter((sectionArg) => {
        return (
          sectionArg.title !== "Hors Concours" &&
          sectionArg.title !== "Forfeit" &&
          sectionArg.title !== "Eliminados" &&
          sectionArg.title !== "Próximos"
        );
      });

      for (const sectionArgs of filteredArgs) {
        const { title, data } = sectionArgs;

        const sortedEnrollments = [...data].sort((a, b) => {
          // Sort by totalFaults first (ascending order)
          const totalFaultsDifference =
            (a.showjumpingScores?.totalFaults ?? 0) -
            (b.showjumpingScores?.totalFaults ?? 0);

          if (totalFaultsDifference !== 0) {
            return totalFaultsDifference;
          }

          // If totalFaults are the same, sort by approximation (ascending order)
          return (
            (isModTimeTrial
              ? a.showjumpingScores?.approximation ?? 0
              : a.showjumpingScores?.time ?? 0) -
            (isModTimeTrial
              ? b.showjumpingScores?.approximation ?? 0
              : b.showjumpingScores?.time ?? 0)
          );
        });

        // Loop through the sorted enrollments to assign ranks.
        let currentRank = 1;
        for (const [index, enrollment] of sortedEnrollments.entries()) {
          const enrollmentRef = doc(db, "enrollments", enrollment.id);

          const isSameRankAsPrevious =
            index > 0 &&
            sortedEnrollments[index].showjumpingScores?.totalFaults ===
              sortedEnrollments[index - 1].showjumpingScores?.totalFaults &&
            sortedEnrollments[index].showjumpingScores?.approximation ===
              sortedEnrollments[index - 1].showjumpingScores?.approximation;

          // If two enrollments have the same totalFaults and approximation, they will have the same rank.
          if (isSameRankAsPrevious) {
            batch.update(enrollmentRef, { rank: currentRank });
          } else {
            currentRank++;
            batch.update(enrollmentRef, { rank: currentRank });
          }

          // Update the local copy of this enrollment's rank
          const localEnrollment = enrollments.find(
            (e) => e.id === enrollment.id
          );
          if (localEnrollment) {
            localEnrollment.rank = currentRank;
          }
        }
      }

      // Commit the batch
      await batch.commit();

      if (enrollments) {
        setEnrollments([...enrollments]); // Assuming enrollments is a state variable
      }
    } catch (error) {
      console.error("Error updating ranks:", error);
      throw new Error("Não foi possível salvar a classificação");
    } finally {
      setIsRankUpdating(false);
    }
  };

  // async function createOrEditEntryOrder(
  //   enrollmentId: string,
  //   position: number
  // ): Promise<Enrollment[]> {
  //   const enrollmentIndex = enrollments.findIndex(
  //     (enrollment) => enrollment.id === enrollmentId
  //   );

  //   if (enrollmentIndex === -1) {
  //     throw new Error("Inscrição não encontrada");
  //   }

  //   let positionKey = position.toString();
  //   let suffixIndex = -1;
  //   setIsGeneratingEntryOrder(true);
  //   try {
  //     while (
  //       enrollments.some((enrollment) => enrollment.entryOrder === positionKey)
  //     ) {
  //       suffixIndex++;
  //       positionKey =
  //         position.toString() +
  //         (suffixIndex < 26 ? String.fromCharCode(65 + suffixIndex) : "");
  //     }

  //     const db = getFirestore();
  //     const enrollmentDocRef = doc(db, "enrollments", enrollmentId);

  //     await updateDoc(enrollmentDocRef, {
  //       entryOrder: positionKey,
  //     });

  //     const updatedEnrollments = enrollments.map((enrollment) =>
  //       enrollment.id === enrollmentId
  //         ? { ...enrollment, entryOrder: positionKey }
  //         : enrollment
  //     );
  //     return updatedEnrollments;
  //   } catch (error) {
  //     throw new Error("Erro ao gerar ordem de entrada.");
  //   } finally {
  //     setIsGeneratingEntryOrder(false);
  //   }
  // }

  return {
    enrollments,
    createEnrollment,
    updateEnrollment,
    isLoading,
    error,
    isCreating,
    isUpdating,
    isGeneratingEntryOrder,
    isUpdatingEntryOrder,
    fetchEnrollments,
    generateEntryOrderForModality,
    handleUpdateEntryOrder,
    fetchModalityEnrollments,
    // createOrEditEntryOrder,
    // handleUpdateModalityTime,
    createLastMinuteEnrollment,
    modalityUpdating,
    rankIsUpdating,
    updateRanks,
  };
};

export default useEnrollments;
