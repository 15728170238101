import React, { useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { ActionSheet } from "../ActionSheet/ActionSheet";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { useTheme } from "../../theme/ThemeContext";

type Option = {
  label: string;
  value: any;
};

type SelectProps = {
  options: Option[];
  placeholder: string;
  value: any;
  onValueChange: (value: any) => void;
};

export const Select: React.FC<SelectProps> = ({
  options,
  placeholder,
  value,
  onValueChange,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleActionSheet = () => {
    setIsVisible(!isVisible);
  };

  const handleSelect = (option: Option) => {
    onValueChange(option.value); // Using onValueChange here
    toggleActionSheet();
  };

  const selectedOption = options.find((opt) => opt.value === value);
  const displayLabel = selectedOption ? selectedOption.label : placeholder;
  const theme = useTheme();
  return (
    <View>
      <TouchableOpacity
        onPress={toggleActionSheet}
        style={styles.selectContainer}
      >
        <Text style={styles.selectText}>{displayLabel}</Text>
        <Icon name="menu-down" size={25} color={theme.colors.tertiary} />
      </TouchableOpacity>

      <ActionSheet
        isVisible={isVisible}
        onClose={toggleActionSheet}
        options={options}
        onSelect={handleSelect}
        selectedValue={value} // Passing selected value here
      />
    </View>
  );
};

const styles = StyleSheet.create({
  selectContainer: {
    borderColor: "#ccc",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderWidth: 1,
    padding: 8,
    borderRadius: 5,
    marginBottom: 10,
  },
  selectText: {
    fontSize: 14,
  },
});
