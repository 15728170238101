import { createContext, useContext, useState } from "react";
import { Horse } from "../types"; // Make sure to create a Horse type based on your schema

export type UserHorsesContextType = {
  userHorses: Horse[] | null;
  setUserHorses: (horses: Horse[] | null) => void;
};

export const UserHorsesContext = createContext<UserHorsesContextType>({
  userHorses: null,
  setUserHorses: () => {},
});

export const useUserHorsesContext = () => useContext(UserHorsesContext);
