import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PrivateWrapper from "./PrivateWrapper";
import DashboardWebV2 from "../screens/DashboardWeb/DashboardWebV2";
import EventManagementWeb from "../screens/EventManagementWeb/EventManagementWeb";
import WebLandingPage from "../screens/WebLandingPage/WebLandingPage";
import TermsAndConditionsScreen from "../screens/TermsAndConditionsScreen/TermsAndConditionsScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen/PrivacyPolicyScreen";
import DirectoryScreen from "../screens/DirectoryScreen/DirectoryScreen";
import EventScoringScreenWeb from "../screens/EventScoringScreen/EventScoringScreenWeb";

const useDefaultTitle = (defaultTitle: string) => {
  useEffect(() => {
    const titleObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (document.title === "undefined") {
          document.title = defaultTitle;
        }
      });
    });

    const head = document.querySelector("head");

    if (head) {
      titleObserver.observe(head, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      titleObserver.disconnect();
    };
  }, [defaultTitle]);
};

export const WebRouter = () => {
  useDefaultTitle("Striboss");
  return (
    <Router>
      <Routes>
        <Route path="/" element={<WebLandingPage />} />
        <Route path="/terms" element={<TermsAndConditionsScreen />} />
        <Route path="/privacy" element={<PrivacyPolicyScreen />} />
        <Route path="/diretorio" element={<DirectoryScreen />} />
        <Route
          path="/*"
          element={
            <PrivateWrapper>
              <Routes>
                <Route index element={<DashboardWebV2 />} />
                <Route path="/dashboard" element={<DashboardWebV2 />} />
                <Route
                  path="/evento/:eventId"
                  element={<EventManagementWeb />}
                />
                <Route
                  path="/prova/:eventId"
                  element={<EventScoringScreenWeb />}
                />
                <Route path="/*" element={<Navigate to="/" />} />
              </Routes>
            </PrivateWrapper>
          }
        />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};
