import React, { useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  Image,
} from "react-native";
import { Text, Avatar, Button } from "react-native-elements";
import { imageSources } from "../../assets/images/imageSources";
import { Event } from "../../types";
import useOrganizerEvents from "../../hooks/useOrganizerEvents";
import { useAuth } from "../../hooks/useAuth";
import useUserProfile from "../../hooks/useUserProfile";
import { useTheme } from "../../theme/ThemeContext";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { toDate } from "../../utils";
import { useNavigate } from "react-router-dom";
import { WebOrganizerNav } from "../../components/WebOrganizerNav/WebOrganizerNav";

const DashboardWebV2: React.FC = () => {
  // const generatedStyles = styles();
  const theme = useTheme();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [sectionedEvents, setSectionedEvents] = useState<
    Array<{ title: string; data: Event[] }>
  >([]);
  const { userProfile } = useUserProfile();
  const { logout } = useAuth();
  const { acronym } = userProfile || {};
  const initials = `${acronym ?? ""}`;
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);
  const { organizerEvents, loading, error } = useOrganizerEvents();
  const { width } = Dimensions.get("window");
  const isMobile = width <= 768;
  const navigate = useNavigate();

  const goToEventManagement = (eventId: string) => {
    navigate(`/evento/${eventId}`);
  };

  useEffect(() => {
    const getEventsByMonth = (): { [key: string]: Event[] } => {
      const groupedEvents: { [key: string]: Event[] } = {};

      organizerEvents?.forEach((event: Event) => {
        const eventDate = toDate(event.date);
        if (!eventDate) {
          return;
        }

        // Use the numerical date as the key for grouping
        const monthYearKey = `${eventDate.getFullYear()}-${eventDate.getMonth()}`;

        if (!groupedEvents[monthYearKey]) {
          groupedEvents[monthYearKey] = [];
        }

        const totalEntries = event.modalities.reduce(
          (sum, modality) => sum + (modality.enrollmentCount || 0),
          0
        );

        groupedEvents[monthYearKey].push({ ...event, totalEntries });
      });

      // Sort each group of events by date in descending order
      for (const [key, events] of Object.entries(groupedEvents)) {
        groupedEvents[key] = events.sort((a, b) => {
          const dateA = toDate(a.date);
          const dateB = toDate(b.date);
          if (dateA && dateB) {
            return dateB.getTime() - dateA.getTime();
          }
          return 0;
        });
      }

      return groupedEvents;
    };

    if (organizerEvents) {
      const groupedEvents = getEventsByMonth();

      // Sort group keys by date in descending order (which is now numerical)
      const sortedKeys = Object.keys(groupedEvents).sort((a, b) => {
        const [yearA, monthA] = a.split("-").map(Number);
        const [yearB, monthB] = b.split("-").map(Number);

        if (yearA < yearB) return 1;
        if (yearA > yearB) return -1;
        return monthB - monthA;
      });

      setSectionedEvents(
        sortedKeys.map((key) => {
          // Extract year and month from the key
          const [year, month] = key.split("-").map(Number);
          const date = new Date(year, month);

          // Convert to Portuguese month/year string
          const title = date.toLocaleString("pt-BR", {
            month: "long",
            year: "numeric",
          });

          return {
            title,
            data: groupedEvents[key],
          };
        })
      );
    }
  }, [organizerEvents]);

  const NavigationBar = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingVertical: 15,
          paddingHorizontal: 0,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
          padding: 15,
        }}
      >
        <View style={{ flex: 2, paddingLeft: 20 }}>
          <TouchableOpacity onPress={() => {}}>
            <Image
              source={imageSources.logoHorizontal}
              style={{ width: 196, height: 30 }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
        <Button
          title="Criar evento"
          onPress={() => {}}
          buttonStyle={{
            ...theme.buttonStyles.secondary,
          }}
          containerStyle={{ alignSelf: "flex-end", marginRight: 20 }}
          loading={false}
          icon={{
            name: "plus-circle",
            type: "material-community",
            size: 20,
            color: theme.colors.background,
          }}
        />
        <View>
          <TouchableOpacity style={{ marginRight: 20 }} onPress={toggleMenu}>
            <Avatar
              size={40}
              rounded
              title={initials}
              containerStyle={{
                backgroundColor: theme.colors.secondary,
                shadowColor: "#000",
                shadowOffset: { width: 0, height: 3 },
                shadowOpacity: 0.35,
                shadowRadius: 3.84,
              }}
              titleStyle={{ fontSize: 14, fontWeight: "700" }}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  if (isMobile) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          padding: 30,
        }}
      >
        <Image
          source={imageSources.logoVertical01}
          style={{ width: 146, height: 120 }}
        />
        <Text
          style={[
            theme.typography.h2,
            {
              textAlign: "center",
              marginTop: 20,
              color: theme.colors.secondary,
            },
          ]}
        >
          Baixe o nosso app pra usar as funcionalidades completas do Striboss no
          celular
        </Text>
        <View style={{ alignItems: "flex-end", margin: 20 }}>
          <TouchableOpacity
            onPress={logout}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Text
              style={[theme.typography.body, { color: theme.colors.grey2 }]}
            >
              Logout
            </Text>
            <Icon name="logout-variant" size={35} color={theme.colors.grey2} />
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  return (
    <>
      <WebOrganizerNav />
      <View
        style={{
          height: "90%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading ?? <LoadingIndicator />}
        {error ? <Text>Não foi possível detectar eventos</Text> : null}
        {sectionedEvents && sectionedEvents.length ? (
          <ScrollView>
            {sectionedEvents.map(({ title, data }) => (
              <View key={title}>
                <View style={{ alignItems: "center", marginTop: 10 }}>
                  <Text style={theme.typography.h2}>{title}</Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      width: width,
                      padding: 10,
                    }}
                  >
                    {/* <Icon
                      name="arrow-left-bold-circle"
                      size={25}
                      color={theme.colors.grey3}
                    /> */}
                    <ScrollView horizontal>
                      {data.map((event: Event) => (
                        <TouchableOpacity
                          style={{
                            width: 200,
                            minHeight: 150,
                            flexDirection: "column",
                            justifyContent: "space-between",
                            backgroundColor: theme.colors.background,
                            borderRadius: 10,
                            margin: 10,
                            shadowColor: "#000",
                            shadowOffset: { width: 3, height: 3 },
                            shadowOpacity: 0.1,
                            shadowRadius: 3.84,
                          }}
                          key={event.id}
                          onPress={() => goToEventManagement(event.id)}
                        >
                          {/* Event Date */}
                          <View
                            style={{
                              backgroundColor: theme.colors.tertiary,
                              borderTopEndRadius: 10,
                              borderTopStartRadius: 10,
                              padding: 10,
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={[
                                theme.typography.body,
                                { color: theme.colors.white },
                              ]}
                            >
                              {event?.date
                                ? toDate(event.date)?.toLocaleDateString(
                                    "pt-BR",
                                    {
                                      day: "numeric",
                                      month: "numeric",
                                      year: "numeric",
                                    }
                                  )
                                : null}
                            </Text>
                          </View>

                          {/* Event Title */}
                          <View style={{ padding: 10, alignItems: "center" }}>
                            <Text
                              style={[
                                theme.typography.h3,
                                { marginBottom: 5, textAlign: "center" },
                              ]}
                            >
                              {event.title}
                            </Text>
                          </View>

                          {/* Additional Info */}
                          <View
                            style={{
                              padding: 10,
                            }}
                          >
                            <Text style={theme.typography.small}>
                              Local: {event.location}
                            </Text>
                            <Text style={theme.typography.small}>
                              Inscrições: {event.totalEntries}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      ))}
                    </ScrollView>
                    {/* <Icon
                      name="arrow-right-bold-circle"
                      size={25}
                      color={theme.colors.grey3}
                    /> */}
                  </View>
                </View>
              </View>
            ))}
          </ScrollView>
        ) : null}
      </View>
    </>
  );
};

export default DashboardWebV2;
