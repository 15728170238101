import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Modal,
  ScrollView,
} from "react-native";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { useTheme } from "../../theme/ThemeContext";

type Option = {
  label: string;
  value: any;
};

type ActionSheetProps = {
  isVisible: boolean;
  onClose: () => void;
  options: Option[];
  onSelect: (option: Option) => void;
  selectedValue: any;
};
export const ActionSheet: React.FC<ActionSheetProps> = ({
  isVisible,
  onClose,
  options,
  onSelect,
  selectedValue,
}) => {
  const theme = useTheme();

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <TouchableOpacity
        style={styles.centeredView}
        onPress={onClose}
        activeOpacity={1}
      >
        <View
          onStartShouldSetResponder={() => true}
          style={styles.modalContainer}
        >
          <TouchableOpacity
            style={{ alignItems: "flex-end" }}
            onPress={onClose}
          >
            <Icon
              name="close-circle"
              size={25}
              color={theme.colors.tertiary}
              onPress={onClose}
            />
          </TouchableOpacity>
          <ScrollView
            style={{ height: "70%" }}
            showsVerticalScrollIndicator={true}
          >
            {options.map((option, index) => (
              <TouchableOpacity key={index} onPress={() => onSelect(option)}>
                <View style={styles.optionContainer}>
                  <Text style={styles.optionText}>{option.label}</Text>
                  {option.value === selectedValue && (
                    <Icon
                      name="check-circle-outline"
                      size={25}
                      color={theme.colors.secondary}
                    />
                  )}
                </View>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "flex-end",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContainer: {
    backgroundColor: "white",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 20,
  },
  optionText: {
    fontSize: 16,
    paddingVertical: 10,
  },
  cancelText: {
    fontSize: 16,
    paddingVertical: 10,
    color: "red",
  },
  optionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 10,
  },
  checkmark: {
    fontSize: 16,
  },
});
