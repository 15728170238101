import React, { useState } from "react";
import { View, TouchableOpacity, Text } from "react-native";
import { FormControl, Input, WarningOutlineIcon } from "native-base";
import { Modal, Select } from "../../CorcelDesign";
import { useTheme } from "../../theme/ThemeContext";
import {
  Enrollment,
  FinalShowjumpingScores,
  ModalityKeys,
  Modality,
} from "../../types";
import { useSnackbar } from "../../context/SnackbarContext";

type NewLastMinuteEnrollmentModalProps = {
  modality: Modality;
  eventId?: string;
  isCreating: boolean;
  isVisible: boolean;
  onClose: () => void;
  createLastMinuteEnrollment: ({
    eventId,
    modalityId,
    fullName,
    category,
  }: {
    eventId?: string;
    category: string | null;
    modalityId: string;
    fullName: string;
  }) => Promise<{
    id: string;
    profileId: string;
    eventId: string;
    horseId: string;
    approved: boolean;
    fullName: string;
    category: string | null;
  }>;
};

export const NewLastMinuteEnrollmentModal: React.FC<
  NewLastMinuteEnrollmentModalProps
> = ({
  modality,
  eventId,
  createLastMinuteEnrollment,
  isCreating,
  onClose,
  isVisible,
}) => {
  const modalityId = modality?.id;
  const [fullName, setFullName] = useState("");
  const [category, setCategory] = useState<string | null>(null);
  const { showMessage } = useSnackbar();

  const clear = () => {
    setFullName("");
    setCategory(null);
  };

  const theme = useTheme();

  const handleCreateEnrollment = async () => {
    try {
      const result = await createLastMinuteEnrollment({
        eventId,
        modalityId,
        fullName,
        category,
      });
      showMessage("Inscrições atualizadas com sucesso", "success");
      onClose();
      clear();
    } catch (error) {
      showMessage("Não foi possível atualizar inscrições", "error");
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      buttonText="Salvar"
      onClose={() => {
        onClose();
        clear();
      }}
      onSubmit={handleCreateEnrollment}
      title="Adicionar inscrição de última hora"
      submitLoading={isCreating}
    >
      <View style={{ marginBottom: 20 }}>
        <Input
          variant="outline"
          size="md"
          placeholder="Nome completo"
          value={fullName}
          onChangeText={setFullName}
        />
      </View>

      <Select
        options={
          modality.categories?.map((cat: string, i: number) => ({
            label: cat,
            value: cat,
          })) ?? []
        }
        placeholder="Categoria"
        value={category}
        onValueChange={setCategory}
      />
    </Modal>
  );
};
