// src/hooks/useOrganizerEvents.ts;

import { useState, useEffect } from "react";
import { useOrganizerEventsContext } from "../context/OrganizerEventsContext";
import { Event, EventType, Modality, User, Enrollment, Horse } from "../types";
import { useAuth } from "./useAuth";
import { EventFormValues } from "../components/EventForm/EventForm";
import { useUserProfileContext } from "../context/UserProfileContext";
import {
  getFirestore,
  onSnapshot,
  collection,
  doc,
  query,
  where,
  getDocs,
  getDoc,
  orderBy,
  addDoc,
  updateDoc,
  writeBatch,
  QuerySnapshot,
  Query,
  DocumentData,
} from "firebase/firestore";
import {
  createModality,
  updateModality,
  deleteModality,
  updateIndividualModality,
} from "../firebaseStandaloneFunctions/handleModalities";
import { ModalityFormValues } from "../components/ModalityForm/ModalityForm";

export interface UseOrganizerEventsData {
  organizerEvents: Event[] | null;
  loading: boolean;
  error: Error | null;
  createEvent: (event: EventFormValues) => Promise<void>;
  updateEvent: (
    event: Partial<EventFormValues> & { id: string }
  ) => Promise<Event | null>;
  deleteEvent: (eventId: string) => Promise<void>;
  fetchParticipantEvents: (userUid: string) => Promise<null | undefined>;
  createLoading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  modalityLoading: boolean;
  handleUpdateModality: ({
    id,
    values,
  }: {
    id: string;
    values: ModalityFormValues;
  }) => Promise<ModalityFormValues>;
  handleDeleteModality: (
    id: string
  ) => Promise<{ id: string; name: string; eventId: string }>;
  fetchEventEnrollments: (eventId: string) => Promise<Enrollment[] | null>;
  enrollmentsLoading: boolean;
}

interface FirestoreModality extends ModalityFormValues {
  id: string;
  eventId: string;
}

const mapEventFormValuesToEvent = (
  formValues: EventFormValues,
  eventType: EventType,
  modalities: Modality[]
): Event => {
  return {
    id: formValues.id || "",
    title: formValues.title,
    description: formValues.description,
    date: formValues.date,
    endDate: formValues.endDate,
    enrollmentDateLimit: formValues.enrollmentDateLimit,
    price: formValues.price,
    location: formValues.location,
    eventTypeId: formValues.eventTypeId,
    eventType: eventType,
    modalities: modalities,
    organizerName: formValues.organizerName,
  };
};

const useOrganizerEvents = (): UseOrganizerEventsData => {
  const { organizerEvents, setOrganizerEvents } = useOrganizerEventsContext();
  const [loading, setLoading] = useState(true);
  const [modalityLoading, setModalityLoading] = useState(false);
  const [enrollmentsLoading, setEnrollmentsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { user, userRole } = useAuth();
  const { userProfile } = useUserProfileContext();
  const firestore = getFirestore();

  const fetchEnrollmentCount = async (modalityId: string) => {
    const enrollmentsSnapshot = await getDocs(
      query(
        collection(firestore, "enrollments"),
        where("modalityId", "==", modalityId)
      )
    );
    return enrollmentsSnapshot.size;
  };

  const fetchParticipantEvents = async (userUid: string) => {
    const enrollmentsQuery = query(
      collection(firestore, "enrollments"),
      where("profileId", "==", userUid)
    );
    const enrollmentsSnapshot = await getDocs(enrollmentsQuery);
    const eventIds = Array.from(
      new Set(enrollmentsSnapshot.docs.map((doc) => doc.data().eventId))
    );

    if (eventIds.length === 0) {
      setOrganizerEvents(null);
      setLoading(false);
      return null;
    }

    // Split eventIds into chunks of 10
    const chunks = [];
    for (let i = 0; i < eventIds.length; i += 10) {
      chunks.push(eventIds.slice(i, i + 10));
    }

    // Perform a separate query for each chunk and merge the results
    const querySnapshots = await Promise.all(
      chunks.map((chunk) =>
        getDocs(
          query(collection(firestore, "events"), where("__name__", "in", chunk))
        )
      )
    );

    const allDocs = querySnapshots.flatMap((snapshot) => snapshot.docs);

    // Process events
    const eventsPromises = allDocs.map(processEvent);
    const fetchedEvents = await Promise.all(eventsPromises);
    const events = fetchedEvents.filter(Boolean) as Event[];

    setOrganizerEvents(events.length > 0 ? events : null);
    setLoading(false);
    setError(null);
  };

  const fetchOrganizerEvents = async (userUid: string) => {
    const eventsQuery = query(
      collection(firestore, "events"),
      where("organizerId", "==", userUid),
      orderBy("date", "desc")
    );

    return eventsQuery;
  };

  const processEvent = async (docSnapshot: any) => {
    const eventData = docSnapshot.data() as Event;
    if (!eventData) {
      return null;
    }
    const eventTypeRef = doc(firestore, "eventTypes", eventData.eventTypeId);
    const eventTypeSnapshot = await getDoc(eventTypeRef);
    const eventType = eventTypeSnapshot.data() as EventType | null;

    if (!eventType) {
      throw new Error(`EventType not found for ID: ${eventData.eventTypeId}`);
    }

    // Fetch event modalities
    const modalitiesSnapshot = await getDocs(
      query(
        collection(firestore, "modalities"),
        orderBy("name", "desc"),
        where("eventId", "==", docSnapshot.id)
      )
    );

    // Fetch enrollment counts and map modalities
    const modalitiesPromises = modalitiesSnapshot.docs.map(async (doc) => {
      const data = doc.data() as Modality;
      const enrollmentCount = await fetchEnrollmentCount(doc.id);
      return { ...data, id: doc.id, enrollmentCount };
    });

    const modalities = await Promise.all(modalitiesPromises);

    const organizerRef = doc(
      collection(firestore, "users"),
      eventData.organizerId
    );
    const organizerSnapshot = await getDoc(organizerRef);
    const organizer = organizerSnapshot.data() as User;

    if (!organizer) {
      throw new Error(`Organizer not found for ID: ${eventData.organizerId}`);
    }

    return {
      ...eventData,
      id: docSnapshot.id,
      eventType,
      modalities,
      organizer,
    };
  };

  const fetchEvents = async (eventsQuery: Query<DocumentData> | null) => {
    if (!eventsQuery) {
      return;
    }

    const unsubscribe = onSnapshot(
      eventsQuery,
      async (querySnapshot: QuerySnapshot<DocumentData>) => {
        if (querySnapshot.empty) {
          setOrganizerEvents(null);
          setLoading(false);
          return;
        }

        const eventsPromises = querySnapshot.docs.map(processEvent);
        const fetchedEvents = await Promise.all(eventsPromises);
        const events = fetchedEvents.filter(Boolean) as Event[];

        setOrganizerEvents(events.length > 0 ? events : null);
        setLoading(false);
        setError(null);
      },
      (error: Error) => {
        setError(error);
        setLoading(false);
        console.log("organizer events", error);
      }
    );

    return () => {
      unsubscribe();
    };
  };

  useEffect(() => {
    if (user && userRole) {
      if (userRole === "PARTICIPANT") {
        fetchParticipantEvents(user.uid);
      } else {
        const fetchUserEvents = async () => {
          const eventsQuery = await fetchOrganizerEvents(user.uid);
          const unsubscribe = await fetchEvents(eventsQuery);
          return unsubscribe;
        };

        const unsubscribePromise = fetchUserEvents();
        return () => {
          unsubscribePromise.then((unsubscribe) => {
            if (unsubscribe) {
              unsubscribe();
            }
          });
        };
      }
    }
  }, [user, firestore, userRole]);

  const createEvent = async (event: EventFormValues) => {
    setCreateLoading(true);
    try {
      if (user) {
        const { organizerName } = event;
        const eventTypeRef = doc(firestore, "eventTypes", event.eventTypeId);
        const eventTypeSnapshot = await getDoc(eventTypeRef);
        const eventType = eventTypeSnapshot.data() as EventType | null;

        if (!eventType) {
          throw new Error(`EventType not found for ID: ${event.eventTypeId}`);
        }

        const { modalities, ...eventWithoutModalities } = event;

        const eventRef = await addDoc(collection(firestore, "events"), {
          ...eventWithoutModalities,
          organizerId: user.uid,
        });

        let modalitiesDocs: Modality[] = [];
        if (event.modalities) {
          modalitiesDocs = await Promise.all(
            event.modalities.map(async (modality) => {
              const docRef = await addDoc(collection(firestore, "modalities"), {
                ...modality,
                eventId: eventRef.id,
                organizerName: organizerName ?? userProfile?.companyName,
              });
              return { ...modality, id: docRef.id, eventId: eventRef.id };
            })
          );
        }

        const newEvent = mapEventFormValuesToEvent(
          event,
          eventType,
          modalitiesDocs
        );

        setOrganizerEvents([...(organizerEvents || []), newEvent]);
      } else {
        throw new Error("User not authenticated");
      }
    } finally {
      setCreateLoading(false);
    }
  };

  const updateEvent = async (
    event: Partial<EventFormValues> & { id: string }
  ): Promise<Event | null> => {
    setUpdateLoading(true);

    try {
      if (user) {
        if (userRole === "PARTICIPANT") {
          throw new Error("Only organizers can update events");
        }

        const eventRef = doc(firestore, "events", event.id);
        const eventData = (await getDoc(eventRef)).data() as Event;

        if (!eventData) {
          throw new Error("Event not found");
        }

        if (eventData.organizerId !== user.uid) {
          throw new Error("Organizers can only update their own events");
        }

        const { modalities, ...eventWithoutModalities } = event;
        await updateDoc(eventRef, eventWithoutModalities);

        if (Array.isArray(modalities)) {
          // Update existing modalities or add new ones
          const updatedModalities: FirestoreModality[] = await Promise.all(
            modalities.map(async (modality: ModalityFormValues) => {
              if (modality.id) {
                // Modality exists, so update it
                const modalityRef = doc(firestore, "modalities", modality.id);
                await updateDoc(modalityRef, modality as { [x: string]: any });
              } else {
                // Modality does not exist, so add it
                const modalityRef = await addDoc(
                  collection(firestore, "modalities"),
                  {
                    ...modality,
                    eventId: eventRef.id,
                    organizerName: userProfile?.companyName,
                  }
                );
                modality.id = modalityRef.id; // Assign the new modality ID
              }
              modality.eventId = modality.eventId || eventRef.id;
              return modality as FirestoreModality;
            })
          );

          const updatedEvent: Event = {
            ...eventData,
            ...event,
            modalities: updatedModalities,
          };

          setOrganizerEvents(
            organizerEvents?.map((existingEvent) =>
              existingEvent.id === updatedEvent.id
                ? updatedEvent
                : existingEvent
            ) || []
          );

          return updatedEvent;
        }

        return null;
      } else {
        throw new Error("User not authenticated");
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  const deleteEvent = async (eventId: string) => {
    setDeleteLoading(true);
    try {
      if (user) {
        const eventRef = doc(firestore, "events", eventId);

        const enrollmentsSnapshot = await getDocs(
          query(
            collection(firestore, "enrollments"),
            where("eventId", "==", eventId)
          )
        );

        // Check if there are any enrollments with the event's ID
        const hasEnrollments = enrollmentsSnapshot.docs.length > 0;

        if (hasEnrollments) {
          throw new Error(
            "Não é possível deletar evento com inscrições ativas"
          );
        }

        // Delete the related modalities
        const modalitiesSnapshot = await getDocs(
          query(
            collection(firestore, "modalities"),
            where("eventId", "==", eventId)
          )
        );

        const deleteBatch = writeBatch(firestore);

        // Delete the related modalities
        modalitiesSnapshot.docs.forEach((doc) => {
          deleteBatch.delete(doc.ref);
        });

        // Delete the event
        deleteBatch.delete(eventRef);
        await deleteBatch.commit();

        setOrganizerEvents(
          organizerEvents?.filter((event) => event.id !== eventId) || []
        );
      } else {
        throw new Error("User not authenticated");
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleUpdateModality = async ({
    id,
    values,
  }: {
    id: string;
    values: ModalityFormValues;
  }) => {
    setModalityLoading(true);
    try {
      if (user) {
        const result = await updateIndividualModality({
          firestore,
          user,
          id,
          userRole,
          values,
        });

        if (organizerEvents) {
          const {
            type,
            trackType,
            description,
            velocity,
            distance,
            numJumps,
            date,
            time,
            numCompetitions,
            categories,
            name,
            manualAllottedTime,
            penaltyPointsPerSecond,
            jumpsArray,
          } = values;

          const updatedOrganizerEvents = organizerEvents.map((event) => {
            if (event.modalities.some((mod) => mod.id === id)) {
              const updatedFields = {
                ...(type && { type }),
                ...(trackType && { trackType }),
                ...(description && { description }),
                ...(velocity && { velocity }),
                ...(distance && { distance }),
                ...(numJumps && { numJumps }),
                ...(date && { date }),
                ...(time && { time }),
                ...(numCompetitions && { numCompetitions }),
                ...(categories && { categories }),
                ...(name && { name }),
                ...(manualAllottedTime && { manualAllottedTime }),
                ...(penaltyPointsPerSecond !== undefined
                  ? { penaltyPointsPerSecond }
                  : {}),
                ...(jumpsArray?.length && { jumpsArray }),
              };
              return {
                ...event,
                modalities: event.modalities.map((mod) =>
                  mod.id === id ? { ...mod, ...updatedFields } : mod
                ),
              };
            }
            return event;
          });

          setOrganizerEvents(updatedOrganizerEvents);
        }

        setModalityLoading(false);
        return result;
      } else {
        throw new Error("Usuário não autentificado");
      }
    } catch (err) {
      setModalityLoading(false);
      if (err instanceof Error) {
        throw new Error(err.message);
      } else {
        throw new Error("Ocorreu um erro desconhecido");
      }
    } finally {
      setModalityLoading(false);
    }
  };

  const handleDeleteModality = async (id: string) => {
    setModalityLoading(true);
    try {
      if (user) {
        const result = await deleteModality(firestore, user, id, userRole);

        // Update the state
        if (organizerEvents) {
          const updatedOrganizerEvents = organizerEvents.map((event) => {
            if (event.modalities.some((modality) => modality.id === id)) {
              return {
                ...event,
                modalities: event.modalities.filter(
                  (modality) => modality.id !== id
                ),
              };
            }
            return event;
          });
          setOrganizerEvents(updatedOrganizerEvents);
        }
        setModalityLoading(false);
        return result;
      } else {
        throw new Error("User not authenticated");
      }
    } catch (err) {
      setModalityLoading(false);
      if (err instanceof Error) {
        throw new Error(err.message);
      } else {
        throw new Error("An unknown error occurred");
      }
    } finally {
      setModalityLoading(false);
    }
  };

  const fetchEventEnrollments = async (eventId: string) => {
    setEnrollmentsLoading(true);
    try {
      const enrollmentsQuery = query(
        collection(firestore, "enrollments"),
        where("eventId", "==", eventId)
      );

      const enrollmentsSnapshot = await getDocs(enrollmentsQuery);

      if (enrollmentsSnapshot.empty) {
        console.log("No enrollments found for this event");
        return null;
      }

      const enrollmentsWithAdditionalData = await Promise.all(
        enrollmentsSnapshot.docs.map(async (enrollmentDoc) => {
          const enrollmentData = enrollmentDoc.data() as Enrollment;

          // Fetch Horse Data
          const horseDocRef = enrollmentData.horseId
            ? doc(firestore, "horses", enrollmentData.horseId)
            : null;
          const horseDoc = horseDocRef
            ? await getDoc(horseDocRef).catch((error) => {
                console.error(`Error fetching horse document`, error);
                return null;
              })
            : null;

          // Fetch Profile Data to get EntityId
          const profileDocRef = doc(
            firestore,
            "users",
            enrollmentData.profileId
          );
          const profileDoc = await getDoc(profileDocRef).catch((error) => {
            console.error(`Error fetching profile document`, error);
            return null;
          });
          const profileData = profileDoc?.data();

          // Fetch Entity Data based on EntityId to get acronym
          const entityId = profileData?.entityId;
          const entityDocRef = entityId
            ? doc(firestore, "users", entityId)
            : null;
          const entityDoc = entityDocRef
            ? await getDoc(entityDocRef).catch((error) => {
                console.error(`Error fetching entity document`, error);
                return null;
              })
            : null;

          return {
            ...enrollmentData,
            id: enrollmentDoc.id,
            horse: horseDoc?.exists()
              ? { ...(horseDoc.data() as Horse), id: horseDoc.id }
              : undefined,
            entity:
              entityDoc && entityDoc.exists()
                ? { ...(entityDoc.data() as User), id: entityDoc.id }
                : null,
            profile:
              profileDoc && profileDoc.exists()
                ? {
                    ...(profileDoc?.data() as User),
                    id: profileDoc.id,
                  }
                : undefined,
          };
        })
      );

      return enrollmentsWithAdditionalData;
    } catch (err) {
      setEnrollmentsLoading(false);
      if (err instanceof Error) {
        throw new Error(err.message);
      } else {
        throw new Error("An unknown error occurred");
      }
    } finally {
      setEnrollmentsLoading(false);
    }
  };

  return {
    organizerEvents,
    loading,
    error,
    createEvent,
    updateEvent,
    deleteEvent,
    createLoading,
    updateLoading,
    deleteLoading,
    modalityLoading,
    handleUpdateModality,
    handleDeleteModality,
    fetchParticipantEvents,
    fetchEventEnrollments,
    enrollmentsLoading,
  };
};

export default useOrganizerEvents;
