import React, { useEffect, useState } from "react";
import { Input, CheckBox } from "react-native-elements";
import { View, Text, FlatList } from "react-native";
import { Event, Modality, ModalityType, ModalityKeys } from "../../types";
import { ModalityFormValues } from "../ModalityForm/ModalityForm";
import { useTheme } from "../../theme/ThemeContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { computeModalityTimes, sanitizeNumericInput } from "../../utils";
import { Modal } from "../../CorcelDesign";
import { useNavigation } from "@react-navigation/native";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

type Props = {
  modality: Modality;
  event: Event;
  isVisible: boolean;
  modalityLoading: boolean;
  onClose: () => void;
  handleUpdateModality: ({
    id,
    values,
  }: {
    id: string;
    values: ModalityFormValues;
  }) => Promise<void>;
};

export const ModalityConfigModal: React.FC<Props> = ({
  modality,
  event,
  isVisible,
  onClose,
  handleUpdateModality,
  modalityLoading,
}) => {
  const [distance, setDistance] = useState<string | undefined>();
  const [numJumps, setNumJumps] = useState<string | undefined>();
  const [velocity, setVelocity] = useState<string | undefined>();
  const [penaltyPointsPerSecond, setPenaltyPointsPerSecond] = useState<
    string | undefined
  >();
  const [type, setType] = useState<ModalityType>();

  const [calculateOrInsertTime, setCalculateOrInsertTime] = useState(0);
  const theme = useTheme();
  const navigation = useNavigation();
  const { showMessage } = useSnackbar();
  const [jumpsArray, setJumpsArray] = useState<string[]>([]);
  const [manualTime, setManualTime] = useState<string | undefined>();

  const handleManualTimeChange = (time: string) => {
    setManualTime(time);
  };

  const onReset = () => {
    setDistance(
      !isNaN(Number(modality.distance))
        ? modality.distance?.toString()
        : undefined
    );
    setVelocity(
      !isNaN(Number(modality.velocity))
        ? modality.velocity?.toString()
        : undefined
    );
    setNumJumps(
      !isNaN(Number(modality.numJumps))
        ? modality.numJumps?.toString()
        : undefined
    );
    setManualTime(
      !isNaN(Number(modality.manualAllottedTime))
        ? modality.manualAllottedTime?.toString()
        : undefined
    );
    setPenaltyPointsPerSecond(
      !isNaN(Number(modality.penaltyPointsPerSecond))
        ? modality.penaltyPointsPerSecond?.toString()
        : undefined
    );
    setCalculateOrInsertTime(modality.manualAllottedTime ? 1 : 0);
    setType(modality.type || ModalityKeys.TIME_TRIAL);
    setJumpsArray(
      modality.jumpsArray && modality.jumpsArray?.length === modality.numJumps
        ? modality.jumpsArray
        : Array.from({ length: Number(modality.numJumps) ?? 0 }, (_, i) => "")
    );
  };

  useEffect(() => {
    // Reset velocity and distance to undefined if the user chooses to insert time manually
    if (calculateOrInsertTime === 1) {
      setVelocity(undefined);
      setDistance(undefined);
    }
    if (calculateOrInsertTime === 0) {
      setManualTime(undefined);
    }
  }, [calculateOrInsertTime]);

  useEffect(() => {
    if (modality) {
      onReset();
    }
  }, [modality]);

  const handleJumpNameChange = (index: number, value: string) => {
    const newJumpsArray = [...jumpsArray];
    newJumpsArray[index] = value;
    setJumpsArray(newJumpsArray);
  };

  const handleNumJumpsChange = (value: string) => {
    setNumJumps(value);
    const newLength = Number(value);
    if (newLength >= 0) {
      setJumpsArray((prevArray) => {
        const newArray = new Array(newLength).fill("");

        return newArray;
      });
    }
  };

  const { allottedTime, idealTime } = computeModalityTimes(
    distance ?? "",
    velocity ?? ""
  );

  const handleEdit = async () => {
    try {
      const sanitizedVelocity = sanitizeNumericInput(velocity ?? "");
      const sanitizedDistance = sanitizeNumericInput(distance ?? "");
      const sanitizedNumJumps = sanitizeNumericInput(numJumps ?? "");
      const sanitizedManualTime = sanitizeNumericInput(manualTime ?? "");
      const sanitizedPenaltyPointsPerSecond = sanitizeNumericInput(
        penaltyPointsPerSecond ?? ""
      );
      const { id, type } = modality ?? {};

      if (id) {
        await handleUpdateModality({
          id,
          values: {
            type: type,
            velocity: Number(sanitizedVelocity),
            distance: Number(sanitizedDistance),
            numJumps: Number(sanitizedNumJumps),
            manualAllottedTime: Number(sanitizedManualTime),
            penaltyPointsPerSecond: sanitizedPenaltyPointsPerSecond,
            jumpsArray,
          },
        });
        showMessage("Categoria atualizada", "success");
        onClose();
      }
    } catch (err) {
      if (err instanceof Error) {
        showMessage(err.message, "error");
      } else {
        showMessage("Erro ao atualizar categoria", "error");
      }
    }
  };

  if (modalityLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      isVisible={isVisible}
      buttonText="Salvar"
      onClose={() => {
        onClose();
        onReset();
      }}
      onSubmit={handleEdit}
      title="Configurar prova"
      submitLoading={modalityLoading}
    >
      <View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <CheckBox
            title="Calcular Tempo"
            checked={calculateOrInsertTime === 0}
            onPress={() => setCalculateOrInsertTime(0)}
            containerStyle={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              marginLeft: 0,
              marginRight: 0,
              padding: 0,
            }}
            size={30}
          />
          <CheckBox
            title="Inserir tempo manualmente"
            checked={calculateOrInsertTime === 1}
            onPress={() => setCalculateOrInsertTime(1)}
            containerStyle={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              marginLeft: 0,
              marginRight: 0,
              padding: 0,
            }}
            size={30}
          />
        </View>
        {calculateOrInsertTime === 0 && (
          <>
            <Input
              label="Velocidade (m/m)"
              value={velocity}
              onChangeText={setVelocity}
              keyboardType="decimal-pad"
              inputMode="decimal"
            />
            <Input
              label="Extensão do percurso (m)"
              value={distance}
              onChangeText={setDistance}
              keyboardType="decimal-pad"
              inputMode="decimal"
            />
          </>
        )}
        {calculateOrInsertTime === 1 && (
          <Input
            label="Tempo concedido manual (s)"
            value={manualTime}
            onChangeText={handleManualTimeChange}
            keyboardType="decimal-pad"
            inputMode="decimal"
          />
        )}
        <Input
          label="Penalidades por segundo (pontos)"
          value={penaltyPointsPerSecond}
          onChangeText={setPenaltyPointsPerSecond}
          keyboardType="decimal-pad"
          inputMode="decimal"
        />
        <Input
          label="Quantidade de Esforços"
          value={numJumps}
          onChangeText={handleNumJumpsChange}
          keyboardType="decimal-pad"
          inputMode="decimal"
        />
        <FlatList
          data={jumpsArray}
          //   horizontal
          contentContainerStyle={{
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <Input
              value={item}
              onChangeText={(value) => handleJumpNameChange(index, value)}
              style={{ width: 75 }}
              inputContainerStyle={{
                borderWidth: 1,
                borderRadius: 4,
                borderColor: "#ccc",
                paddingLeft: 8,
                paddingRight: 8,
              }}
            />
          )}
        />
        {calculateOrInsertTime === 0 && (
          <Text style={theme.typography.body}>
            Tempo concedido: {allottedTime} | Tempo ideal: {idealTime}
          </Text>
        )}
      </View>
    </Modal>
  );
};
