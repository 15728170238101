// App.tsx
import "react-native-gesture-handler";
import React, { useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { AuthProvider } from "./src/context/AuthContext";
import { EntitiesProvider } from "./src/context/EntitiesContext";
import { DressageReprisesProvider } from "./src/context/DressageReprisesContext";
import { ThemeProvider } from "./src/theme/ThemeContext";
import SnackbarContext from "./src/context/SnackbarContext";
import { Snackbar } from "react-native-paper";
import { useAuth } from "./src/hooks/useAuth";
import { getApps, initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { UserProfileContext } from "./src/context/UserProfileContext";
import { EnrollmentsProvider } from "./src/context/EnrollmentsContext";
import { UserHorsesContext } from "./src/context/UserHorsesContext";
import { OrganizerEventsContext } from "./src/context/OrganizerEventsContext";
import { AllEventsProvider } from "./src/context/AllEventsContext";
import { EventTypesContext } from "./src/context/EventTypesContext";
import { NativeBaseProvider } from "native-base";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { WebRouter } from "./src/navigation/WebRouter";

const App = () => {
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [userProfile, setUserProfile] = React.useState<any>(null);
  const [eventTypes, setEventTypes] = React.useState<any>(null);
  const [userHorses, setUserHorses] = React.useState<any>(null);
  const [organizerEvents, setOrganizerEvents] = React.useState<any>(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState<"success" | "error">(
    "success"
  );

  const auth = useAuth();
  if (!getApps().length) {
    initializeApp(firebaseConfig);
  }

  const showMessage = (
    message: string,
    type: "success" | "error" = "success"
  ) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarVisible(true);
  };

  const dismissSnackbar = () => setSnackbarVisible(false);

  const backgroundColor = snackbarType === "success" ? "green" : "red";

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider>
        <NativeBaseProvider>
          <NavigationContainer>
            <ThemeProvider>
              <AuthProvider>
                <EntitiesProvider>
                  <DressageReprisesProvider>
                    <EventTypesContext.Provider
                      value={{ eventTypes, setEventTypes }}
                    >
                      <UserProfileContext.Provider
                        value={{ userProfile, setUserProfile }}
                      >
                        <OrganizerEventsContext.Provider
                          value={{ organizerEvents, setOrganizerEvents }}
                        >
                          <AllEventsProvider>
                            <EnrollmentsProvider>
                              <UserHorsesContext.Provider
                                value={{ userHorses, setUserHorses }}
                              >
                                <SnackbarContext.Provider
                                  value={{ showMessage }}
                                >
                                  <WebRouter />
                                  <Snackbar
                                    visible={snackbarVisible}
                                    onDismiss={dismissSnackbar}
                                    duration={Snackbar.DURATION_SHORT}
                                    style={{ backgroundColor, zIndex: 100000 }}
                                    action={{
                                      label: "Fechar",
                                      onPress: dismissSnackbar,
                                    }}
                                  >
                                    {snackbarMessage}
                                  </Snackbar>
                                </SnackbarContext.Provider>
                              </UserHorsesContext.Provider>
                            </EnrollmentsProvider>
                          </AllEventsProvider>
                        </OrganizerEventsContext.Provider>
                      </UserProfileContext.Provider>
                    </EventTypesContext.Provider>
                  </DressageReprisesProvider>
                </EntitiesProvider>
              </AuthProvider>
            </ThemeProvider>
          </NavigationContainer>
        </NativeBaseProvider>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
};

export default App;
