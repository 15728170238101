// src/context/EntitiesContext.tsx
import { createContext, useContext, useState } from "react";
import { Entity } from "../types";

export type EntitiesContextType = {
  entities: Entity[] | null;
  setEntities: (entities: Entity[] | null) => void;
};

export const EntitiesContext = createContext<EntitiesContextType>({
  entities: null,
  setEntities: () => {},
});

export const useEntitiesContext = () => useContext(EntitiesContext);

type EntitiesProviderProps = {
  children: React.ReactNode;
};

export const EntitiesProvider: React.FC<EntitiesProviderProps> = ({
  children,
}) => {
  const [entities, setEntities] = useState<Entity[] | null>([]);

  return (
    <EntitiesContext.Provider value={{ entities, setEntities }}>
      {children}
    </EntitiesContext.Provider>
  );
};
