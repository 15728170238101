// src/context/OrganizerEventsContext.tsx
import { createContext, useContext, useState } from "react";
import { Event } from "../types";

export type OrganizerEventsContextType = {
  organizerEvents: Event[] | null;
  setOrganizerEvents: (events: Event[] | null) => void;
};

export const OrganizerEventsContext = createContext<OrganizerEventsContextType>(
  {
    organizerEvents: null,
    setOrganizerEvents: () => {},
  }
);

export const useOrganizerEventsContext = () =>
  useContext(OrganizerEventsContext);
