import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { useTheme } from "../../theme/ThemeContext";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";

interface MessageProps {
  message: string;
  iconName: string;
}

const EmptyState: React.FC<MessageProps> = ({ message, iconName }) => {
  const theme = useTheme();

  return (
    <View
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 1,
        padding: 15,
      }}
    >
      <Icon
        name={iconName}
        size={35}
        color={theme.colors.grey3}
        style={{ margin: 10 }}
      />
      <Text
        style={{
          ...theme.typography.body,
          color: theme.colors.grey2,
          textAlign: "center",
          lineHeight: 23,
        }}
      >
        {message}
      </Text>
    </View>
  );
};

export default EmptyState;
