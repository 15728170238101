// src/screens/DirectoryScreen/DirectoryScreen.tsx
import React, { useState } from "react";
import {
  Text,
  View,
  SectionList,
  TouchableOpacity,
  Dimensions,
  Image,
  Linking,
} from "react-native";
import { Actionsheet } from "native-base";
import { ListItem } from "react-native-elements";
import { useTheme } from "../../theme/ThemeContext";
import { useEntities } from "../../hooks/useEntities";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { Entity } from "../../types";
import { imageSources } from "../../assets/images/imageSources";
import { useNavigate } from "react-router-dom";
import { WhatsappIcon } from "../../CorcelDesign/Icons/WhatsappIcon";
import { FacebookIcon } from "../../CorcelDesign/Icons/FacebookIcon";
import { InstagramIcon } from "../../CorcelDesign/Icons/InstagramIcon";
import { formatPhoneNumber } from "../../utils";

const DirectoryScreen: React.FC = () => {
  const theme = useTheme();
  const {
    entities,
    isLoading: entitiesLoading,
    error: entitiesError,
  } = useEntities();
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const { width } = Dimensions.get("window");
  const isMobile = width <= 768;
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const navigate = useNavigate();

  if (entitiesLoading)
    return (
      <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
        <LoadingIndicator />
      </View>
    );

  // Sort entities by state.
  const entitiesByState = (entities || []).reduce<Record<string, Entity[]>>(
    (result, entity) => {
      (result[entity.state] = result[entity.state] || []).push(entity);
      return result;
    },
    {}
  );

  // Create sections and sort them.
  const sections = Object.keys(entitiesByState)
    .sort()
    .map((state) => ({
      title: state,
      data: entitiesByState[state].sort((a, b) => a.name.localeCompare(b.name)), // sort entities within each section
    }));

  // Filter sections based on selected state.
  const filteredSections = selectedState
    ? sections.filter((section) => section.title === selectedState)
    : sections;

  const NavigationBar = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingVertical: 10,
          paddingHorizontal: isMobile ? 10 : 0,
        }}
      >
        <View style={!isMobile && { flex: 2, paddingLeft: 20 }}>
          <TouchableOpacity
            onPress={() => navigate("/")}
            style={{ marginVertical: 12 }}
          >
            <Image
              source={imageSources.logoHorizontal}
              style={{ width: 196, height: 35 }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <>
      <NavigationBar />
      <View
        style={[
          !isMobile
            ? {
                backgroundColor: theme.colors.background,
                alignItems: "flex-start",
                justifyContent: "center",
                maxHeight: windowHeight - 79,
                flexDirection: "row",
                height: "100%",
                paddingTop: 20,
              }
            : null,
        ]}
      >
        <View
          style={[
            !isMobile
              ? {
                  maxWidth: "90%",
                  minWidth: "50%",
                  maxHeight: "95%",
                  backgroundColor: theme.colors.white,
                }
              : null,
          ]}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              padding: 15,
              backgroundColor: theme.colors.grey3,
            }}
          >
            <Text
              style={{
                ...theme.typography.h2,
                color: theme.colors.tertiary,
                fontWeight: "700",
              }}
            >
              Hípicas
            </Text>
            <TouchableOpacity onPress={() => setFilterModalVisible(true)}>
              <Icon name="filter" size={35} color={theme.colors.tertiary} />
            </TouchableOpacity>
          </View>
          <SectionList
            sections={filteredSections}
            ListHeaderComponentStyle={{ marginRight: 0, paddingRight: 0 }}
            renderSectionHeader={({ section: { title } }) => (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                <Icon
                  name="map-marker"
                  size={25}
                  color={theme.colors.tertiary}
                />
                <Text
                  style={{
                    ...theme.typography.h3,
                    color: theme.colors.grey2,
                    fontWeight: "700",
                    marginVertical: 15,
                    marginLeft: 10,
                  }}
                >
                  {title}
                </Text>
              </View>
            )}
            renderItem={({ item }) => {
              const {
                id,
                acronym,
                name,
                email,
                phone,
                instagramLink,
                facebookLink,
                address,
                whatsapp,
              } = item;
              const whatsNumber = whatsapp ? formatPhoneNumber(whatsapp) : null;
              return (
                <ListItem
                  key={id}
                  topDivider
                  bottomDivider
                  style={{ justifyContent: "center" }}
                >
                  <View style={{ flexShrink: 1, flex: 1, marginLeft: 30 }}>
                    <ListItem.Content>
                      <ListItem.Title style={theme.typography.h3}>
                        {acronym ? `${acronym} | ${name}` : name}
                      </ListItem.Title>
                      {email ? (
                        <ListItem.Subtitle style={theme.typography.small}>
                          {email}
                        </ListItem.Subtitle>
                      ) : null}
                      {phone ? (
                        <ListItem.Subtitle style={theme.typography.small}>
                          {phone}
                        </ListItem.Subtitle>
                      ) : null}
                      {address ? (
                        <ListItem.Subtitle style={theme.typography.small}>
                          {address}
                        </ListItem.Subtitle>
                      ) : null}
                    </ListItem.Content>
                    <View
                      style={{
                        flexDirection: "row",
                        paddingVertical: 10,
                        justifyContent: "flex-end",
                      }}
                    >
                      {whatsNumber ? (
                        <TouchableOpacity
                          onPress={() =>
                            Linking.openURL(`https://wa.me/${whatsNumber}`)
                          }
                          style={{ marginHorizontal: 5 }}
                        >
                          <WhatsappIcon
                            size={24}
                            color={theme.colors.tertiary}
                          />
                        </TouchableOpacity>
                      ) : null}
                      {instagramLink ? (
                        <TouchableOpacity
                          onPress={() => Linking.openURL(`${instagramLink}`)}
                          style={{ marginHorizontal: 5 }}
                        >
                          <InstagramIcon
                            size={24}
                            color={theme.colors.primary}
                          />
                        </TouchableOpacity>
                      ) : null}
                      {facebookLink ? (
                        <TouchableOpacity
                          onPress={() => Linking.openURL(`${facebookLink}`)}
                          style={{ marginHorizontal: 5 }}
                        >
                          <FacebookIcon
                            size={24}
                            color={theme.colors.secondary}
                          />
                        </TouchableOpacity>
                      ) : null}
                    </View>
                  </View>
                </ListItem>
              );
            }}
            keyExtractor={(item, index) => item.id}
          />
          <Actionsheet
            isOpen={filterModalVisible}
            onClose={() => setFilterModalVisible(false)}
          >
            <Actionsheet.Content>
              <Actionsheet.Item
                onPress={() => {
                  setSelectedState("");
                  setFilterModalVisible(false);
                }}
              >
                Todos
              </Actionsheet.Item>
              {Object.keys(entitiesByState).map((state) => (
                <Actionsheet.Item
                  key={state}
                  onPress={() => {
                    setSelectedState(state);
                    setFilterModalVisible(false);
                  }}
                >
                  {state}
                </Actionsheet.Item>
              ))}
            </Actionsheet.Content>
          </Actionsheet>
        </View>
        {isMobile ? null : (
          <View>
            <Icon
              name="dots-vertical"
              size={70}
              color={theme.colors.tertiary}
            />
          </View>
        )}
      </View>
    </>
  );
};

export default DirectoryScreen;
