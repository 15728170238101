// firebaseConfig.ts

import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyAn2hY2j8xQJI8xnYlPg879dKjqhG80fWc",
  authDomain: "striboss-51a33.firebaseapp.com",
  projectId: "striboss-51a33",
  storageBucket: "striboss-51a33.appspot.com",
  messagingSenderId: "475647903553",
  appId: "1:475647903553:web:2b7249e39075db4d7c83c0",
  measurementId: "G-CQ2L3K1XT8",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore();
