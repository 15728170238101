// src/context/AllEventsContext.tsx
import React, { createContext, useState, useContext } from "react";
import { Event } from "../types";

export interface AllEventsContextData {
  allEvents: Event[] | null;
  setAllEvents: (events: Event[] | null) => void;
  fetched: boolean;
  setFetched: (fetched: boolean) => void;
}

// Create the context
export const AllEventsContext = createContext<AllEventsContextData | undefined>(
  undefined
);

// Create the provider component
export const AllEventsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [allEvents, setAllEvents] = useState<Event[] | null>(null);
  const [fetched, setFetched] = useState(false);

  return (
    <AllEventsContext.Provider
      value={{ allEvents, setAllEvents, fetched, setFetched }}
    >
      {children}
    </AllEventsContext.Provider>
  );
};

export const useAllEventsContext = (): AllEventsContextData => {
  const context = useContext(AllEventsContext);
  if (!context) {
    throw new Error(
      "useAllEventsContext must be used within an AllEventsProvider"
    );
  }
  return context;
};
