// src/context/DressageReprisesContext.tsx
import { createContext, useContext, useState } from "react";
import { Reprise } from "../types";

export type DressageReprisesContextType = {
  reprises: Reprise[] | null;
  setReprises: (reprises: Reprise[] | null) => void;
};

export const DressageReprisesContext =
  createContext<DressageReprisesContextType>({
    reprises: null,
    setReprises: () => {},
  });

export const useDressageReprisesContext = () =>
  useContext(DressageReprisesContext);

type DressageReprisesProviderProps = {
  children: React.ReactNode;
};

export const DressageReprisesProvider: React.FC<
  DressageReprisesProviderProps
> = ({ children }) => {
  const [reprises, setReprises] = useState<Reprise[] | null>([]);

  return (
    <DressageReprisesContext.Provider value={{ reprises, setReprises }}>
      {children}
    </DressageReprisesContext.Provider>
  );
};
