import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { ListItem } from "react-native-elements";
import {
  FormControl,
  Button,
  Modal,
  WarningOutlineIcon,
  CheckIcon,
  Checkbox,
} from "native-base";
import { useTheme } from "../../theme/ThemeContext";
import { calculateAge } from "../../utils";
import { Enrollment, Horse } from "../../types";
import useUserHorses from "../../hooks/useUserHorses";
import EmptyState from "../EmptyState/EmptyState";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { Select } from "../../CorcelDesign/Select/Select";

interface ManageSingleEnrollmentModalProps {
  isVisible: boolean;
  isUpdating: boolean;
  onClose: () => void;
  enrollment: Enrollment;
  handleUpdateHorse: (enrollmentId: string, horseId: string) => Promise<void>;
  handleUpdateEnrollmentStatus: (
    enrollmentId: string,
    variables: {
      isForfait?: boolean;
      isHorsConcours?: boolean;
      isEliminated?: boolean;
      approved?: boolean;
    }
  ) => Promise<void>;
}

export const ManageSingleEnrollmentModal: React.FC<
  ManageSingleEnrollmentModalProps
> = ({
  isVisible,
  onClose,
  enrollment,
  handleUpdateEnrollmentStatus,
  handleUpdateHorse,
  isUpdating,
}) => {
  const [isHorsConcours, setIsHorsConcours] = useState<boolean>(
    enrollment.isHorsConcours ?? false
  );
  const [isApproved, setIsApproved] = useState<boolean>(
    enrollment.approved ?? false
  );
  const [isEliminated, setIsEliminated] = useState(
    enrollment.isEliminated ?? false
  );
  const [isForfait, setIsForfait] = useState(enrollment.isForfait ?? false);
  const [selectedHorse, setSelectedHorse] = useState<string | undefined>(
    enrollment.horseId ?? undefined
  );

  const theme = useTheme();
  const { userHorses, loading, error } = useUserHorses();

  const enrollmentIsMissingHorse = Boolean(!enrollment.horseId);

  const isSchoolHorse = (horseId: string | null) => {
    return userHorses?.some((userHorse: Horse) => userHorse.id === horseId);
  };

  useEffect(() => {
    setIsHorsConcours(enrollment.isHorsConcours ?? false);
    setIsApproved(enrollment.approved ?? false);
    setIsEliminated(enrollment.isEliminated ?? false);
    setIsForfait(enrollment.isForfait ?? false);
    if (isSchoolHorse(enrollment.horseId)) {
      setSelectedHorse(enrollment.horseId ?? undefined);
    }
  }, [enrollment]);

  const saveHorseSelection = async () => {
    try {
      if (selectedHorse) {
        await handleUpdateHorse(enrollment.id, selectedHorse);
      }
    } catch (error) {
      console.error("Error updating enrollment horse:", error);
    }
  };

  const handleSave = () => {
    const variables = {
      isForfait,
      isHorsConcours,
      isEliminated,
      approved: isApproved,
    };
    try {
      if (selectedHorse && enrollment.horseId !== selectedHorse) {
        saveHorseSelection();
      }
      handleUpdateEnrollmentStatus(enrollment.id, variables);
    } catch (error) {
      console.error(error);
    } finally {
      onClose();
    }
  };

  const horses = userHorses || [];

  const renderHorsePicker = () => {
    if (horses.length > 0) {
      return (
        <>
          <FormControl mt={2} mb={1}>
            <FormControl.Label>Editar o cavalo:</FormControl.Label>
            <Select
              options={horses?.map((horse: Horse, i: number) => ({
                label: horse.name,
                value: horse.id,
              }))}
              placeholder="Montaria"
              value={selectedHorse}
              onValueChange={setSelectedHorse}
            />

            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              Escolha um cavalo
            </FormControl.ErrorMessage>
          </FormControl>
        </>
      );
    } else {
      return (
        <EmptyState
          message="Adicione um cavalo ao seu perfil"
          iconName="account-child-circle"
        />
      );
    }
  };

  const renderEnrollmentInfo = () => {
    const { entity, phone, email } = enrollment.profile ?? {};
    const { fullName, age } = enrollment;
    const {
      name: horseName,
      breed: horseBreed,
      height: horseHeight,
      dateOfBirth: horseDateOfBirth,
    } = enrollment.horse ?? {};

    return (
      <>
        <ListItem>
          <ListItem.Content>
            <ListItem.Title
              style={{ ...theme.typography.h3, marginVertical: 5 }}
            >
              {fullName} {entity && `(${entity.name})`}
            </ListItem.Title>
            <ListItem.Subtitle
              style={{ ...theme.typography.body, color: theme.colors.grey2 }}
            >
              Telefone: {phone}
            </ListItem.Subtitle>
            <ListItem.Subtitle
              style={{ ...theme.typography.body, color: theme.colors.grey2 }}
            >
              Email: {email}
            </ListItem.Subtitle>
            {age ? (
              <ListItem.Subtitle
                style={{ ...theme.typography.body, color: theme.colors.grey2 }}
              >
                Idade: {age} anos
              </ListItem.Subtitle>
            ) : null}
          </ListItem.Content>
        </ListItem>
        {enrollment.horse ? (
          <ListItem>
            <ListItem.Content>
              <ListItem.Title
                style={{ ...theme.typography.h3, marginVertical: 5 }}
              >
                {horseName}
              </ListItem.Title>
              <ListItem.Subtitle
                style={{ ...theme.typography.body, color: theme.colors.grey2 }}
              >
                Raça: {horseBreed}
              </ListItem.Subtitle>
              <ListItem.Subtitle
                style={{ ...theme.typography.body, color: theme.colors.grey2 }}
              >
                Idade: {horseDateOfBirth ? calculateAge(horseDateOfBirth) : ""}
                anos
              </ListItem.Subtitle>

              <ListItem.Subtitle
                style={{ ...theme.typography.body, color: theme.colors.grey2 }}
              >
                Altura: {horseHeight}
              </ListItem.Subtitle>
            </ListItem.Content>
          </ListItem>
        ) : null}
      </>
    );
  };

  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      {loading ? (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <LoadingIndicator />
        </View>
      ) : (
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>
            Gerenciar concorrente
            {(enrollmentIsMissingHorse || isSchoolHorse(enrollment.horseId)) &&
            userHorses
              ? renderHorsePicker()
              : null}
          </Modal.Header>
          <Modal.Body>
            {enrollment && renderEnrollmentInfo()}
            <FormControl my={5}>
              <Checkbox
                isChecked={isHorsConcours}
                value="data"
                onChange={() => {
                  setIsHorsConcours(!isHorsConcours);
                }}
                size="md"
                _text={{
                  fontSize: "md",
                  flexShrink: 1,
                  color: theme.colors.secondary,
                }}
                _checked={{ backgroundColor: theme.colors.secondary }}
              >
                Hors Concours
              </Checkbox>
            </FormControl>
            <FormControl mb={5}>
              <Checkbox
                isChecked={isApproved}
                value="data"
                onChange={() => {
                  setIsApproved(!isApproved);
                }}
                size="md"
                _text={{
                  fontSize: "md",
                  flexShrink: 1,
                  color: theme.colors.secondary,
                }}
                _checked={{ backgroundColor: theme.colors.secondary }}
              >
                Aprovado
              </Checkbox>
            </FormControl>
            <FormControl mb={5}>
              <Checkbox
                isChecked={isForfait}
                value="data"
                onChange={() => {
                  setIsForfait(!isForfait);
                }}
                size="md"
                _text={{
                  fontSize: "md",
                  flexShrink: 1,
                  color: theme.colors.error,
                }}
                _checked={{ backgroundColor: theme.colors.error }}
              >
                Forfait
              </Checkbox>
            </FormControl>
            <FormControl mb={5}>
              <Checkbox
                isChecked={isEliminated}
                value="data"
                onChange={() => {
                  setIsEliminated(!isEliminated);
                }}
                size="md"
                _text={{
                  fontSize: "md",
                  flexShrink: 1,
                  color: theme.colors.error,
                }}
                _checked={{ backgroundColor: theme.colors.error }}
              >
                Eliminado/a
              </Checkbox>
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={5}>
              <Button
                onPress={onClose}
                _text={{ fontWeight: "semibold" }}
                style={{ backgroundColor: theme.colors.grey2 }}
              >
                Cancelar
              </Button>
              <Button
                onPress={handleSave}
                _text={{ fontWeight: "semibold" }}
                style={{ backgroundColor: theme.colors.secondary }}
                isLoading={isUpdating}
              >
                Salvar
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      )}
    </Modal>
  );
};
