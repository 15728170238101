import React, { useEffect, useState } from "react";
import { Overlay } from "react-native-elements";
import {
  View,
  Text,
  TouchableOpacity,
  SectionList,
  Dimensions,
} from "react-native";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import useOrganizerEvents from "../../hooks/useOrganizerEvents";
import {
  Enrollment,
  Event,
  FinalShowjumpingScores,
  ModalityKeys,
  Modality,
} from "../../types";
import { useTheme } from "../../theme/ThemeContext";
import { useSnackbar } from "../../context/SnackbarContext";
import {
  stringEntryOrderComparator,
  computeModalityTimes,
  getModalityTitle,
} from "../../utils";
import { ShowjumpingScoringViewWeb } from "../../components/ShowjumpingScoringViewWeb/ShowjumpingScoringViewWeb";
import { Modal } from "../../CorcelDesign";
import type {
  UpdateEnrollmentArgs,
  RankUpdateArgs,
} from "../../hooks/useEnrollments";
import type { UpdatedEnrollmentType } from "../../hooks/useEnrollments";
import { EnrollmentCard } from "../../components/EnrollmentCard/EnrollmentCard";
import { useNavigation } from "@react-navigation/native";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { EventPdf } from "../../components/exports/EventPdf";
import { ModalityPdf } from "../../components/exports/ModalityPdf";
import { NewLastMinuteEnrollmentModal } from "../../components/NewLastMinuteEnrollmentModal/NewLastMinuteEnrollment";
import { DraggableEntryOrderModal } from "../../components/DraggableEntryOrderModal/DraggableEntryOrderModal";
import { ModalityConfigModal } from "../../components/ModalityConfigModal/ModalityConfigModal";
import { ModalityFormValues } from "../../components/ModalityForm/ModalityForm";

type Props = {
  enrollments: Enrollment[];
  modality: Modality; // Replace with the actual types
  handleUpdateEntryOrder: (
    enrollmentId: string,
    newPosition: number,
    newData: Enrollment[]
  ) => Promise<void>;
  handleUpdateEnrollmentStatus: (
    enrollmentId: string,
    variables: any
  ) => Promise<void>;
  handleUpdateHorse: (enrollmentId: string, horseId: string) => Promise<void>;
  isUpdating: boolean;
  isCreating: boolean;
  isGeneratingEntryOrder: boolean;
  isUpdatingEntryOrder: boolean;
  updateEnrollment: (
    args: UpdateEnrollmentArgs
  ) => Promise<UpdatedEnrollmentType>;
  updateRanks: (
    args: RankUpdateArgs[],
    isModTimeTrial: boolean
  ) => Promise<void>;
  handleGenerateEntryOrder: (modalityId: string) => Promise<void>;
  createLastMinuteEnrollment: ({
    eventId,
    modalityId,
    fullName,
    category,
  }: {
    eventId?: string;
    category: string | null;
    modalityId: string;
    fullName: string;
  }) => Promise<{
    id: string;
    profileId: string;
    eventId: string;
    horseId: string;
    approved: boolean;
    fullName: string;
    category: string | null;
  }>;
  rankIsUpdating: boolean;
  eventId?: string;
  event: Event;
};

export const ShowjumpingScoringScreenWeb: React.FC<Props> = ({
  enrollments,
  modality,
  handleUpdateHorse,
  isUpdating,
  updateEnrollment,
  rankIsUpdating,
  updateRanks,
  createLastMinuteEnrollment,
  isCreating,
  event,
  eventId,
  handleGenerateEntryOrder,
  isGeneratingEntryOrder,
  handleUpdateEntryOrder,
  isUpdatingEntryOrder,
}) => {
  const { handleUpdateModality, modalityLoading } = useOrganizerEvents();
  const [mod, setMod] = useState<Modality>();
  const [ranksSaved, setRanksSaved] = useState(true);
  const windowHeight = Dimensions.get("window").height;
  const [showTimeCalculator, setShowTimeCalculator] = useState(false);
  const [isDragEntryOrderModalVisible, setIsDragEntryOrderModalVisible] =
    useState(false);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const closePrintModal = () => setPrintModalVisible(false);
  const openPrintModal = () => setPrintModalVisible(true);
  const [selectedEnrollment, setSelectedEnrollment] =
    useState<Enrollment | null>(null);
  const [showNewEnrollmentModal, setShowNewEnrollmentModal] = useState(false);
  const theme = useTheme();
  const navigation = useNavigation();
  const { showMessage } = useSnackbar();

  const enrollmentsWithoutOrder = enrollments.filter((enrollment) =>
    Boolean(!enrollment.entryOrder)
  );

  const { name: modName, type: modType } = mod ?? {};

  const isModTimeTrial = modType === ModalityKeys.TIME_TRIAL;
  const isModRace = modType === ModalityKeys.RACE;

  const modTypeTitle = modType ? getModalityTitle(modType) : "";

  useEffect(() => {
    if (
      !(
        modality?.distance ||
        modality.velocity ||
        modality.numJumps ||
        modality.manualAllottedTime
      )
    ) {
      setShowTimeCalculator(true);
    }

    if (modality) {
      setMod(modality);
    }
  }, [modality]);

  const [sections, setSections] = useState<
    { title: string; data: Enrollment[] }[]
  >([]);

  useEffect(() => {
    const filteredResults = enrollments
      .filter(
        (enrollment) =>
          enrollment.approved &&
          enrollment.showjumpingScores &&
          !enrollment.isForfait &&
          !enrollment.isEliminated &&
          !enrollment.isHorsConcours
      )
      .sort((a, b) => {
        // Sort by totalFaults first
        if (
          a.showjumpingScores?.totalFaults !== b.showjumpingScores?.totalFaults
        ) {
          return (
            (a.showjumpingScores?.totalFaults ?? 0) -
            (b.showjumpingScores?.totalFaults ?? 0)
          );
        }

        // If totalFaults are the same, sort by approximation
        return (
          (isModTimeTrial
            ? a.showjumpingScores?.approximation ?? 0
            : a.showjumpingScores?.time ?? 0) -
          (isModTimeTrial
            ? b.showjumpingScores?.approximation ?? 0
            : b.showjumpingScores?.time ?? 0)
        );
      });

    const resultsByCategory: { [key: string]: Enrollment[] } = {};

    filteredResults.forEach((enrollment) => {
      const category = enrollment.category ?? "Sem categoria";
      if (!resultsByCategory[category]) {
        resultsByCategory[category] = [];
      }
      resultsByCategory[category].push(enrollment);
    });

    const resultsSections = Object.keys(resultsByCategory).map((category) => ({
      title: `Resultados - ${category}`,
      data: resultsByCategory[category],
    }));

    const newSections = [
      {
        title: "Próximos",
        data: enrollments
          .filter(
            (enrollment) =>
              enrollment.approved &&
              !enrollment.showjumpingScores &&
              !enrollment.isEliminated &&
              !enrollment.isForfait
          )
          .sort((a, b) => {
            const entryOrderA = a.entryOrder?.toString() ?? "0";
            const entryOrderB = b.entryOrder?.toString() ?? "0";
            return stringEntryOrderComparator(entryOrderA, entryOrderB);
          }),
      },
      ...resultsSections,
      {
        title: "Hors Concours",
        data: enrollments.filter(
          (enrollment) =>
            enrollment.isHorsConcours && enrollment.showjumpingScores
        ),
      },
      {
        title: "Eliminados",
        data: enrollments.filter((enrollment) => enrollment.isEliminated),
      },
      {
        title: "Forfeit",
        data: enrollments.filter((enrollment) => enrollment.isForfait),
      },
    ];
    setSections(newSections);
  }, [enrollments]);

  useEffect(() => {
    const proximosSection = sections.find(
      (section) => section.title === "Próximos"
    );

    if (!ranksSaved && proximosSection && proximosSection.data.length === 0) {
      // Call function to save all ranks here.
      saveAllRanks();
      setRanksSaved(true);
    }
  }, [sections]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", () => {
      // Save the ranks when the user navigates away from this screen
      if (!ranksSaved) {
        saveAllRanks();
      }
    });

    return unsubscribe;
  }, [navigation]);

  const { allottedTime, idealTime } = mod?.manualAllottedTime
    ? {
        allottedTime: mod.manualAllottedTime,
        idealTime: Math.round(mod.manualAllottedTime * 0.95),
      }
    : computeModalityTimes(
        mod?.distance?.toString() ?? "",
        mod?.velocity?.toString() ?? ""
      );

  const renderModalityButton = () => {
    if (isModTimeTrial && idealTime) {
      return `Tempo ideal: ${idealTime}`;
    }
    if (isModRace && allottedTime) {
      return `Tempo concedido: ${allottedTime}`;
    }
    return "Calcular tempo";
  };

  const openEditScoreModal = (enrollment: Enrollment) => {
    setSelectedEnrollment(enrollment);
    setShowScoreModal(true);
  };

  const closeEditScoreModal = () => {
    setShowScoreModal(false);
    setSelectedEnrollment(null);
  };

  const handleSaveShowjumpingScore = async ({
    finalShowjumpingScores,
    isEliminated,
    isForfait,
  }: {
    finalShowjumpingScores: FinalShowjumpingScores;
    isEliminated: boolean;
    isForfait: boolean;
  }) => {
    if (selectedEnrollment) {
      try {
        await updateEnrollment({
          enrollmentId: selectedEnrollment.id,
          showjumpingScores: finalShowjumpingScores,
          isEliminated,
          isForfait,
        });
        showMessage("Resultados atualizadom com sucesso", "success");
        setRanksSaved(false);
      } catch (error) {
        showMessage("Não foi possível atualizar o resultado", "error");
      } finally {
        setShowScoreModal(false);
      }
    }
  };

  const saveAllRanks = async () => {
    try {
      await updateRanks(sections, isModTimeTrial);
      showMessage("Classificações salvas com sucesso", "success");
    } catch (err) {
      if (err instanceof Error) {
        showMessage(err.message, "error");
      } else {
        showMessage("Erro ao atualizar classificação", "error");
      }
    }
  };

  // Function to check if there are enrollments with showjumpingScores and show warning if yes
  const handleModalityUpdateWithWarning = async ({
    id,
    values,
  }: {
    id: string;
    values: ModalityFormValues;
  }) => {
    const { distance, velocity, manualAllottedTime } = values;

    let newTime;
    let newIdealTime;
    if (!isNaN(Number(manualAllottedTime))) {
      newTime = Number(manualAllottedTime);
      newIdealTime = Math.round(Number(manualAllottedTime) * 0.95);
    } else {
      newTime = computeModalityTimes(
        distance?.toString() ?? "",
        velocity?.toString() ?? ""
      ).allottedTime;
      newIdealTime = computeModalityTimes(
        distance?.toString() ?? "",
        velocity?.toString() ?? ""
      ).idealTime;
    }

    const oldAllottedTime = allottedTime;

    if (newTime !== oldAllottedTime) {
      const enrollmentsWithScores = enrollments.some(
        (enrollment) => enrollment.showjumpingScores
      );

      if (enrollmentsWithScores) {
        const confirmed = window.confirm(
          "Alguns resultados já foram lançados, deseja sobrescrever?"
        );

        if (confirmed) {
          for (const enrollment of enrollments) {
            if (enrollment.showjumpingScores) {
              const { time } = enrollment.showjumpingScores;
              const calculatedApproximation =
                time && newIdealTime && isModTimeTrial
                  ? Math.abs(time - newIdealTime)
                  : undefined;

              console.log(calculatedApproximation);

              await updateEnrollment({
                enrollmentId: enrollment.id,
                showjumpingScores: {
                  ...enrollment.showjumpingScores,
                  approximation: calculatedApproximation
                    ? Number(calculatedApproximation.toFixed(2))
                    : null,
                },
              });
            }
          }
        } else {
          return;
        }
      }
    }

    // Update the modality
    await handleUpdateModality({ id, values });
  };

  if (rankIsUpdating || isGeneratingEntryOrder || isUpdating) {
    return <LoadingIndicator />;
  }

  return (
    <View style={{ flexDirection: "row", maxHeight: windowHeight - 180 }}>
      <View style={{ flex: 1, paddingHorizontal: 15 }}>
        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              marginHorizontal: 10,
              backgroundColor: theme.colors.tertiary,
              borderRadius: 5,
              flex: 2,
              shadowColor: "#000",
              shadowOffset: { width: 3, height: 3 },
              shadowOpacity: 0.1,
              shadowRadius: 3.84,
            }}
            onPress={() => setShowTimeCalculator(true)}
          >
            <Text
              style={{
                ...theme.typography.body,
                marginRight: 20,
                fontWeight: "600",
                color: theme.colors.white,
              }}
            >
              {renderModalityButton()}
            </Text>
            <Icon name="calculator" size={25} color={theme.colors.white} />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              marginHorizontal: 10,
              backgroundColor: theme.colors.tertiary,
              borderRadius: 5,
              shadowColor: "#000",
              shadowOffset: { width: 3, height: 3 },
              shadowOpacity: 0.1,
              shadowRadius: 3.84,
              flex: 1,
            }}
            onPress={openPrintModal}
          >
            <Icon name="printer" size={25} color={theme.colors.white} />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              marginHorizontal: 10,
              backgroundColor: theme.colors.tertiary,
              borderRadius: 5,
              shadowColor: "#000",
              shadowOffset: { width: 3, height: 3 },
              shadowOpacity: 0.1,
              shadowRadius: 3.84,
              flex: 1,
            }}
            onPress={() => {
              setShowNewEnrollmentModal(true);
            }}
          >
            <Icon name="account-plus" size={25} color={theme.colors.white} />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              marginHorizontal: 10,
              backgroundColor: theme.colors.tertiary,
              borderRadius: 5,
              shadowColor: "#000",
              shadowOffset: { width: 3, height: 3 },
              shadowOpacity: 0.1,
              shadowRadius: 3.84,
              flex: 1,
            }}
            onPress={() => {
              if (enrollmentsWithoutOrder.length > 0) {
                handleGenerateEntryOrder(modality.id);
              } else {
                setIsDragEntryOrderModalVisible(true);
              }
            }}
          >
            <Icon
              name="format-list-numbered"
              size={25}
              color={theme.colors.white}
            />
          </TouchableOpacity>
        </View>
        <View style={{ margin: 15 }}>
          <Text
            style={{
              ...theme.typography.h3,
              color: theme.colors.secondary,
              textAlign: "center",
            }}
          >
            {modName} - {modTypeTitle}
          </Text>
        </View>
        <View style={{ marginBottom: 15 }}>
          <Text
            style={{
              ...theme.typography.h3,
              color: theme.colors.secondary,
              textAlign: "center",
            }}
          >
            Tempo ideal: {idealTime} | Tempo concedido: {allottedTime}
          </Text>
        </View>

        <View>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              marginHorizontal: 10,
              backgroundColor: theme.colors.accent,
              borderRadius: 5,
              flex: 2,
              shadowColor: "#000",
              shadowOffset: { width: 3, height: 3 },
              shadowOpacity: 0.1,
              shadowRadius: 3.84,
            }}
            onPress={saveAllRanks}
          >
            <Text
              style={{
                ...theme.typography.body,
                marginRight: 20,
                fontWeight: "600",
                color: theme.colors.white,
              }}
            >
              Terminar série e gerar Classificações
            </Text>
            <Icon name="calculator" size={25} color={theme.colors.white} />
          </TouchableOpacity>
        </View>

        <SectionList
          sections={sections}
          keyExtractor={(item) => item.id}
          renderItem={({ item, index }) => (
            <EnrollmentCard
              enrollment={item}
              index={index}
              onPress={() => openEditScoreModal(item)}
              isModTimeTrial={isModTimeTrial}
            />
          )}
          ListFooterComponent={<View style={{ height: 150 }} />}
          renderSectionHeader={({ section: { title } }) => (
            <View
              style={{
                padding: 15,
                backgroundColor: theme.colors.background,
                borderRadius: 5,
                marginBottom: 5,
              }}
            >
              <Text
                style={{
                  ...theme.typography.h3,
                  color: theme.colors.grey2,
                  textAlign: "center",
                }}
              >
                {title}
              </Text>
            </View>
          )}
          contentContainerStyle={{
            padding: 10,
          }}
        />
        {mod ? (
          <ModalityConfigModal
            modality={mod}
            event={event}
            isVisible={showTimeCalculator}
            modalityLoading={modalityLoading}
            handleUpdateModality={handleModalityUpdateWithWarning}
            onClose={() => {
              setShowTimeCalculator(false);
            }}
          />
        ) : null}
      </View>
      <View
        style={{
          flex: 1,
          padding: 35,
          alignItems: "center",
          // justifyContent: "center",
        }}
      >
        {showScoreModal && modType ? (
          <ShowjumpingScoringViewWeb
            isVisible={showScoreModal}
            onClose={closeEditScoreModal}
            isUpdating={isUpdating}
            onSave={handleSaveShowjumpingScore}
            enrollment={selectedEnrollment}
            allottedTime={allottedTime}
            idealTime={idealTime}
            handleUpdateHorse={handleUpdateHorse}
            numJumps={mod ? Number(mod.numJumps) : 0}
            jumpsArray={mod?.jumpsArray}
            penaltyPointsPerSecond={mod?.penaltyPointsPerSecond}
            modalityType={modType}
          />
        ) : null}
      </View>
      <Overlay isVisible={printModalVisible} onBackdropPress={closePrintModal}>
        <View style={{ flex: 1, padding: 10 }}>
          <EventPdf modalityId={modality?.id ?? ""} currentEvent={event} />
        </View>
      </Overlay>
      {mod ? (
        <NewLastMinuteEnrollmentModal
          modality={modality}
          eventId={eventId}
          createLastMinuteEnrollment={createLastMinuteEnrollment}
          isCreating={isCreating}
          onClose={() => setShowNewEnrollmentModal(false)}
          isVisible={showNewEnrollmentModal}
        />
      ) : null}
      {isDragEntryOrderModalVisible &&
        Boolean(
          sections.find((section) => section.title === "Próximos")?.data
        ) && (
          <DraggableEntryOrderModal
            isVisible={isDragEntryOrderModalVisible}
            enrollments={enrollments}
            onClose={() => setIsDragEntryOrderModalVisible(false)}
            updateEntryOrder={handleUpdateEntryOrder}
            loading={isUpdatingEntryOrder}
          />
        )}
    </View>
  );
};
