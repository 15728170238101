// src/firebaseStandaloneFunctions
import {
  Firestore,
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { User } from "firebase/auth";
import { Modality } from "../types";
import { ModalityFormValues } from "../components/ModalityForm/ModalityForm";

export const createModality = async (
  firestore: Firestore,
  user: User,
  eventId: string,
  name: string,
  userRole: string | null
): Promise<{ id: string; name: string; eventId: string }> => {
  if (!user || userRole !== "ORGANIZER") {
    throw new Error("Somente organizadores podem criar categorias");
  }

  const eventDoc = await getDoc(doc(collection(firestore, "events"), eventId));
  const event = eventDoc.data();

  if (!eventDoc.exists || !event) {
    throw new Error("Evento não encontrado");
  }

  if (event.organizerId !== user.uid) {
    throw new Error(
      "Organizadores só podem criar categorias para os próprios eventos"
    );
  }

  if (!name) {
    throw new Error("Adicione um nome para a categoria");
  }

  const modalityRef = await addDoc(collection(firestore, "modalities"), {
    name: name,
    eventId: eventId,
  });

  const modalityDoc = await getDoc(modalityRef);
  const modalityData = modalityDoc.data() as Modality;

  return {
    ...modalityData,
    id: modalityDoc.id,
    eventId,
  };
};

export const updateModality = async (
  firestore: Firestore,
  user: User,
  id: string,
  name: string,
  userRole: string | null
): Promise<{ id: string; name: string; eventId: string }> => {
  if (!user || userRole !== "ORGANIZER") {
    throw new Error("Somente organizadores podem editar categorias");
  }

  const modalityDoc = await getDoc(
    doc(collection(firestore, "modalities"), id)
  );
  const modality = modalityDoc.data();

  if (!modality || !modalityDoc.exists) {
    throw new Error("Categoria não encotrada");
  }

  const eventDoc = await getDoc(
    doc(collection(firestore, "events"), modality.eventId)
  );
  const event = eventDoc.data();

  if (!event) {
    throw new Error("Evento não existe");
  }

  if (event.organizerId !== user.uid) {
    throw new Error(
      "Organizadores só podem alterar categorias dos próprios eventos"
    );
  }

  if (!name) {
    throw new Error("Adicione um nome para a categoria");
  }

  await updateDoc(doc(collection(firestore, "modalities"), id), { name });

  return {
    id: modalityDoc.id,
    ...modality,
    name,
    eventId: modality.eventId,
  };
};

export const updateIndividualModality = async ({
  firestore,
  user,
  id,
  userRole,
  values,
}: {
  firestore: Firestore;
  user: User;
  id: string;
  userRole: string | null;
  values: ModalityFormValues;
}): Promise<ModalityFormValues> => {
  if (!user || userRole === "PARTICIPANT") {
    throw new Error("Somente organizadores podem editar categorias");
  }

  const modalityDoc = await getDoc(
    doc(collection(firestore, "modalities"), id)
  );
  const modality = modalityDoc.data();

  if (!modality || !modalityDoc.exists) {
    throw new Error("Categoria não encotrada");
  }

  const eventDoc = await getDoc(
    doc(collection(firestore, "events"), modality.eventId)
  );
  const event = eventDoc.data();

  if (!event) {
    throw new Error("Evento não existe");
  }

  if (event.organizerId !== user.uid) {
    throw new Error(
      "Organizadores só podem alterar categorias dos próprios eventos"
    );
  }

  const updatedModality: Partial<ModalityFormValues> = {};

  if (values.type) updatedModality.type = values.type;
  if (values.trackType) updatedModality.trackType = values.trackType;
  if (values.description) updatedModality.description = values.description;
  if (values.velocity) updatedModality.velocity = values.velocity;
  if (values.distance) updatedModality.distance = values.distance;
  if (values.numJumps) updatedModality.numJumps = values.numJumps;
  if (values.date) updatedModality.date = values.date;
  if (values.time) updatedModality.time = values.time;
  if (values.numCompetitions)
    updatedModality.numCompetitions = values.numCompetitions;
  if (values.categories) updatedModality.categories = values.categories;
  if (values.name) updatedModality.name = values.name;
  if (values.manualAllottedTime)
    updatedModality.manualAllottedTime = values.manualAllottedTime;
  if (values.penaltyPointsPerSecond)
    updatedModality.penaltyPointsPerSecond = values.penaltyPointsPerSecond;
  if (values.jumpsArray?.length) updatedModality.jumpsArray = values.jumpsArray;

  await updateDoc(
    doc(collection(firestore, "modalities"), id),
    updatedModality
  );

  if (!modality.name) {
    throw new Error("Nome da modalidade não pode ser indefinido");
  }

  return {
    id: modalityDoc.id,
    ...modality,
    ...updatedModality,
    eventId: modality.eventId,
  };
};

export const deleteModality = async (
  firestore: Firestore,
  user: User,
  id: string,
  userRole: string | null
): Promise<{ id: string; name: string; eventId: string }> => {
  if (!user || userRole === "PARTICIPANT") {
    throw new Error("Somente orgaizadores podem deletar categorias");
  }

  const modalityDoc = await getDoc(
    doc(collection(firestore, "modalities"), id)
  );
  const modality = modalityDoc.data() as Modality;
  if (!modalityDoc.exists || !modality) {
    throw new Error("Categoria não encontrada");
  }

  const eventDoc = await getDoc(
    doc(collection(firestore, "events"), modality.eventId)
  );
  const event = eventDoc.data();
  if (!event) {
    throw new Error("Evento não existe");
  }

  if (event.organizerId !== user.uid) {
    throw new Error(
      "Organizadores só podem deletar categorias dos próprios eventos"
    );
  }

  const enrollmentsSnapshot = await getDocs(
    query(collection(firestore, "enrollments"), where("modalityId", "==", id))
  );

  const hasEnrollments = enrollmentsSnapshot.docs.length > 0;

  if (hasEnrollments) {
    throw new Error("Não é possivel deletar categorias com inscrições ativas.");
  }

  await deleteDoc(doc(collection(firestore, "modalities"), id));

  return {
    ...modality,
    id: modalityDoc.id,
  };
};
