import moment from "moment";
import { DateType, FirestoreTimestamp, Modality, ModalityType } from "./types";

export const toDate = (
  value: Date | FirestoreTimestamp | undefined | string
): Date | undefined => {
  if (!value) {
    return undefined;
  }

  if (value instanceof Date) {
    return value;
  }

  if (typeof value === "string") {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date string: ${value}`);
    }
    return date;
  }

  if (typeof value === "object" && "seconds" in value) {
    return new Date(value.seconds * 1000); // Convert to milliseconds
  }

  throw new Error(`Unrecognized date value: ${value}`);
};

export const formatTime24Hour = (eventDate: DateType): string | undefined => {
  if (!eventDate) {
    return undefined;
  }
  const date = toDate(eventDate);

  return moment.utc(date).format("HH:mm");
};

export const formatTime12Hour = (eventDate: DateType): string | undefined => {
  if (!eventDate) {
    return undefined;
  }
  const date = toDate(eventDate);
  return moment.utc(date).format("hh:mm A");
};

function pad(num: number, size: number): string {
  let s = String(num);
  while (s.length < size) s = "0" + s;
  return s;
}
export const formatDateToDisplay = (
  eventDate: DateType
): string | undefined => {
  if (!eventDate) {
    return undefined;
  }

  let date;

  if (typeof eventDate === "string") {
    // Date string "YYYY-MM-DD"
    date = new Date(eventDate);
  } else if (isFirestoreTimestamp(eventDate)) {
    // Firestore Timestamp object
    date = new Date(eventDate.seconds * 1000); // Convert seconds to milliseconds
  } else {
    // Regular JavaScript Date object
    date = eventDate;
  }

  return moment.utc(date).format("DD/MM/YYYY");
};

export const formatDate = (date: string): string => {
  return moment.utc(date).format("YYYY-MM-DD");
};

export const formatDecimalInput = (value: string) => {
  // Replace commas with dots
  let formattedValue = value.replace(",", ".");

  // Add a zero before the dot if necessary
  if (formattedValue.startsWith(".")) {
    formattedValue = "0" + formattedValue;
  }

  return formattedValue;
};

export const calculateAge = (
  dateOfBirth: Date | FirestoreTimestamp | undefined | string
): number => {
  const dob = toDate(dateOfBirth);
  if (!dob) {
    return 0;
  }

  const currentDate = new Date();

  let age = currentDate.getFullYear() - dob.getFullYear();
  const monthDifference = currentDate.getMonth() - dob.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && currentDate.getDate() < dob.getDate())
  ) {
    age--;
  }

  return age;
};

export const stringEntryOrderComparator = (a: string, b: string) => {
  const matchA = a.match(/(\d+)(\D*)/);
  const matchB = b.match(/(\d+)(\D*)/);

  if (!matchA || !matchB) {
    return a.localeCompare(b); // Fallback to standard string comparison if regex match fails
  }

  const [numA, suffixA] = matchA.slice(1);
  const [numB, suffixB] = matchB.slice(1);

  const numericA = parseInt(numA, 10);
  const numericB = parseInt(numB, 10);

  if (numericA !== numericB) {
    return numericA - numericB;
  }

  // If numerical parts are equal, sort by suffix
  return suffixA.localeCompare(suffixB);
};

export const isFirestoreTimestamp = (
  date: DateType
): date is FirestoreTimestamp => {
  return (date as FirestoreTimestamp).seconds !== undefined;
};

export const setByTimezone = (time: Date): void => {
  const date: Date = new Date();
  const difference: number = -date.getTimezoneOffset() / 60;

  time.setHours(time.getHours() + difference);
};

export const sortModalitiesByTime = (modalities: Modality[]) => {
  const sortedModalities =
    modalities?.length &&
    modalities.sort((a: Modality, b: Modality) => {
      // Create combined Date objects
      const aDateObj = toDate(a.date);
      const aTimeObj = toDate(a.time);
      const bDateObj = toDate(b.date);
      const bTimeObj = toDate(b.time);

      if (!aDateObj || !aTimeObj || !bDateObj || !bTimeObj) {
        return 0;
        // throw new Error("Date or time is not defined");
      }

      const aDateTime = new Date(
        aDateObj.getFullYear(),
        aDateObj.getMonth(),
        aDateObj.getDate(),
        aTimeObj.getHours(),
        aTimeObj.getMinutes(),
        aTimeObj.getSeconds()
      );

      const bDateTime = new Date(
        bDateObj.getFullYear(),
        bDateObj.getMonth(),
        bDateObj.getDate(),
        bTimeObj.getHours(),
        bTimeObj.getMinutes(),
        bTimeObj.getSeconds()
      );

      // Compare timestamps
      return aDateTime.getTime() - bDateTime.getTime();
    });
  return sortedModalities || [];
};

export const formatPhoneNumber = (input: string): string => {
  // Remove all non-numeric characters
  const cleaned = input.replace(/\D/g, "");

  // Add "55" at the beginning
  return `55${cleaned}`;
};

export const computeModalityTimes = (distance: string, velocity: string) => {
  if (!distance || !velocity) return {};
  const distanceNum = parseInt(distance);
  const velocityNum = parseInt(velocity);
  if (isNaN(distanceNum) || isNaN(velocityNum)) return {};

  const allottedTime = Math.ceil((distanceNum / velocityNum) * 60);
  const idealTime = Math.round(allottedTime * 0.95);
  return { allottedTime, idealTime };
};

export const sanitizeNumericInput = (
  input: string | undefined
): number | undefined => {
  if (input === "0") return 0;
  if (!input) return undefined;
  const sanitized = input.replace(/[^\d.,]/g, "").replace(/,/g, ".");
  return sanitized ? Number(sanitized) : undefined;
};

export const getModalityTitle = (modType: ModalityType): string | null => {
  if (!modType) {
    return null;
  }
  const modalityTitles = {
    timeTrial: "Normal com Faixa de Tempo e Tempo Ideal",
    race: "Chronômetro",
    tour: "Derby",
    power: "Potência",
    reprise: "Reprise",
  };

  return modalityTitles[modType] || null;
};

export const customSort = (a: string, b: string): number => {
  const numA = parseInt(a, 10);
  const numB = parseInt(b, 10);

  if (!isNaN(numA) && !isNaN(numB)) {
    return numA - numB;
  } else if (!isNaN(numA)) {
    return -1;
  } else if (!isNaN(numB)) {
    return 1;
  }

  return a.localeCompare(b);
};
