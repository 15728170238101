import React from "react";
import { CorcelIcon } from "./CorcelIcon";

interface Props {
  color?: string;
  size?: number;
}

export const WhatsappIcon: React.FC<Props> = ({ color = "#000", size }) => {
  const myPaths = [
    {
      d: "M23.4 4.54666C22.1776 3.31189 20.7216 2.33288 19.1169 1.66673C17.5121 1.00057 15.7908 0.660607 14.0533 0.66666C6.77333 0.66666 0.84 6.59999 0.84 13.88C0.84 16.2133 1.45333 18.48 2.6 20.48L0.733334 27.3333L7.73333 25.4933C9.66667 26.5467 11.84 27.1067 14.0533 27.1067C21.3333 27.1067 27.2667 21.1733 27.2667 13.8933C27.2667 10.36 25.8933 7.03999 23.4 4.54666ZM14.0533 24.8667C12.08 24.8667 10.1467 24.3333 8.45333 23.3333L8.05333 23.0933L3.89333 24.1867L5 20.1333L4.73333 19.72C3.637 17.9693 3.05485 15.9457 3.05333 13.88C3.05333 7.82666 7.98667 2.89333 14.04 2.89333C16.9733 2.89333 19.7333 4.03999 21.8 6.11999C22.8233 7.13861 23.6343 8.35021 24.1859 9.68456C24.7375 11.0189 25.0187 12.4495 25.0133 13.8933C25.04 19.9467 20.1067 24.8667 14.0533 24.8667ZM20.08 16.6533C19.7467 16.4933 18.12 15.6933 17.8267 15.5733C17.52 15.4667 17.3067 15.4133 17.08 15.7333C16.8533 16.0667 16.2267 16.8133 16.04 17.0267C15.8533 17.2533 15.6533 17.28 15.32 17.1067C14.9867 16.9467 13.92 16.5867 12.6667 15.4667C11.68 14.5867 11.0267 13.5067 10.8267 13.1733C10.64 12.84 10.8 12.6667 10.9733 12.4933C11.12 12.3467 11.3067 12.1067 11.4667 11.92C11.6267 11.7333 11.6933 11.5867 11.8 11.3733C11.9067 11.1467 11.8533 10.96 11.7733 10.8C11.6933 10.64 11.0267 9.01333 10.76 8.34666C10.4933 7.70666 10.2133 7.78666 10.0133 7.77333H9.37333C9.14667 7.77333 8.8 7.85333 8.49333 8.18666C8.2 8.51999 7.34667 9.31999 7.34667 10.9467C7.34667 12.5733 8.53333 14.1467 8.69333 14.36C8.85333 14.5867 11.0267 17.92 14.3333 19.3467C15.12 19.6933 15.7333 19.8933 16.2133 20.04C17 20.2933 17.72 20.2533 18.2933 20.1733C18.9333 20.08 20.2533 19.3733 20.52 18.6C20.8 17.8267 20.8 17.1733 20.7067 17.0267C20.6133 16.88 20.4133 16.8133 20.08 16.6533Z",
      color: color,
    },
  ];

  return <CorcelIcon paths={myPaths} size={size} viewBox="0 0 28 28" />;
};
