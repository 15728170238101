// components/ModalityForm.tsx
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ListItem } from "react-native-elements";
import { View, Platform, Text } from "react-native";
import RNDateTimePicker from "@react-native-community/datetimepicker";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { useTheme } from "../../theme/ThemeContext";
import { Modal } from "../../CorcelDesign/Modal/Modal";
import { Select } from "../../CorcelDesign/Select/Select";
import { formatDateToDisplay, formatTime24Hour, toDate } from "../../utils";
import { FormControl, Input, TextArea } from "native-base";
import { DateType, Reprise, ModalityType, ModalityKeys } from "../../types";

export interface ModalityFormValues {
  name?: string;
  type?: ModalityType | null;
  trackType?: string;
  description?: string;
  velocity?: number | null;
  distance?: number | null;
  numJumps?: number | null;
  manualAllottedTime?: number | null;
  date?: DateType;
  time?: DateType;
  numCompetitions?: number;
  categories?: string[];
  id?: string;
  eventId?: string;
  dressageRepriseId?: string;
  jumpsArray?: string[];
  penaltyPointsPerSecond?: number | null;
}

interface ModalityFormProps {
  modalityData?: ModalityFormValues | null;
  saveModality: (modalityData: ModalityFormValues) => void;
  onClose: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  eventTypeId?: string;
  reprises?: Reprise[] | null;
}

const showjumpingOptions = [
  { label: "Chronômetro", value: ModalityKeys.RACE },
  {
    label: "Normal com Faixa de Tempo e Tempo Ideal",
    value: ModalityKeys.TIME_TRIAL,
  },
  { label: "Derby", value: ModalityKeys.DERBY },
  { label: "Potência", value: ModalityKeys.DERBY },
];

export const ModalityForm: React.FC<ModalityFormProps> = ({
  modalityData,
  saveModality,
  onClose,
  isOpen,
  isLoading,
  eventTypeId,
  reprises,
}) => {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const [name, setName] = useState("");
  const [type, setType] = useState<ModalityType>();
  const [repriseId, setRepriseId] = useState("");
  const [description, setDescription] = useState("");
  const [trackType, setTrackType] = useState("");
  const [distance, setDistance] = useState("");
  const [velocity, setVelocity] = useState("");
  const [numJumps, setNumJumps] = useState(
    modalityData?.numJumps?.toString() || ""
  );
  const [date, setDate] = useState(tomorrow);
  const [time, setTime] = useState(tomorrow);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);

  const [numCompetitions, setNumCompetitions] = useState(
    modalityData?.numCompetitions?.toString() || ""
  );
  const [categories, setCategories] = useState([""]);
  const theme = useTheme();

  useEffect(() => {
    if (eventTypeId === "3f18501c-8bf5-42e2-9ecf-3cbdc84c3ca2") {
      setType("reprise");
    }
    if (modalityData) {
      if (eventTypeId === "3f18501c-8bf5-42e2-9ecf-3cbdc84c3ca2") {
        setType("reprise");
      } else {
        setType(modalityData.type || ModalityKeys.TIME_TRIAL);
      }
      setName(modalityData.name || "");
      setDescription(modalityData.description || "");
      setTrackType(modalityData.trackType || "");
      setRepriseId(modalityData.dressageRepriseId || "");
      setDistance(modalityData.distance?.toString() || "");
      setVelocity(modalityData.velocity?.toString() || "");
      setNumJumps(modalityData.numJumps?.toString() || "");
      setNumCompetitions(modalityData.numCompetitions?.toString() || "");
      setCategories(modalityData?.categories || [""]);
      const modDate = toDate(modalityData.date);
      const modTime = toDate(modalityData.time);
      setDate(modDate ?? tomorrow);
      setTime(modTime ?? tomorrow);
    }
    return () => clear();
  }, [modalityData]);

  const addCategory = () => {
    setCategories([...categories, ""]);
  };

  const updateCategory = (index: number, value: string) => {
    const newCategories = [...categories];
    newCategories[index] = value;
    setCategories(newCategories);
  };

  const removeCategory = (index: number) => {
    const newCategories = [...categories];
    newCategories.splice(index, 1);
    setCategories(newCategories);
  };

  const renderItem = useCallback(
    ({ item, index }: { item: string; index: number }) => (
      <ListItem
        key={index}
        bottomDivider
        containerStyle={{
          backgroundColor: theme.colors.background,
        }}
      >
        <ListItem.Content>
          <Input
            size="md"
            placeholder={`Categoria ${index + 1}`}
            value={item}
            onChangeText={(value) => updateCategory(index, value)}
            backgroundColor={theme.colors.white}
          />
        </ListItem.Content>

        <Icon
          name="delete-forever"
          onPress={() => removeCategory(index)}
          size={30}
          color={theme.colors.error}
        />
      </ListItem>
    ),
    [categories]
  );

  const clear = () => {
    setName("");
    setType(undefined);
    setTrackType("");
    setVelocity("");
    setNumJumps("");
    setDistance("");
    setDescription("");
    setRepriseId("");
    setDate(tomorrow);
    setTime(tomorrow);
    setNumCompetitions("");
    setCategories([""]);
  };

  const handleSubmit = () => {
    const newModalityData = {
      name,
      type: type ?? null,
      trackType,
      velocity: parseInt(velocity.replace(/[^0-9.,]/g, "")) ?? null,
      distance: parseInt(distance.replace(/[^0-9.,]/g, "")) ?? null,
      numJumps: parseInt(numJumps.replace(/[^0-9.,]/g, "")) ?? null,
      dressageRepriseId: repriseId,
      description,
      date,
      time,
      numCompetitions:
        parseInt(numCompetitions.replace(/[^0-9.,]/g, "") ?? 1) ?? null,
      categories,
    };

    saveModality({ ...modalityData, ...newModalityData });
  };

  const repriseOptions = useMemo(
    () =>
      reprises
        ?.sort((a, b) => a.name?.localeCompare(b.name))
        .map((reprise: Reprise) => ({
          label: reprise.name,
          value: reprise.id,
        })),
    [reprises]
  );

  const closeAndClear = () => {
    onClose();
    clear();
  };

  const renderForm = () => (
    <>
      {eventTypeId === "3f18501c-8bf5-42e2-9ecf-3cbdc84c3ca2" ? (
        <>
          {reprises ? (
            <FormControl mt={2} isRequired>
              <FormControl.Label>Tipo de reprise</FormControl.Label>
              <Select
                options={repriseOptions || []}
                placeholder="Tipo da reprise"
                value={repriseId}
                onValueChange={(itemValue: string) => {
                  setRepriseId(itemValue);
                  const selectedReprise = reprises?.find(
                    (reprise) => reprise.id === itemValue
                  );

                  if (selectedReprise) {
                    setName(selectedReprise.name);
                  }
                }}
              />
            </FormControl>
          ) : null}
        </>
      ) : (
        <FormControl mt={2} isRequired>
          <FormControl.Label>Tipo de prova</FormControl.Label>
          <Select
            options={showjumpingOptions}
            placeholder="Tipo da prova"
            value={type}
            onValueChange={(itemValue: ModalityType) => setType(itemValue)}
          />
        </FormControl>
      )}
      {eventTypeId === "3f18501c-8bf5-42e2-9ecf-3cbdc84c3ca2" ? null : (
        <FormControl isRequired>
          <FormControl.Label>Título</FormControl.Label>
          <Input
            variant="outline"
            size="md"
            mb={3}
            placeholder="Título"
            value={name}
            onChangeText={setName}
          />
        </FormControl>
      )}
      {Platform.OS === "android" ? (
        <>
          <FormControl>
            <FormControl.Label>Data</FormControl.Label>

            <Input
              variant="outline"
              size="md"
              mb={3}
              placeholder="Data"
              value={formatDateToDisplay(date)}
              onPressIn={() => setShowDatePicker(true)}
            />
          </FormControl>
          {showDatePicker && (
            <RNDateTimePicker
              testID="datePicker"
              value={date}
              mode="date"
              display="default"
              timeZoneOffsetInMinutes={0}
              onTouchCancel={() => setShowDatePicker(false)}
              onChange={(event, selectedDate) => {
                setShowDatePicker(false);
                const currentDate = selectedDate || date;
                setDate(currentDate);
              }}
            />
          )}
        </>
      ) : (
        <FormControl>
          <FormControl.Label>Data</FormControl.Label>
          <RNDateTimePicker
            testID="datePicker"
            value={date}
            mode="date"
            display="default"
            timeZoneOffsetInMinutes={0}
            onTouchCancel={() => setShowDatePicker(false)}
            onChange={(event, selectedDate) => {
              setShowDatePicker(false);
              const currentDate = selectedDate || date;
              setDate(currentDate);
            }}
          />
        </FormControl>
      )}
      {Platform.OS === "android" ? (
        <>
          <FormControl>
            <FormControl.Label>Hora</FormControl.Label>
            <Input
              variant="outline"
              size="md"
              mb={3}
              placeholder="Hora"
              value={formatTime24Hour(time)}
              onPressIn={() => setShowTimePicker(true)}
            />
          </FormControl>
          {showTimePicker && (
            <RNDateTimePicker
              testID="timePicker"
              value={time}
              mode="time"
              display="default"
              timeZoneOffsetInMinutes={0}
              onTouchCancel={() => setShowTimePicker(false)}
              onChange={(event, selectedDate) => {
                setShowTimePicker(false);
                const currentDate = selectedDate || time;
                setTime(currentDate);
              }}
            />
          )}
        </>
      ) : (
        <FormControl>
          <FormControl.Label>Hora</FormControl.Label>
          <RNDateTimePicker
            testID="timePicker"
            value={time}
            mode="time"
            display="default"
            onTouchCancel={() => setShowTimePicker(false)}
            timeZoneOffsetInMinutes={0}
            onChange={(event, selectedDate) => {
              setShowTimePicker(false);
              const currentDate = selectedDate || time;
              setTime(currentDate);
            }}
          />
        </FormControl>
      )}

      <FormControl>
        <FormControl.Label>Tipo de pista</FormControl.Label>
        <Input
          variant="outline"
          size="md"
          mb={3}
          placeholder="Tipo de Pista"
          value={trackType}
          onChangeText={setTrackType}
        />
      </FormControl>
      {eventTypeId === "3f18501c-8bf5-42e2-9ecf-3cbdc84c3ca2" ? null : (
        <>
          <FormControl>
            <FormControl.Label>Velocidade (m/minuto)</FormControl.Label>

            <Input
              variant="outline"
              size="md"
              mb={3}
              placeholder="Velocidade"
              value={velocity}
              onChangeText={setVelocity}
              keyboardType="numeric"
              inputMode="numeric"
            />
          </FormControl>
          <FormControl>
            <FormControl.Label>Extenção (m)</FormControl.Label>
            <Input
              variant="outline"
              size="md"
              mb={3}
              placeholder="Extenção"
              value={distance ?? null}
              onChangeText={setDistance}
              keyboardType="numeric"
              inputMode="numeric"
            />
          </FormControl>
          <FormControl>
            <FormControl.Label>Quantidade de Obstáculos</FormControl.Label>
            <Input
              variant="outline"
              size="md"
              mb={3}
              placeholder="Qtd obstáculos"
              value={numJumps ?? null}
              onChangeText={setNumJumps}
              keyboardType="numeric"
              inputMode="numeric"
            />
          </FormControl>
        </>
      )}

      <FormControl>
        <FormControl.Label>Outras</FormControl.Label>
        <TextArea
          variant="outline"
          aria-label="t1"
          h={20}
          placeholder="Quaisquer outras informações"
          value={description}
          onChangeText={setDescription}
          size="md"
          type="text"
          autoCompleteType=""
        />
      </FormControl>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 15,
        }}
      >
        <Text style={theme.typography.h3}>Categorias:</Text>

        <Icon
          name="plus-circle"
          size={25}
          style={{ marginLeft: 10 }}
          color={theme.colors.accent}
          onPress={() => {
            addCategory();
          }}
        />
      </View>
      <View style={{ paddingVertical: 10 }}>
        {categories.map((item, index) => renderItem({ item, index }))}
      </View>
    </>
  );

  return (
    <Modal
      isVisible={isOpen}
      buttonText="Salvar Série"
      onClose={closeAndClear}
      onSubmit={handleSubmit}
      title="Defina a série"
      submitLoading={isLoading}
    >
      {renderForm()}
    </Modal>
  );
};
