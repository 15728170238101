import React, { useState, useEffect } from "react";
import { View, Dimensions } from "react-native";
import { Text, ButtonGroup } from "react-native-elements";
import { OrganizerGroupParamList } from "../../navigation/OrganizerTabs";
import { RouteProp } from "@react-navigation/native";
import { Enrollment, Modality, Event } from "../../types";
import PendingEnrollmentsList from "../../components/PendingEnrollmentsList/PendingEnrollmentsListWeb";
import ApprovedEnrollmentsListWeb from "../../components/ApprovedEnrollmentsList/ApprovedEnrollmentsListWeb";
import useEnrollments from "../../hooks/useEnrollments";
import useOrganizerEvents from "../../hooks/useOrganizerEvents";
import { useTheme } from "../../theme/ThemeContext";
import { useSnackbar } from "../../context/SnackbarContext";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import {
  ModalityForm,
  ModalityFormValues,
} from "../../components/ModalityForm/ModalityForm";
import { EventTypeIds } from "../../types";
import { ShowjumpingScoringScreenWeb } from "../ModalityScoringScreen/ShowjumpingScoringScreenWeb";
import { DressageScoringScreen } from "../ModalityScoringScreen/DressageScoringScreen";

import { EnrollmentPdf } from "../../components/exports/EnrollmentPdf";
import { ModalityPdf } from "../../components/exports/ModalityPdf";
import { stringEntryOrderComparator } from "../../utils";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import styles from "./styles";

type Props = {
  modality?: Modality;
  currentEvent?: Event;
};
const ModalityManagementScreen: React.FC<Props> = ({
  modality,
  currentEvent,
}) => {
  const theme = useTheme();
  const { showMessage } = useSnackbar();

  const modalityId = modality?.id;
  const eventTypeId = currentEvent?.eventTypeId;
  const { handleUpdateModality, modalityLoading } = useOrganizerEvents();
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const isDressageEvent = eventTypeId === EventTypeIds.DRESSAGE_EVENT_TYPE_ID;
  const isShowjumpingEvent =
    eventTypeId === EventTypeIds.SHOWJUMPING_EVENT_TYPE_ID;

  const {
    enrollments,
    isLoading,
    error,
    updateEnrollment,
    isUpdating,
    generateEntryOrderForModality,
    isGeneratingEntryOrder,
    handleUpdateEntryOrder,
    isUpdatingEntryOrder,
    updateRanks,
    rankIsUpdating,
  } = useEnrollments({
    modalityId,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [modalityOverlayVisible, setModalityOverlayVisible] = useState(false);

  const [pendingEnrollments, setPendingEnrollments] = useState<Enrollment[]>(
    []
  );
  const [approvedEnrollments, setApprovedEnrollments] = useState<Enrollment[]>(
    []
  );

  const generatedStyles = styles();

  useEffect(() => {
    setPendingEnrollments(
      enrollments.filter((enrollment: Enrollment) => !enrollment.approved)
    );
    setApprovedEnrollments(
      enrollments.filter((enrollment: Enrollment) => enrollment.approved)
    );
  }, [enrollments, approvedCount]);

  const updateModality = async (values: ModalityFormValues) => {
    try {
      if (modalityId) {
        await handleUpdateModality({
          id: modalityId,
          values,
        });
        showMessage("Categoria atualizada", "success");
      }
    } catch (err) {
      if (err instanceof Error) {
        showMessage(err.message, "error");
      } else {
        showMessage("Erro ao atualizar categoria", "error");
      }
    } finally {
      setModalityOverlayVisible(false);
    }
  };

  const handleUpdateStats = async (
    enrollmentId: string,
    variables: {
      rank?: number;
      score?: number;
      time?: number;
      approximation?: number;
      faults?: number;
      // timeFaultPoints?: number;
      // totalFaultPoints?: number;
    }
  ) => {
    try {
      await updateEnrollment({
        enrollmentId,
        ...variables,
      });
      showMessage("Estatísticas do concorrente atualizadas", "success");
    } catch (error) {
      showMessage("Não foi possivel atualizar as estatísticas", "error");
      console.error("Error updating participant stats:", error);
    }
  };
  const handleUpdateHorse = async (enrollmentId: string, horseId: string) => {
    try {
      await updateEnrollment({
        enrollmentId,
        horseId,
      });
      showMessage("Cavalo atualizado com sucesso", "success");
    } catch (error) {
      showMessage("Não foi possível atualizar o cavalo", "error");
      console.error("Error updating horse:", error);
    }
  };
  const handleUpdateEnrollmentStatus = async (
    enrollmentId: string,
    variables: {
      isForfait?: boolean;
      isHorsConcours?: boolean;
      isEliminated?: boolean;
      approved?: boolean;
    }
  ) => {
    try {
      await updateEnrollment({
        enrollmentId,
        ...variables,
      });

      setApprovedCount((count) => (variables.approved ? count + 1 : count - 1));

      showMessage("Inscrição atualizada com sucesso", "success");
    } catch (error) {
      showMessage("Não foi possível atualizar inscrição", "error");
      console.error("Error updating enrollment:", error);
    }
  };

  const handleGenerateEntryOrder = async (modalityId: string) => {
    try {
      await generateEntryOrderForModality(modalityId);
      showMessage("Ordem de entrada gerada com sucesso", "success");
    } catch (error) {
      console.error("Error generating entry order:", error);
      showMessage("Não foi possivel gerar ordem de entrada", "error");
    }
  };

  if (isLoading || modalityLoading) {
    return (
      <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
        <LoadingIndicator />
      </View>
    );
  }

  if (error) {
    return (
      <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
        <Text>Não foi possível carregar a lista de inscritos.</Text>
      </View>
    );
  }

  // Function to update the selected index in segmented control
  const updateIndex = (selectedIndex: number) => {
    setSelectedIndex(selectedIndex);
  };

  // Segmented control buttons
  const buttons = [
    `Pendências (${isUpdating ? "" : pendingEnrollments.length})`,
    `Prova (${isUpdating ? "" : approvedEnrollments.length})`,
  ];

  const enrollmentList = () => {
    if (selectedIndex === 0) {
      return (
        <PendingEnrollmentsList
          enrollments={pendingEnrollments}
          isUpdating={isUpdating}
          handleUpdateEnrollmentStatus={handleUpdateEnrollmentStatus}
          handleUpdateHorse={handleUpdateHorse}
        />
      );
    } else if (selectedIndex === 1) {
      return (
        <ApprovedEnrollmentsListWeb
          enrollments={approvedEnrollments}
          modalityId={modality?.id}
          isUpdating={isUpdating}
          handleGenerateEntryOrder={handleGenerateEntryOrder}
          handleUpdateEnrollmentStatus={handleUpdateEnrollmentStatus}
          handleUpdateHorse={handleUpdateHorse}
          isGeneratingEntryOrder={isGeneratingEntryOrder}
          handleUpdateEntryOrder={handleUpdateEntryOrder}
          isUpdatingEntryOrder={isUpdatingEntryOrder}
        />
      );
      // if (isShowjumpingEvent) {
      //   return (
      //     <ShowjumpingScoringScreenWeb
      //       enrollments={enrollments}
      //       modality={modality}
      //       handleUpdateEnrollmentStatus={handleUpdateEnrollmentStatus}
      //       handleUpdateHorse={handleUpdateHorse}
      //       isUpdating={isUpdating}
      //       updateEnrollment={updateEnrollment}
      //       updateRanks={updateRanks}
      //       rankIsUpdating={rankIsUpdating}
      //     />
      //   );
      // }

      // if (isDressageEvent) {
      //   return (
      //     <DressageScoringScreen
      //       enrollments={enrollments}
      //       modality={modality}
      //       handleUpdateEnrollmentStatus={handleUpdateEnrollmentStatus}
      //       handleUpdateHorse={handleUpdateHorse}
      //       isUpdating={isUpdating}
      //       updateEnrollment={updateEnrollment}
      //     />
      //   );
      // }
    }
  };

  return (
    <View style={{ height: windowHeight - 1000 }}>
      <View
        style={{
          ...generatedStyles.container,
        }}
      >
        <ButtonGroup
          onPress={updateIndex}
          selectedIndex={selectedIndex}
          buttons={buttons}
          containerStyle={{
            borderWidth: 1,
            backgroundColor: theme.colors.white,
            borderColor: "#ccc",
            borderRadius: 10,
          }}
          selectedButtonStyle={{
            backgroundColor: theme.colors.tertiary,
            paddingVertical: 10,
          }}
          buttonStyle={{ paddingVertical: 10 }}
        />
      </View>

      {enrollmentList()}

      {modalityOverlayVisible && (
        <ModalityForm
          modalityData={modality}
          saveModality={(modalityData) => {
            updateModality(modalityData);
          }}
          onClose={() => setModalityOverlayVisible(false)}
          isOpen={modalityOverlayVisible}
          isLoading={modalityLoading}
          eventTypeId={eventTypeId}
        />
      )}
    </View>
  );
};

export default ModalityManagementScreen;
