// src/hooks/useUserHorses

import { useState, useEffect } from "react";
import { useUserHorsesContext } from "../context/UserHorsesContext";
import { Horse } from "../types"; // Make sure to create a Horse type based on your schema
import { useAuth } from "./useAuth";
import {
  getFirestore,
  onSnapshot,
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";

export interface UseUserHorsesData {
  userHorses: Horse[] | null;
  loading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  error: Error | null;
  addUserHorse: (horseData: Partial<Horse>) => Promise<void>;
  updateUserHorse: (
    horseId: string,
    horseData: Partial<Horse>
  ) => Promise<void>;
  deleteUserHorse: (horseId: string) => Promise<void>;
}

const useUserHorses = (): UseUserHorsesData => {
  const { userHorses, setUserHorses } = useUserHorsesContext();
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuth();
  const firestore = getFirestore();

  useEffect(() => {
    if (user) {
      const userHorsesQuery = query(
        collection(firestore, "horses"),
        where("ownerId", "==", user.uid)
      );

      const unsubscribe = onSnapshot(
        userHorsesQuery,
        (querySnapshot) => {
          const horses: Horse[] = [];
          querySnapshot.forEach((doc) => {
            const horseData = doc.data();
            horses.push({ id: doc.id, ...horseData } as Horse);
          });
          setUserHorses(horses);
          setLoading(false);
        },
        (error) => {
          setError(error);
          setLoading(false);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [user, firestore]);

  const addUserHorse = async (horseData: Partial<Horse>) => {
    setUpdateLoading(true);
    try {
      if (user) {
        const { name, breed, dateOfBirth, height, sex } = horseData;

        if (!name || !breed || !dateOfBirth || !height || !sex) {
          throw new Error(
            "Por favor, forneça um nome, raça, data de nascimento, altura e sexo válidos para o cavalo"
          );
        }

        await addDoc(collection(firestore, "horses"), {
          ...Object.fromEntries(
            Object.entries(horseData).filter(([key]) => key !== "updateHorseId")
          ),
          ownerId: user.uid,
        });
      } else {
        throw new Error("Usuário não autenticado");
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  const updateUserHorse = async (
    horseId: string,
    horseData: Partial<Horse>
  ) => {
    setUpdateLoading(true);
    try {
      if (user) {
        const { name, breed, dateOfBirth, height, sex } = horseData;

        if (!name || !breed || !dateOfBirth || !height || !sex) {
          throw new Error(
            "Por favor, forneça um nome, raça, data de nascimento, altura e sexo válidos para o cavalo"
          );
        }

        const horseRef = doc(firestore, "horses", horseId);
        const horseSnapshot = await getDoc(horseRef);
        const horse = horseSnapshot.data();

        if (!horse) {
          throw new Error("Cavalo inexistente");
        }

        if (horse.ownerId !== user.uid) {
          throw new Error("Usuário não tem permissão para editar esse cavalo");
        }

        await updateDoc(horseRef, horseData);
      } else {
        throw new Error("Usuário não autenticado");
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  const deleteUserHorse = async (horseId: string) => {
    setDeleteLoading(true);
    try {
      if (user) {
        const horseRef = doc(firestore, "horses", horseId);
        const horseSnapshot = await getDoc(horseRef);
        const horse = horseSnapshot.data();

        if (!horse) {
          throw new Error("Esse cavalo não existe");
        }

        if (horse.ownerId !== user.uid) {
          throw new Error("Usuário não tem permissão para editar esse cavalo");
        }

        // Check if there are any enrollment instances using the horse
        const enrollmentQuery = query(
          collection(firestore, "enrollments"),
          where("horseId", "==", horseId)
        );
        const enrollmentsnapshot = await getDocs(enrollmentQuery);

        if (!enrollmentsnapshot.empty) {
          throw new Error(
            "Não é possível excluir do registro deste cavalo, pois ele já foi cadastrado em eventos."
          );
        }

        await deleteDoc(horseRef);
      } else {
        throw new Error("Usuário não autenticado");
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  return {
    userHorses,
    loading,
    error,
    deleteLoading,
    updateLoading,
    addUserHorse,
    updateUserHorse,
    deleteUserHorse,
  };
};

export default useUserHorses;
