// src/hooks/useEntities.ts
import { useEffect, useState } from "react";
import { useEntitiesContext } from "../context/EntitiesContext";
import { Entity } from "../types";
import { getDocs, collection } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

export const useEntities = () => {
  const { entities, setEntities } = useEntitiesContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (entities === null || entities.length === 0) {
      const fetchEntities = async () => {
        try {
          const db = getFirestore();
          const entitiesSnapshot = await getDocs(collection(db, "entities"));
          const fetchedEntities = entitiesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Entity[];

          setEntities(fetchedEntities);
        } catch (error) {
          console.error("Nenhuma entidade encontrada", error);
        }
      };
      setIsLoading(true);
      setError(null);

      try {
        fetchEntities();
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Ocorreu um erro desconhecido");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [entities, setEntities]);

  return { entities, setEntities, isLoading, error };
};
