// src/context/EventTypesContext.tsx
import { createContext, useContext, useState } from "react";
import { EventType } from "../types";

export type EventTypesContextType = {
  eventTypes: EventType[] | null;
  setEventTypes: (eventTypes: EventType[] | null) => void;
};

export const EventTypesContext = createContext<EventTypesContextType>({
  eventTypes: null,
  setEventTypes: () => {},
});

export const useEventTypesContext = () => useContext(EventTypesContext);
