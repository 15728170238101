import React, { useState, useCallback, useRef } from "react";
import { View, TouchableOpacity, Image, Alert } from "react-native";
import { Text, Avatar, Button, Overlay } from "react-native-elements";
import { imageSources } from "../../assets/images/imageSources";
import { useAuth } from "../../hooks/useAuth";
import useUserProfile from "../../hooks/useUserProfile";
import { useTheme } from "../../theme/ThemeContext";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { useNavigate } from "react-router-dom";

export const WebOrganizerNav: React.FC = () => {
  const theme = useTheme();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [avatarPosition, setAvatarPosition] = useState({ x: 0, y: 0 });
  const { userProfile } = useUserProfile();
  const { logout } = useAuth();
  const { acronym } = userProfile || {};
  const initials = `${acronym ?? ""}`;
  const navigate = useNavigate();
  const goToEvents = () => {
    navigate(`/dashboard`);
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const onLogoutWithWarning = useCallback(() => {
    Alert.alert(
      "Aviso",
      "Deseja mesmo sair da sua conta?",
      [
        { text: "Cancelar", onPress: () => {}, style: "cancel" },
        {
          text: "Sair",
          onPress: () => {
            toggleMenu();
            logout();
          },
        },
      ],
      { cancelable: false }
    );
  }, [logout]);

  const NavigationBar = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingVertical: 15,
          paddingHorizontal: 0,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
          padding: 15,
        }}
      >
        <View style={{ paddingLeft: 20 }}>
          <TouchableOpacity onPress={goToEvents}>
            <Image
              source={imageSources.logoHorizontal}
              style={{ width: 196, height: 30 }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Button
            title="Criar evento"
            onPress={() => {}}
            buttonStyle={{
              ...theme.buttonStyles.disabled,
            }}
            containerStyle={{ alignSelf: "flex-end", marginRight: 20 }}
            loading={false}
            icon={{
              name: "plus-circle",
              type: "material-community",
              size: 20,
              color: theme.colors.background,
            }}
          />
          <View>
            <TouchableOpacity style={{ marginRight: 20 }} onPress={toggleMenu}>
              <Avatar
                size={40}
                rounded
                title={initials}
                containerStyle={{
                  backgroundColor: theme.colors.secondary,
                  shadowColor: "#000",
                  shadowOffset: { width: 0, height: 3 },
                  shadowOpacity: 0.35,
                  shadowRadius: 3.84,
                }}
                titleStyle={{ fontSize: 14, fontWeight: "700" }}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      <NavigationBar />
      <Overlay
        isVisible={isMenuVisible}
        onBackdropPress={toggleMenu}
        overlayStyle={{
          minWidth: 150,
          minHeight: 200,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TouchableOpacity
          onPress={onLogoutWithWarning}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Icon
            name="logout-variant"
            size={30}
            color={theme.colors.error}
            style={{ marginRight: 10 }}
          />
          <Text style={theme.typography.h3}>Sair</Text>
        </TouchableOpacity>
      </Overlay>
    </>
  );
};
