// src/hooks/useUserProfile.ts

import { useEffect, useState } from "react";
import { useUserProfileContext } from "../context/UserProfileContext";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  setDoc,
  updateDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { User, EventType, Entity } from "../types";
import { useAuth } from "./useAuth";

type EntityType = {
  id: string;
  name: string;
};

export interface UserType {
  id?: string;
  email?: string;
  password?: string;
  role?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  phone?: string;
  acronym?: string;
  companyName?: string;
  companyLogo?: string;
  website?: string;
  description?: string;
  contactEmail?: string;
  contactPhone?: string;
  eventTypePrefId?: string;
  entityId?: string;
  isStudent?: boolean;
  expoPushToken?: string;
  entity?: User;
  eventTypePref?: EventType;
}

const emailValidator = (email: string) => {
  // Validate email format
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const getEntity = async (
  entityId: string
): Promise<EntityType | null> => {
  if (!entityId) return null;

  const entityDoc = await getDoc(doc(getFirestore(), "entities", entityId));

  if (!entityDoc.exists()) {
    return null;
  }

  const entityData = entityDoc.data() as Entity;
  return entityData;
};

const useUserProfile = () => {
  const { userProfile, setUserProfile } = useUserProfileContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState<Error | null>(null);
  const [updatedProfileData, setUpdatedProfileData] = useState<UserType | null>(
    null
  );
  const { user, userRole } = useAuth();

  useEffect(() => {
    const fetchUserProfile = async (userId: string) => {
      setLoading(true);
      setError(null);
      try {
        const userDoc = await getDoc(doc(getFirestore(), "users", userId));

        if (!userDoc.exists()) {
          throw new Error("Usuário não encontrado");
        }

        const userData = userDoc.data();
        const entity = await getEntity(userData?.entityId);

        let eventTypePref = null;

        if (userData?.eventTypePrefId) {
          const eventTypePrefDoc = await getDoc(
            doc(getFirestore(), "eventTypes", userData.eventTypePrefId)
          );

          if (!eventTypePrefDoc.exists()) {
            throw new Error("Tipo de evento não encontrado");
          }

          eventTypePref = eventTypePrefDoc.data();
        }

        const user = {
          ...userData,
          id: userDoc.id,
          eventTypePref,
          entity: entity ?? undefined,
        };

        setUserProfile(user);
        setLoading(false);
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserProfile(user.uid);
      } else {
        setUserProfile(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setUserProfile]);

  useEffect(() => {
    if (updatedProfileData) {
      setUserProfile(updatedProfileData);
    }
  }, [updatedProfileData]);

  const updateUserProfile = async (updatedProfile: Partial<UserType>) => {
    setUpdateLoading(true);
    setUpdateError(null);
    const { uid: userId } = user ?? {};

    try {
      if (!userId) {
        throw new Error("User not authenticated");
      }

      const userRef = doc(getFirestore(), "users", userId);
      const userDoc = await getDoc(userRef);
      const existingUser = userDoc.data();

      if (!existingUser || !userDoc.exists()) {
        throw new Error("User not found");
      }

      if (updatedProfile.email && updatedProfile.email !== existingUser.email) {
        const emailInUseSnapshot = await getDocs(
          query(
            collection(getFirestore(), "users"),
            where("email", "==", updatedProfile.email)
          )
        );

        const emailInUse = emailInUseSnapshot.docs.some(
          (doc) => doc.id !== userId
        );

        if (emailInUse) {
          throw new Error("O email já está sendo utilizado por outro usuário");
        }

        if (!emailValidator(updatedProfile.email)) {
          throw new Error("Email inválido");
        }
      }
      let updatedEntity: EntityType | null = null;
      if (updatedProfile.entityId) {
        updatedEntity = await getEntity(updatedProfile.entityId);
      }

      let updatedEventType: EventType | null;

      if (updatedProfile.eventTypePrefId) {
        const eventTypePrefDoc = await getDoc(
          doc(getFirestore(), "eventTypes", updatedProfile.eventTypePrefId)
        );

        const eventTypePrefData = eventTypePrefDoc.data() as EventType;

        if (!eventTypePrefData || !eventTypePrefDoc.exists()) {
          throw new Error("Invalid Event Type Preference");
        }
        updatedEventType = eventTypePrefData;
      }

      const updatedUserData = { ...existingUser, ...updatedProfile };

      // Save the updated data using the setDoc method
      await updateDoc(userRef, updatedUserData);

      setUserProfile(() => ({
        ...existingUser,
        ...updatedProfile,
        entity: updatedEntity ?? existingUser.entity,
        eventTypePref: updatedEventType ?? existingUser.eventTypePref,
      }));
      setUpdateLoading(false);
    } catch (error) {
      setUpdateError(error as Error);
      setUpdateLoading(false);
    }
  };

  return {
    userProfile,
    updateUserProfile,
    loading,
    error,
    updateError,
    updateLoading,
  };
};

export default useUserProfile;
