// src/hooks/useAuth
import { useState, useEffect } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  User,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { UserType } from "../types";
import * as Notifications from "expo-notifications";
import { auth, firestore } from "../../firebaseConfig";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: true,
  }),
});

export async function sendPushNotification(
  expoPushToken: string,
  title: string,
  body: string
) {
  const message = {
    to: expoPushToken,
    sound: "default",
    title: title,
    body: body,
    data: { body },
  };

  await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });
}

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [userData, setUserData] = useState<UserType | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setIsAuthenticated(true);
        setUser(firebaseUser);
        const userDoc = await getDoc(
          doc(collection(firestore, "users"), firebaseUser.uid)
        );
        if (userDoc.exists()) {
          const userData = userDoc.data() as UserType;
          setUserRole(userData?.role ?? "PARTICIPANT");
          setUserData(userData);
        }
      } else {
        setIsAuthenticated(false);
        setUserRole(null);
        setUserData(null);
        setUser(null);
      }
      setInitializing(false);
    });
    return () => unsubscribe();
  }, []);

  const deleteAccount = async (email: string, password: string) => {
    if (user) {
      setIsDeletingUser(true);
      try {
        // Re-authenticate user before deleting their account
        const credential = EmailAuthProvider.credential(email, password);
        await reauthenticateWithCredential(user, credential);

        // Delete user's data from Firestore
        await deleteDoc(doc(collection(firestore, "users"), user.uid));

        // Delete all enrollments with the profileId equal to the user's id
        const enrollmentsQuery = query(
          collection(firestore, "enrollments"),
          where("profileId", "==", user.uid)
        );
        const enrollmentsSnapshot = await getDocs(enrollmentsQuery);

        // Initialize the batch
        const batch = writeBatch(firestore);
        enrollmentsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        // Commit the batch
        await batch.commit();

        // Delete user's account from Firebase Authentication
        await user.delete();
      } catch (error) {
        console.error("Error re-authenticating user:", error);
        // Handle the error as needed
      } finally {
        setIsDeletingUser(false);
      }
    }
  };

  const login = async (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: (error: unknown) => void
  ) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        if (!userCredential.user.emailVerified) {
          return onError(
            "Para ativar sua conta, clique no link que enviamos no email de confirmação. Lembre de olhar o spam!!"
          );
        }
        onSuccess();
      }
    } catch (error: any) {
      if (error.code) {
        switch (error.code) {
          case "auth/user-not-found":
            onError("Nenhum usuário encontrado com este e-mail.");
            break;
          case "auth/wrong-password":
            onError("Senha incorreta.");
            break;
          case "auth/invalid-email":
            onError("E-mail inválido.");
            break;
          default:
            onError("Erro ao fazer login.");
        }
      } else {
        onError("Erro ao fazer login.");
      }
    } finally {
      setLoading(false);
    }
  };

  const register = async (
    email: string,
    password: string,
    role: string,
    onSuccess: () => void,
    onError: (error: unknown) => void
  ) => {
    setLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (user) {
        await sendEmailVerification(user);

        await setDoc(doc(collection(firestore, "users"), user.uid), {
          email: email,
          role: role,
          id: user.uid,
        });
        await signOut(auth);
        onSuccess();
      }
    } catch (error: any) {
      if (error.code) {
        switch (error.code) {
          case "auth/email-already-in-use":
            onError("Este e-mail já está em uso.");
            break;
          case "auth/invalid-email":
            onError("E-mail inválido.");
            break;
          case "auth/weak-password":
            onError("A senha deve ter no mínimo 8 caracteres.");
            break;
          default:
            onError("Erro ao criar a conta.");
        }
      } else {
        onError("Erro ao criar a conta.");
      }
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (
    email: string,
    onSuccess: () => void,
    onError: (error: unknown) => void
  ) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      onSuccess();
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  return {
    isAuthenticated,
    userRole,
    userData,
    user,
    login,
    logout,
    register,
    resetPassword,
    loading,
    initializing,
    deleteAccount,
    isDeletingUser,
  };
};
